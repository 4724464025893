import { useEffect, useState, useRef } from "react";
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
// store
import { requests } from '@helpers/requests';

export function useFetchData(fetchURL, isAvailable) {
    isAvailable = isAvailable || true

    const [result, setResult] = useState({
        isLoading: true,
        length: 0,
        data: null
    })

    useEffect(() => {
        async function fetchData() {
            const request = await requests.get(fetchURL);

            if (request.status === 200) {
                setResult({
                    ...result,
                    isLoading: false,
                    length: request.response.length,
                    data: request.response
                })
            } else {
                setResult({
                    ...result,
                    isLoading: false
                })
            }
        }

        if (isAvailable) {
            fetchData()
        }
        // eslint-disable-next-line
    }, [])

    return [result, setResult]
}


/* Check if current user that is logged in has given permission */
export function useHasPermission(permission_to_be_checked) {
    const permissions = useSelector(state => state.permissions)
    return permissions.includes(permission_to_be_checked)
}


export function useQueryPage(){
    const location = useLocation()
    let searchParams = new URLSearchParams(location.search);
    const page = searchParams.get('page');
    return page !== null ? page : 1
}

export function useHasPermissions(permissions_to_be_checked) {
    const permissions = useSelector(state => state.permissions)
    const founded = permissions_to_be_checked.some(perm => permissions.indexOf(perm) >= 0)
    return founded
}

export function useHasModuleAllowed(allowedModulesToBeChecked) {
    const allowedModules = useSelector(state => state.allowed_modules)
    // check if every is in allowedModules
    const founded = allowedModulesToBeChecked.every(module => allowedModules.indexOf(module) >= 0)
    return founded
}

export function useSelectOptions(endpoint, text) {
    const [response] = useFetchData(endpoint)
    const [result, setResult] = useState({
        isLoading: response.isLoading,
        options: []
    })

    useEffect(() => {
        if (!response.isLoading) {
            let optionItems = []
            if (response?.data?.results) {
                optionItems = response?.data?.results?.map(optionItem => {
                    return {
                        key: optionItem.id,
                        value: optionItem.id,
                        text: optionItem[text]
                    }
                })
            } else if (response?.data) {
                optionItems = response?.data?.map(optionItem => {
                    return {
                        key: optionItem.id,
                        value: optionItem.id,
                        text: optionItem[text]
                    }
                })
            }

            setResult({
                isLoading: response.isLoading,
                options: optionItems
            })
        }
        // eslint-disable-next-line
    }, [response])

    return [result, setResult]
}

export function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
}

export const useIsMount = () => {
    const isMountRef = useRef(true);
    useEffect(() => {
      isMountRef.current = false;
    }, []);
    return isMountRef.current;
};

