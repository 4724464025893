import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
// store
// import { icons } from '@icons';
import { routes } from '@routes';
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { thousandsSeparators } from '@helpers/functions';
import { useHasPermission, useIsMount } from '@helpers/hooks';
// components
import Icon from '@components/Icon';
import ListView from '@components/ListView';
import Action from '@components/general/Action';
import Attachments from '@components/Attachments';
import DatePresets from '@components/DatePresets';
import SuperField from '@components/forms/SuperField';
import LabelsList from '@components/lists/LabelsList';
import GenerateDocuments from '@components/GenerateDocuments';
import { Form, Popup, Divider, Label } from 'semantic-ui-react';
// shared components
import OrderState from '../shared/OrderState';
import OrderDetail from '../shared/OrderDetail';
// specific components
import TransportOrderForm from './components/TransportOrderForm';
import TransportFulfillmentForm from './components/TransportFulfillmentForm';

const TransportOrder = ({ isPurchase }) => {
    const { t } = useTranslation()
    const isMount = useIsMount()
    const dateFormat = useSelector(state => state.date_format)
    const [attachmentsOpen, setAttachmentsOpen] = useState(0)


    const canManage = useHasPermission('orders.c_manage_order')

    const [accounts, setAccounts] = useState([])
    const [employees, setEmployees] = useState([])
    const [labels, setLabels] = useState([])
    const [units, setUnits] = useState([])
    const [vehicles, setVehicles] = useState([])
    const [externalCarriers, setExternalCarriers] = useState([])
    
    const [isPurchaseOrder, setIsPurchaseOrder] = useState(isPurchase)

    useEffect(() => {
        async function fetchAccounts(){
            const request = await requests.get(API.ACCOUNTS + `business_details/?query={id, name}&${isPurchaseOrder ? "is_supplier" : "is_account"}=true&is_active=true&exclude_unit=true`)
            if (request.status === 200) {
                setAccounts(request.response.map(item => ({ key: item.id, value: item.id, text: item.name })))
            }
        }

        async function fetchEmployees(){
            const request = await requests.get(API.EMPLOYEES + "?only_basic_info=true&is_active=true")
            if (request.status === 200) {
                setEmployees(request.response.map(item => ({ key: item.id, value: item.id, text: item.fullname_with_titles })))
            }
        }

        async function fetchLabels(){
            const request = await requests.get(API.ORDERS + "labels/?query={id, name}")
            if (request.status === 200) {
                setLabels(request.response.map(item => ({ key: item.id, value: item.id, text: item.name })))
            }
        }

        async function fetchUnits(){
            const request = await requests.get(API.UNITS + "?only_from_active_company=true&is_business=true&query={id, name}")
            if (request.status === 200) {
                setUnits(request.response.map(item => ({ key: item.id, value: item.id, text: item.name })))
            }
        }

        async function fetchVehicles(){
            const request = await requests.get(API.VEHICLES + "?query={id, title, car_plate_number}")
            if (request.status === 200) {
                setVehicles(request.response.map(item => ({ key: item.id, value: item.id, text: `${item.car_plate_number} - ${item.title}` })))
            }
        }

        async function fetchExternalCarriers(){
            const request = await requests.get(API.ACCOUNTS + `business_details/?query={id, name}&is_supplier=true&is_active=true&exclude_unit=true`)
            if (request.status === 200) {
                setExternalCarriers(request.response.map(item => ({ key: item.id, value: item.id, text: `${item.name}` })))
            }
        }

        fetchAccounts()
        if (isMount) {
            fetchEmployees()
            fetchLabels()
            fetchUnits()
            fetchVehicles()
            fetchExternalCarriers()
        }
        // eslint-disable-next-line
    }, [isPurchaseOrder])

    const handleDisplayAddress = (address) => {
        if (address?.country === "" || address?.city === "" ) return `${t('unspecified')} ${t('address')}`
        let addressToRender = `${address?.street || ""}, ${address?.postcode || ""} ${address?.city || ""}, ${address?.country_display || ""}`

        return addressToRender.trim()
    }

    const handleDisplayShortAddress = (address) => {
        if (address?.country !== "" && address?.city !== "" ) return `${address?.city}, ${address?.country}`

        return "--"
    }

    return (
        <ListView
            as="table"
            allowSearch
            allowSelection
            actionsCellWidth="1"
            endpoint={API.ORDERS}
            isSoftDeleteAllowed
            activeLabelOnRender={isPurchase ? 1 : 0 }
            initialFilters={{
                is_purchase_order: isPurchaseOrder,
                ordering: "-created_on",
                owner: "",
                account: "",
                labels: [],
                assigned_to: [],
                order_manager: "",
                order_status: "",
                is_active: true,
                // after - before (date_type == 1)
                transport_loading_date_after: "",
                transport_loading_date_before: "",
                // from - to (date_type == 2)
                transport_from_delivery_date: "",
                transport_to_delivery_date: "",
                transport_has_invoice_reference: "",

                // plate_numbers
                transport_truck: "",
                transport_trailer: "",

                // loading address filters:
                transport_loading_address_city: "",
                transport_loading_address_country: "",
                transport_loading_address_postcode: "",
                // destination address filters:
                transport_destination_address_city: "",
                transport_destination_address_country: "",
                transport_destination_address_postcode: "",

                resource_type: "transportorder",
                // custom
                date_type: 1,
            }}
            onResponse={(response, filters) => {
                setIsPurchaseOrder(filters.is_purchase_order)
            }}
            bulkActions={ (selected) => [
                { 
                    as: "modal",
                    name: t('generate_documents'),
                    icon: "document-text-outline",
                    modal: <GenerateDocuments source="orders.TransportOrder" selected={selected} />
                }
            ]}
            listAdditionActions={() => [
                {
                    as: 'filter',
                    index: 0,
                    name: t('received_orders'),
                    filters: {
                        is_purchase_order: false,
                    },
                },
                {
                    as: 'filter',
                    index: 1,
                    name: t('issued_orders'),
                    filters: {
                        is_purchase_order: true,
                    },
                },
            ]}
            exports={[
                {
                    endpoint: API.EXPORTS + "dynamic_transport_order/",
                    permissions: ['orders.c_view_order'],
                    apply_filters: true,
                    type: "xlsx",
                    filename: "transport_orders"
                },
            ]}
            tableHeaders={[
                { title: t('order_num'), orderBy: "order_number" },
                { title: t('name'), orderBy: "name" },
                { title: isPurchaseOrder ? t('account') : t('supplier'), orderBy: "owner__name" },
                { title: isPurchaseOrder ? t('supplier') : t('account'), orderBy: "business_detail__name" },
                { title: t('price_without_tax') },
                { title: t('dates') },
                // { title: t('date_of_unload') },
                { title: t('transport') },
                { title: t('vehicle') },
                // { title: t('labels') },
                { title: t('order_manager'), orderBy: "order_manager__fullname" },
                { title: t('state'), orderBy: "order_status" },
                { title: t('documents') },
            ]}
            renderFilterFields={(filters, setFilters) => (
                <>
                    <p style={{ fontWeight: 'bold', fontSize: "1rem" }}>{ t('select_date_to_be_used_in_filter') }:</p>
                    <Form.Group widths="equal">
                        <SuperField as="radio"
                            label={t('date_of_load')}
                            checked={ filters.date_type === 1 }
                            onChange={ () => {
                                if(filters.date_type === 2) {
                                    const dateFrom = filters.transport_from_delivery_date
                                    const dateTo = filters.transport_to_delivery_date
                                    setFilters({ 
                                        ...filters, 
                                        date_type: 1,
                                        transport_loading_date_after: dateFrom,
                                        transport_loading_date_before: dateTo,
                                        transport_from_delivery_date: "",
                                        transport_to_delivery_date: "",
                                    })
                                }
                            }}
                        />
                        <SuperField as="radio"
                            label={t('date_of_unload')}
                            checked={ filters.date_type === 2 }
                            onChange={ () => {
                                if(filters.date_type === 1) {
                                    const dateFrom = filters.transport_loading_date_after
                                    const dateTo = filters.transport_loading_date_before
                                    setFilters({ 
                                        ...filters, 
                                        date_type: 2,
                                        transport_loading_date_after: "",
                                        transport_loading_date_before: "",
                                        transport_from_delivery_date: dateFrom,
                                        transport_to_delivery_date: dateTo,
                                    })
                                }
                            }}
                        />
                    </Form.Group>
                    <Divider/>
                    <Form.Group widths="equal">
                        <SuperField as="datepicker"
                            label={t('date_to')}
                            value={filters.date_type === 1 ? filters.transport_loading_date_before : filters.transport_to_delivery_date}
                            onChange={(e, { value }) => {
                                if (filters.date_type === 1) {
                                    setFilters({ 
                                        ...filters, 
                                        transport_loading_date_before: value,
                                        transport_to_delivery_date: "",
                                    })
                                } else if(filters.date_type === 2) {
                                    setFilters({ 
                                        ...filters, 
                                        transport_loading_date_before: "",
                                        transport_to_delivery_date: value,
                                    })
                                }
                            }}
                        />
                            <SuperField as="datepicker"
                            label={t('date_from')}
                            value={filters.date_type === 1 ? filters.transport_loading_date_after : filters.transport_from_delivery_date}
                            onChange={(e, { value }) => {
                                if (filters.date_type === 1) {
                                    setFilters({ 
                                        ...filters, 
                                        transport_loading_date_after: value,
                                        transport_from_delivery_date: "",
                                    })
                                } else if(filters.date_type === 2) {
                                    setFilters({ 
                                        ...filters, 
                                        transport_loading_date_after: "",
                                        transport_from_delivery_date: value,
                                    })
                                }
                            }}
                        />
                    </Form.Group>
                    <DatePresets
                        from={filters.transport_loading_date_after}
                        to={filters.transport_loading_date_before}
                        onSelection={ (firstMonthDay, lastMonthDay) => {
                            setFilters(prev => ({
                                ...prev,
                                transport_loading_date_after: firstMonthDay,
                                transport_loading_date_before: lastMonthDay
                            }))
                        }}
                    />

                    <Form.Group widths="equal">
                        <SuperField as="choice"
                            search
                            key={isPurchaseOrder}
                            label={isPurchaseOrder ? t('account') : t('supplier')}
                            value={filters.owner}
                            customOptions={units}
                            onChange={(e, { value }) => setFilters({ ...filters, owner: value })}
                            />
                        <SuperField as="choice"
                            search
                            key={!isPurchaseOrder}
                            label={isPurchaseOrder ? t('supplier') : t('account')}
                            value={filters.account}
                            customOptions={accounts}
                            onChange={(e, { value }) => setFilters({ ...filters, account: value })}
                        />
                    </Form.Group>

                    <Form.Group widths="equal">
                        <SuperField as="input"
                            label={t('car_plate_number') + " " + t('truck') }
                            value={filters.transport_truck}
                            onChange={(e, { value }) => setFilters({ ...filters, transport_truck: value })}
                            />
                        <SuperField as="input"
                            label={t('car_plate_number') + " " + t('trailer') }
                            value={filters.transport_trailer}
                            onChange={(e, { value }) => setFilters({ ...filters, transport_trailer: value })}
                        />
                    </Form.Group>

                    <p><strong>{ t('loading_address') }</strong></p>
                    <Form.Group widths="equal">
                        <SuperField as="input"
                            label={t('city') }
                            value={filters.transport_loading_address_city}
                            onChange={(e, { value }) => setFilters({ ...filters, transport_loading_address_city: value })}
                            />
                        <SuperField as="input"
                            label={t('country') }
                            value={filters.transport_loading_address_country}
                            onChange={(e, { value }) => setFilters({ ...filters, transport_loading_address_country: value })}
                        />
                        <SuperField as="input"
                            width="8"
                            label={t('postcode') }
                            value={filters.transport_loading_address_postcode}
                            onChange={(e, { value }) => setFilters({ ...filters, transport_loading_address_postcode: value })}
                        />
                    </Form.Group>

                    <p><strong>{ t('destination_address') }</strong></p>
                    <Form.Group widths="equal">
                        <SuperField as="input"
                            label={t('city') }
                            value={filters.transport_destination_address_city}
                            onChange={(e, { value }) => setFilters({ ...filters, transport_destination_address_city: value })}
                            />
                        <SuperField as="input"
                            label={t('country') }
                            value={filters.transport_destination_address_country}
                            onChange={(e, { value }) => setFilters({ ...filters, transport_destination_address_country: value })}
                        />
                        <SuperField as="input"
                            width="8"
                            label={t('postcode') }
                            value={filters.transport_destination_address_postcode}
                            onChange={(e, { value }) => setFilters({ ...filters, transport_destination_address_postcode: value })}
                        />
                    </Form.Group>

                    <Form.Group widths="equal">
                        <SuperField as="choice"
                            search
                            label={t('order_manager')}
                            value={filters.order_manager}
                            customOptions={employees}
                            onChange={(e, { value }) => setFilters({ ...filters, order_manager: value })}
                        />
                    </Form.Group>
                    {/* <SuperField as="choice"
                        search
                        multiple
                        label={t('responsible_persons')}
                        value={filters.assigned_to}
                        customOptions={employees}
                        onChange={(e, { value }) => setFilters({ ...filters, assigned_to: value })}
                    /> */}

                    <SuperField as="choice"
                        search
                        multiple
                        label={t('labels')}
                        value={filters.labels}
                        customOptions={labels}
                        onChange={(e, { value }) => setFilters({ ...filters, labels: value })}
                    />

                    <Form.Group widths="equal">
                        <SuperField as="choice"
                            label={t('state')}
                            value={filters.order_status}
                            type="order_status_choices"
                            onChange={(e, { value }) => setFilters({ ...filters, order_status: value })}
                        />
                        <SuperField as="choice"
                            label={t('status')}
                            value={filters.is_active}
                            customOptions={[
                                { key: 1, value: true, text: t('active_order') },
                                { key: 2, value: false, text: t('closed_order') },
                            ]}
                            onChange={(e, { value }) => setFilters({ ...filters, is_active: value })}
                        />
                    </Form.Group>

                    <SuperField as="choice"
                        label={t('invoicing_status')}
                        value={filters.transport_has_invoice_reference}
                        customOptions={[
                            { key: 2, value: true, text: t('invoiced') },
                            { key: 3, value: false, text: t('not_invoiced') },
                        ]}
                        onChange={(e, { value }) => setFilters({ ...filters, transport_has_invoice_reference: value })}
                    />
                </>
            )}
            actions={[
                {
                    as: "modal",
                    type: "add",
                    name: t('create_order'),
                    modal: <TransportOrderForm isPurchase={isPurchaseOrder} accounts={accounts} employees={employees} owners={units} vehicles={vehicles} externalCarriers={externalCarriers}/>,
                    permissions: ['orders.c_manage_order'],
                },
                // {
                //     as: "modal",
                //     type: "custom",
                //     icon: "add-circle-outline",
                //     name: (!canManageFulfillments || !canManageAllRecords) ? t('fulfillment_limited_access_hint') : t('create_fulfillment'),
                //     modalSize: "tiny",
                //     disabled: (!canManageAllRecords || !canManageFulfillments),
                //     customIconColor: "var(--dark)",
                //     modal: <ServiceOrderFulfillment/>,
                //     permissions: ['orders.c_manage_order_fulfillment']
                // },
                // {
                //     type: "sidebar",
                //     icon: "eye-outline",
                //     name: t('fulfillment_overview'),
                //     modalSize: "large",
                //     customIconColor: "var(--primary)",
                //     content: <TransportOrderDetail/>,
                //     permissions: ['orders.c_view_order_fulfillment']
                // },
                {
                    as: "modal",
                    type: "custom",
                    modalSize: "large",
                    icon: "eye-outline",
                    name: t('order_detail'),
                    customIconColor: "var(--primary)",
                    modal: (item) => <OrderDetail id={item.id}/>,
                    permissions: ['orders.c_view_order']
                },
                {
                    as: "modal",
                    type: "edit",
                    name: t('edit'),
                    permissions: ['orders.c_manage_order'],
                    modal: <TransportOrderForm isPurchase={isPurchaseOrder} accounts={accounts} employees={employees} owners={units} vehicles={vehicles} externalCarriers={externalCarriers}/>
                },
                {
                    name: t('delete'),
                    type: "delete",
                    as: "delete",
                    text: t('delete_order'),
                    permissions: ['orders.c_delete_order'],
                },
            ]}
            renderCells={(order, setData) => ([
                { width: 1, content: order.order_number },
                { content: 
                    <>
                        <strong style={{ fontSize: "1rem" }}>{ order.name }</strong>

                        <div style={{ marginTop: "0.5rem" }}>
                            <LabelsList
                                key={order.id}
                                rowID={order.id}
                                labels={order.labels}
                                endpoint={API.ORDERS}
                                additionalFilters="&lead_tag=false"
                                resourcetype={order.resourcetype}
                                endpointLabels={API.ORDERS + "labels/"}
                                label_key={"labels"}
                            />
                        </div>
                    </>
                    
                
                },
                { content: order.owner?.name || "" },
                { content: 
                    <>
                        { order?.business_detail?.id ?
                            <>
                                <Link target="_blank" to={(isPurchase ? routes.SUPPLIERS : routes.ACCOUNTS) + order.business_detail.id}>
                                    { order.business_detail?.name }
                                </Link> <br/>
                                {/* <small> { order.account?.is_supplier ? t('supplier') : t('account') } </small> */}
                            </>
                            : "--"
                        }
                    </>
                },
                { content: 
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        { thousandsSeparators(order?.price?.sum || 0) + " " + order?.price?.currency }
                        <sup style={{ marginLeft: "0.5rem" }}>
                            <Popup
                                basic
                                trigger={
                                    <Icon name="information-circle" style={{ fontSize: "1rem" }}/>
                                }
                                content={
                                    <div>
                                        <strong>{ t('total_fulfilled') }: { " " }</strong> <br/>
                                        { order.fulfillment 
                                            ? thousandsSeparators(order.fulfillment?.total_fee || 0) + " " + order?.price?.currency
                                            : `0  ${order?.price?.currency}` 
                                        }
                                    </div>
                                }
                            />
                        </sup>
                    </div>
                },
                { content: 
                    <>
                        <strong>{ t('date_of_load') }:</strong> {" "}
                        { moment(order.loading_date).format(dateFormat) } <br/>
                        <strong>{ t('date_of_unload') }:</strong> {" "}
                        { order.fulfillment === null 
                            ? 
                                <>
                                    -- <br/>
                                    <Action as="modal"
                                        header={t('add_unload_date')}
                                        modalSize="tiny"
                                        trigger={
                                            <small style={{ color: "var(--primary)" }} className="ref-link"> {t('add_unload_date')} </small>
                                        }
                                        content={
                                            <TransportFulfillmentForm order={order} setData={setData}/>
                                        }
                                    />
                                </>
                            : 
                                <>
                                    <span style={{ position: "relative", top: "-0.2rem" }}>
                                        { moment(order.fulfillment.delivery_date).format(dateFormat) }
                                    </span>
                                    { order.fulfillment.note !== "" && 
                                        <Popup
                                            basic
                                            trigger={
                                                <Icon name="document-text-outline" style={{ marginLeft: "0.5rem", fontSize: "1rem" }}/>
                                            }
                                            content={order.fulfillment.note}
                                        />
                                    }
                                    <br/>
                                    <Action as="modal"
                                        header={t('update_unload_date')}
                                        modalSize="tiny"
                                        trigger={
                                            <small style={{ color: "var(--primary)" }} className="ref-link"> {t('update_unload_date')} </small>
                                        }
                                        content={
                                            <TransportFulfillmentForm order={order} setData={setData}/>
                                        }
                                    />
                                </>
                        }
                    </>
                },
                { content: 
                    <>
                        <span>
                            <strong>{t('transport_from')}:</strong> {" "}
                            <span title={handleDisplayAddress(order?.loading_address)}>
                                { handleDisplayShortAddress(order?.loading_address) }
                            </span>
                            <br/>

                            <strong>{t('transport_to')}:</strong> {" "}
                            <span title={handleDisplayAddress(order?.destination_address)}>
                                { handleDisplayShortAddress(order?.destination_address) }
                            </span>
                            <br/>

                            <strong>{t('distance')}: </strong> { order?.distance || "-" }
                        </span>
                    </>
                },
                { width: 2, content: 
                    <>
                        <strong>{t('truck')}:</strong>{" "}
                        { order.truck?.car_plate_number || order.external_truck }
                        <br/>
                        <strong>{t('trailer')}:</strong> {" "}
                        { order.trailer?.car_plate_number ||  order.external_trailer }
                        <br/>
                        <strong>{t('driver')}:</strong> {" "}
                        { order?.driver?.fullname || order?.external_carrier?.name }
                    </>
                },
                { content: 
                    <>
                        { order?.order_manager?.id ?
                            <Link target="_blank" to={routes.EMPLYOEES_DETAIL + order?.order_manager?.id}>
                                <span>{ order?.order_manager?.fullname }</span>
                            </Link>
                            : "--"
                        }
                    </>
                },
                { content: <OrderState order={order} setData={setData}/> },
                { content: 
                    <Label basic>
                        <Popup
                            style={{ zIndex: "1000", minWidth: "450px" }}
                            open={attachmentsOpen === order.id}
                            onOpen={() => setAttachmentsOpen(order.id)}
                            hoverable
                            position='left center'
                            trigger={
                                <div style={{ cursor: "pointer" }}>
                                    <Icon name="reader-outline" style={{ marginRight: "0.5rem" }}/> 
                                    <span style={{ position: "relative", top: "-0.1rem" }}>{ order.attachments.length }</span>
                                </div>
                            }
                            content={
                                <Attachments 
                                    viewOnly={!canManage}
                                    record={order}
                                    setData={setData}
                                    allowFolders
                                    moduleName={"TransportOrder"}
                                    sourceEndpoint={API.ORDERS}
                                    closeView={() => setAttachmentsOpen(0)}
                                    additionalData={{ resourcetype: "TransportOrder" }}
                                />
                            }
                        />
                    </Label>
                },
            ])}
        />
    );
};

export default TransportOrder;