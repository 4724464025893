import React, { Fragment, useState } from 'react'
import { useTranslation } from "react-i18next"
import { toast } from 'react-semantic-toasts';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import Icon from '@components/Icon';
import CanView from '@components/perms/CanView';
import LanguageForm from '@components/forms/LanguageForm';
import ConfrimModal from '@components/modals/ConfrimModal';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import { Header, Divider, Grid, Label } from 'semantic-ui-react';
import {FlexItem, FlexRow} from '@components/tables/FlexTable';


const LanguageSection = ({ isManageable, result, setResult, patchEndpoint }) => {
    const { t } = useTranslation();

    const [languages, setLanguages] = useState(result?.languages || []);

    const getColor = (language) => {
        if (language.is_primary && language.is_native) {
            return "var(--primary)"
        }
        if (language.is_primary && language.is_native === false) {
            return "var(--info)"
        }
        if (language.is_native && language.is_primary === false) {
            return "var(--warning)"
        }
        if (language.is_native === false && language.is_primary === false) {
            return "var(--success)"
        }
    }
    const onConfirm = async (newLanguage) => {
        const data = {
            languages: {
                add: [newLanguage.id]
            }
        }

        const request = await requests.patch(patchEndpoint + result?.id + "/", data)

        if (request.status === 200) {
            setLanguages([...languages, newLanguage])
            setResult(prev => ({
                ...prev,
                languages: [...languages, newLanguage]
            }))
        } else if(request.status === 400) {
            toast({
                type: 'error',
                icon: 'warning',
                title: request.response?.languages?.[0] || request.response?.non_field_errors?.[0],
                animation: 'pulse',
                time: 5000,
            })
        }
    }

    const onDelete = async (id) => {
        const result = await requests.del(API.LANGUAGES + id + "/");
        if (result.status === 204) {
            const newLanguageList = languages.filter((item) => item.id !== id);
            setLanguages(newLanguageList);
            setResult(prev => ({
                ...prev,
                languages: newLanguageList
            }))
        }
    }

    const onUpdate = (updatedItem) => {
        const newLanguageList = languages.filter((item) => {
            if (item.id === updatedItem.id) {
                item.language_name = updatedItem.language_name;
                item.level = updatedItem.level;
                item.level_display = updatedItem.level_display;
                item.is_primary = updatedItem.is_primary;
                item.is_native = updatedItem.is_native;
            }

            return item;
        });

        setLanguages(newLanguageList)
        setResult(prev => ({
            ...prev,
            languages: newLanguageList
        }))
    }

    return (
        <Fragment>
            <Grid stackable>
                <Grid.Row>
                    <Grid.Column only="computer" computer="3" stretched style={{ borderRadius: "4px", paddingTop: "1rem", backgroundColor: "var(--light)"}}>
                        <FlexRow background="transparent" padding="0">
                            <FlexItem textAlign="center">
                                <Header icon >
                                    <Icon  name='language-outline' style={{ fontSize: "5rem" }} />
                                    <h3>{t('languages')}</h3>
                                </Header>
                            </FlexItem>
                        </FlexRow>
                    </Grid.Column>
                    <Grid.Column computer="12" tablet="16" mobile="16">
                        <Grid columns='2' style={{ paddingTop: 0, paddingBottom: 0 }}>
                            <Grid.Column width='10' style={{ paddingTop: "0.8rem", paddingBottom: 0 }}>
                                <Header floated="left" as="h3"> 
                                    <Icon name="language-outline" style={{ marginRight: "0.5rem" }}/>
                                    <span style={{ position: "relative", top: "-0.2rem" }}>
                                        {t('languages')}
                                    </span>
                                </Header>
                            </Grid.Column>

                            <Grid.Column width='6' textAlign="right" style={{ padding: 0 }}>
                                <CanView render={isManageable}>
                                    <SuperDuperModal
                                        header={t('add_language')}
                                        trigger={
                                            <Header style={{ padding: 0, cursor: "pointer", paddingTop: "1rem" }} floated="right" as="h4">
                                                <Icon name="add-outline" style={{ fontSize: "1rem", marginRight: "0.5rem"}} />
                                                <span style={{ position: "relative", top: "-0.2rem" }}>
                                                    {t('add_language')}
                                                </span>
                                            </Header>
                                        }
                                        content={
                                            <LanguageForm
                                                language={{}}
                                                onConfirm={(newLanguage) => onConfirm(newLanguage)}
                                            />
                                        }
                                    />
                                </CanView>
                            </Grid.Column>

                        </Grid>

                        <Divider />

                        <Grid style={{padding: 0}}>
                            <Grid.Row style={{padding: 0}}>
                                { languages.length > 0
                                    ?
                                <Grid.Column computer="10" tablet="16" mobile="16">
                                    {languages.map((language, index) => (
                                        <Label key={index}
                                        style={{ backgroundColor: getColor(language),
                                            paddingRight: '0.3rem', marginBottom: "0.3rem"
                                        }}>
                                            <FlexRow background="transparent" padding="0">
                                                <FlexItem>
                                                    <SuperDuperModal
                                                        header={t('language')}
                                                        trigger={
                                                            <span style={{ fontSize: "0.9rem", color: "white", marginRight: "5px" }}>
                                                                <Icon
                                                                    name="bookmark-outline"
                                                                    style={{ marginRight: "0.5rem" }}
                                                                />
                                                                <strong style={{ position: "relative", top: "-0.2rem" }}>{ language.language_name.title + ' ' + language.level_display }</strong>
                                                            </span>

                                                        }
                                                        content={
                                                            <LanguageForm
                                                                language={language}
                                                                onConfirm={(updatedLanguage) => onUpdate(updatedLanguage)}
                                                            />
                                                        }
                                                    />
                                                </FlexItem>
                                                <FlexItem basis="10%">
                                                    <ConfrimModal
                                                        onConfirm={() => onDelete(language.id)}
                                                        description={t('delete_language')}
                                                        button={<Icon name="close-outline" style={{ color: "var(--danger)", cursor: "pointer", marginLeft: "0.5rem" }}/>}
                                                    />
                                                </FlexItem>
                                            </FlexRow>
                                        </Label>
                                    ))
                                }
                                </Grid.Column>
                                :
                                <Grid.Column textAlign="center" computer="16" tablet="16" mobile="16">
                                    <Header as="h4">{t('nothing')}</Header>
                                </Grid.Column>
                                }
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Fragment>
    );
};

export default LanguageSection;