import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// store 
import { routes } from '@routes';
import { API } from '@store/config';
import { requests } from '@helpers/requests'
// components 
import CanView from '@components/perms/CanView';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import { Form, Button, Icon, Divider, Header } from 'semantic-ui-react';
import Action from '@components/general/Action';

const Documents = ({ contract, setContracts, onClose }) => {
    const { t } = useTranslation()
    const [isProcessing, setIsProcessing] = useState(false)

    // eslint-disable-next-line
    const [documentGroup, setDocumentGroup] = useState("")
    const [uploadedDoc, setUploadedDoc] = useState(null)
    const [uploadedDocError, setUploadedDocError] = useState(null)
    // eslint-disable-next-line
    const [uploadedDocAdditions, setUploadedDocAdditions] = useState(contract.external_file_additions.map(item => ({
        id: item.id,
        name: item.name,
        file: item.file,
        source: null,
        processing: false,
        error: null,
    })))

    const handleStoreFile = async () => {
        setIsProcessing(true)

        // handle contract file first 
        let request = undefined
        if (uploadedDoc !== null) { // create
            const formData = new FormData()
            formData.append("external_file", uploadedDoc)
            request = await requests.patch(API.CONTRACTS + contract.id + "/file/", formData)

            if (request.status !== 200) {
                setUploadedDocError(request.status.external_file)
            }
        }

        // handle additions (if new then create, other pass)
        let additions = []
        for (let i = 0; i < uploadedDocAdditions.length; i++) {
            onFileChange(i, "processing", true)
            if (uploadedDocAdditions[i].id === "") { // create
                const formData = new FormData()
                formData.append("name", uploadedDocAdditions[i].name)
                formData.append("file", uploadedDocAdditions[i].source)
                if (documentGroup !== "") {
                    formData.append("group", documentGroup)
                }

                const additionRequest = await requests.post(API.ATTACHMENTS, formData)
                if (additionRequest.status === 201) {
                    additions.push(additionRequest.response.id)
                    onFileChange(i, "id", additionRequest.response.id)
                } else {
                    onFileChange(i, "error", additionRequest.response.file)
                }
            } else {
                additions.push(uploadedDocAdditions[i].id)
            }
            onFileChange(i, "processing", false)
        }

        const contractRequest = await requests.patch(API.CONTRACTS + contract.id + "/", {
            external_file: request?.status === 200 ? request.response.external_file : contract.external_file,
            external_file_additions: {
                add: additions
            }
        })

        if (contractRequest.status === 200) {
            setContracts(prev => prev.filter(item => {
                if (item.id === contract.id) {
                    item.external_file = contractRequest.response.external_file
                    item.external_file_additions = contractRequest.response.external_file_additions
                }

                return item
            }))
        }

        if (uploadedDocError === null && uploadedDocAdditions.filter(item => item.error !== null).length === 0) {
            onClose()
        }


        setIsProcessing(false)
    }

    const onAddFileAddition = () => {
        setUploadedDocAdditions(prevState => ([...prevState, {
            id: "",
            name: "",
            file: "",
            source: null,
            processing: false,
            error: null
        }]))
    }

    const onFileChange = (index, updatedKey, value) => {
        setUploadedDocAdditions(prevState => prevState.filter((record, key) => {
            if (index === key) {
                record[updatedKey] = value
            }

            return record
        }))
    }

    const onFileRemove = async (index, item) => {
        if (item.id === "") {
            setUploadedDocAdditions(prev => prev.filter((stateItem, key) => key !== index))
        } else {
            const request = await requests.del(API.ATTACHMENTS + item.id + "/")
            if (request.status === 204) {
                setUploadedDocAdditions(prev => prev.filter((stateItem, key) => stateItem.id !== item.id))
            }
        }
    }

    const onRemoveContractFile = async () => {
        const request = await requests.del(API.CONTRACTS + contract.id + "/file/")
        if (request.status === 204) {
            setUploadedDoc(null)
            setContracts(prev => prev.filter(item => {
                if (item.id === contract.id) {
                    item.external_file = null
                }

                return item
            }))

        }
    }

    const isFormValid = () => {
        if (contract.external_file !== null || uploadedDoc !== null) {
            return true
        }

        return false
    }

    const Additions = () => {
        return (
            <>
                <Divider />
                <Header as="h3" content={t('additions')} />
                <Divider />
            </>
        )
    }


    return (
        <>
            <Button.Group size="small" basic fluid style={{ borderRadius: "0rem" }}>
                <Button active={true}>{t("uploaded_docs_versions")}</Button>
            </Button.Group>
            <Divider />
            <p>
                <Icon name="info circle" /> {t('uploaded_scan_versions_docs_hint')} <Link to={routes.EMPLYOEES_DETAIL + contract.employee.id} target="_blank">{t('in_employee_card_section_documents')}</Link>.
            </p>
            <Divider />
            <Form onSubmit={handleStoreFile}>
                <>
                    {contract.external_file === null
                        ?
                        <Form.Group widths="equal">
                            <CanView permissions={['contracts.c_manage_all_contracts', 'company.c_manage_only_assigned_unit_employees', 'common.c_manage_only_agency_employees', 'common.c_manage_only_internal_employees', 'common.c_manage_only_external_employees']}>
                                <SuperField as="input"
                                    required
                                    type="file"
                                    label={t('contract')}
                                    error={uploadedDocError !== null ? t('not_supported_file_type_only_pdf_docx') : false}
                                    onChange={(event) => {
                                        if (event.target.files.length > 0) {
                                            setUploadedDoc(event.target.files[0]);
                                        }
                                    }}
                                />
                            </CanView>
                            {/* <SuperField
                                as="choice"
                                search
                                allowAdditions
                                help={ t('help_group_create') }
                                onAddItem={(e, { value }) => handleAddItem(value)}
                                customOptions={documentGroups.options}
                                loading={documentGroups.isLoading}
                                disabled={documentGroups.isLoading}
                                label={t('folder')}
                                value={documentGroup}
                                onChange={(e, { value }) => setDocumentGroup(value)}
                            /> */}
                        </Form.Group>
                        :
                        <Form.Group widths="equal">
                            <Form.Field>
                                <label><strong> {t("contract")} </strong></label>
                                <div >
                                    <a href={contract.external_file} download={contract.external_file} target="_blank" rel="noopener noreferrer">
                                        <Icon name="file alternate" /> {t('contract') + " - " + contract.employee.fullname}
                                    </a>
                                    <CanView permissions={['contracts.c_manage_all_contracts', 'company.c_manage_only_assigned_unit_employees', 'common.c_manage_only_agency_employees', 'common.c_manage_only_internal_employees', 'common.c_manage_only_external_employees']}>
                                        <Action as="custom" type="icon" icon="close" tooltip={t('close')}
                                            onClick={() => onRemoveContractFile()}
                                            iconColor="var(--danger)"
                                            size="large"
                                            paddingLeft="1rem"
                                            paddingRight="1rem"
                                            paddingTop="1rem"
                                        />
                                    </CanView>
                                </div>
                            </Form.Field>
                            {/* <SuperField
                                as="choice"
                                search
                                allowAdditions
                                help={ t('help_group_create') }
                                onAddItem={(e, { value }) => handleAddItem(value)}
                                customOptions={documentGroups.options}
                                loading={documentGroups.isLoading}
                                disabled={documentGroups.isLoading}
                                label={t('folder')}
                                value={documentGroup}
                                onChange={(e, { value }) => setDocumentGroup(value)}
                            /> */}
                        </Form.Group>
                    }
                    <Additions />

                    {uploadedDocAdditions.map((item, index) => (
                        <Form.Group key={index}>
                            {item.id === ""
                                ?
                                <Form.Group widths="equal">
                                    <SuperField as="input"
                                        required
                                        type="file"
                                        error={item.error !== null ? t('not_supported_file_type_only_pdf_docx') : false}
                                        onChange={(event) => {
                                            if (event.target.files.length > 0) {
                                                onFileChange(index, "name", event.target.files[0].name);
                                                onFileChange(index, "source", event.target.files[0]);
                                            }
                                        }}
                                    />
                                    <CanView permissions={['contracts.c_manage_all_contracts', 'company.c_manage_only_assigned_unit_employees', 'common.c_manage_only_agency_employees', 'common.c_manage_only_internal_employees', 'common.c_manage_only_external_employees']}>
                                        <Form.Field style={{ marginTop: "0.7rem" }}>
                                            <Action as="custom" type="icon" icon="close" tooltip={t('close')}
                                                onClick={() => onFileRemove(index, item)}
                                                iconColor="var(--danger)"
                                                size="large"
                                                paddingLeft="1rem"
                                                paddingRight="1rem"
                                                paddingTop="0"
                                            />
                                        </Form.Field>
                                    </CanView>
                                </Form.Group>
                                :
                                <Form.Group>
                                    <Form.Field style={{ marginLeft: "0.5rem" }}>
                                        <a href={item.file} download={item.file} target="_blank" rel="noopener noreferrer">
                                            <Icon name="file alternate" /> {item.name}
                                        </a>
                                        <CanView permissions={['contracts.c_manage_all_contracts', 'company.c_manage_only_assigned_unit_employees', 'common.c_manage_only_agency_employees', 'common.c_manage_only_internal_employees', 'common.c_manage_only_external_employees']}>
                                            <Action as="custom" type="icon" icon="close" tooltip={t('close')}
                                                onClick={() => onFileRemove(index, item)}
                                                iconColor="var(--danger)"
                                                size="large"
                                                paddingLeft="1rem"
                                                paddingRight="1rem"
                                                paddingTop="0"
                                            />
                                        </CanView>
                                    </Form.Field>
                                </Form.Group>
                            }
                        </Form.Group>
                    ))}
                </>

                <CanView permissions={['contracts.c_manage_all_contracts', 'company.c_manage_only_assigned_unit_employees']}>
                    <Button type="button" size="small" primary content={t("add_addition")} onClick={onAddFileAddition} />
                </CanView>
                <Divider />
                <Form.Field style={{ textAlign: "right" }}>
                    <ModalCancel onClose={onClose} />
                    <CanView permissions={['contracts.c_manage_all_contracts']}>
                        <ModalSubmit
                            disabled={isProcessing || !isFormValid()}
                            loading={isProcessing}
                            text={t('save')}
                        />
                    </CanView>
                </Form.Field>
            </Form>
        </>
    );
};

export default Documents;