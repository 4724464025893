import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import moment from 'moment';
// store
import { API } from '@store/config';
import { patchRequest, getRequest, createRequest } from '@services/ServiceCommon';
// components
import ModalSubmit from '@components/buttons/ModalSubmit';
import SuperField from '@components/forms/SuperField';
import NonFieldErrors from '@components/NonFieldErrors';
import LabelInput from './input/LabelInput'
import { Divider, Form } from 'semantic-ui-react';

const CaseForm = ({ data, setData, setTotal, handleRequest, onClose }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false)
    // eslint-disable-next-line
    const [error, setError] = useState(null)
    const [employees, setEmployees] = useState([])

    const [form, setForm] = useState({
        id: data?.id || "",
        title: data?.title ? data.title : '',
        description: data?.description ? data.description : '',
        priority: data?.priority ? data.priority : '',
        type: data?.type?.id ? data.type.id : { id: 1, title: 'Incident' },
        label: data?.label ? data.label : [],
        business_detail: data?.business_detail ? data?.business_detail.id : '',
        assigned_to: data?.assigned_to ? data.assigned_to.map(item => item.id) : [],
        due_date: data?.due_date ? moment(data.due_date).format('YYYY-MM-DD') : '',
        status: data?.status ? data.status : ''
    })

    useEffect(() => {
        async function fetchData() {
            let result = await getRequest(API.EMPLOYEES + '?query={fullname,id}')
            if (result.status === 200) {
                let output = []
                for (let item of result.response) {
                    if (item.id) {
                        output.push({ key: item.id, value: item.id, text: item.fullname })
                    }
                }
                setEmployees(output)
            }
        }
        fetchData()
    }, [])

    async function handleSubmit() {
        setLoading(true)

        if (!form.id || form.id === 0) {
            let new_data = {
                title: form.title,
                type: form.type,
            }
            if (form.business_detail !== '') new_data = { ...new_data, business_detail: form.business_detail }
            if (form.assigned_to.length > 0) new_data = { ...new_data, assigned_to: { add: form.assigned_to } }
            if (form.label.length > 0) new_data = { ...new_data, label: { add: form.label } }
            if (form.priority !== '') new_data = { ...new_data, priority: form.priority }
            if (form.description !== '') new_data = { ...new_data, description: form.description }
            if (form.due_date !== '') new_data = { ...new_data, due_date: form.due_date + 'T23:59' }
            if (form.assigned_to.length > 0) {new_data = { ...new_data, status: 2 }}
            else{ new_data = { ...new_data, status: 1 } }

            let request = await createRequest(API.CASES, new_data)
            if (request.status === 201) {
                handleRequest(request.response, setData, setTotal)
                onClose()
            } else {
                setError(request.response)
            }
        }
        else {
            let new_data = {
                title: form.title,
                type: form.type,
                priority: form.priority,
                description: form.description,
                status: form.status,
                business_detail: form.business_detail,
            }
            if (form.assigned_to.length > 0) {
                new_data = { ...new_data, assigned_to: { remove: data.assigned_to.map(item => item.id), add: form.assigned_to } }
            }
            else if (form.assigned_to.length === 0) {
                new_data = { ...new_data, assigned_to: { remove: data.assigned_to.map(item => item.id) } }
            }
            if (form.due_date !== '') new_data = { ...new_data, due_date: form.due_date + 'T23:59' }

            let request = await patchRequest(`${API.CASES}${data.id}/`, new_data)

            if (request.status === 200) {
                handleRequest(request.response, setData, setTotal)
                onClose()
            } else {
                setError(request.response)
            }
        }
        setLoading(false)
    }

    async function changeAssigned(value) {
        if(form.status === 1 && value.length > 0){
            setForm({ ...form, assigned_to: value, status: 2 })
        }
        else if(form.status === 2 && value.length === 0){
            setForm({ ...form, assigned_to: value, status: 1 })
        }
        else{
            setForm({ ...form, assigned_to: value })
        }
    }

    return (
        <Form onSubmit={handleSubmit}>
            <NonFieldErrors errors={error} />
            <SuperField
                label={t("title")}
                required
                as='input'
                value={form.title}
                onChange={(e, { value }) => setForm({ ...form, title: value })}
            />
            <SuperField
                label={t("description")}
                as='textarea'
                value={form.description}
                onChange={(e, { value }) => setForm({ ...form, description: value })}
            />
            <Divider />
            <Form.Group widths="equal">
                {form.id === 0 &&
                    <SuperField
                        label={t("status")}
                        as='choice'
                        type="case_status_choices"
                        value={form.status?.toString() || ""}
                        onChange={(e, { value }) => setForm({ ...form, status: value })}
                    />
                }
                <SuperField
                    label={t("priority")}
                    as='choice'
                    type="priority_choices"
                    value={form.priority?.toString() || ""}
                    onChange={(e, { value }) => setForm({ ...form, priority: value })}
                />
                <SuperField as="choice"
                    search
                    label={t('type')}
                    endpoint={API.CASES_TYPES}
                    text="title"
                    value={form.type || ""}
                    onChange={(e, { value }) => setForm({ ...form, type: value })}
                />
                <SuperField
                    label={t("due_date")}
                    as='datepicker'
                    value={form.due_date}
                    onChange={(e, { value }) => setForm({ ...form, due_date: value })}
                />
            </Form.Group>
            <Divider />
            <Form.Group widths="equal">
                <SuperField
                    search
                    as='choice'
                    text="name"
                    label={t("account")}
                    value={form.business_detail}
                    onChange={(e, { value }) => setForm({ ...form, business_detail: value })}
                    endpoint={API.ACCOUNTS + "business_details/?is_account=true&exclude_unit=true&query={id, name}&is_active=true"}
                />
                <SuperField
                    multiple
                    search
                    label={t("assigned")}
                    as='choice'
                    customOptions={employees}
                    value={form.assigned_to}
                    onChange={(e, { value }) => changeAssigned(value)}
                />
            </Form.Group>
            {(form.id === 0 || !form.id) &&
                <>
                    <Divider />
                    <LabelInput
                        value={form.label}
                        onChange={(e) => setForm({ ...form, label: e })}
                    />
                </>
            }
            <ModalSubmit
                text={t("confirm")}
                disabled={form.title === ""}
                loading={loading} />
        </Form>
    );
};

export default CaseForm;