import React from 'react';
import { useTranslation } from 'react-i18next';
// components
import { Header, Divider } from 'semantic-ui-react';

const ImportInvalidReport = ({ errors }) => {
    const { t } = useTranslation()

    return (
        <>
            { errors.map(row => (
                <>
                    <Header as="h3">{ t('invalid_row')}: { (row?.invalid_row || row?.row) }</Header>
                    <>
                        { row?.errors?.map(error => (
                            <>
                                { typeof error === 'object' 
                                    ? 
                                    <>
                                    { Object.keys(error).map(key => (
                                        <>
                                            <strong> {key}: </strong> <br/>
                                            <ul>
                                                { error[key].map(message => (
                                                    <li> { message } </li>
                                                )) }
                                            </ul>
                                        </>
                                    )) }
                                    </>
                                    :
                                    <>
                                        <li style={{ marginLeft: "1.5rem" }}> { error } <br/></li>
                                    </>
                                }
                            </>
                        )) }
                    </>
                    <Divider/>
                </>
            )) }
        </>
    );
};

export default ImportInvalidReport;