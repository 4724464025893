import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-semantic-toasts';
// store 
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import { Form, Divider, Header } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import EmailField from "@components/forms/common/EmailField";
import PhoneField from "@components/forms/common/PhoneField";

const UserDemoForm = ({ setResult, onClose }) => {
    const { t } = useTranslation();

    const [isProcessing, setIsProcessing] = useState(false)
    const [form, setForm] = useState({
        email: "",
        username: "",
        firstname: "",
        lastname: "",
        phone: "",
        phoneType: null,
        gender: 3 // others
    })

    const handleSubmit = async () => {
        setIsProcessing(true)
        const request = await requests.post(API.USERS + "demo/", {
            email: form.email,
            username: form.username,
            first_name: form.firstname,
            last_name: form.lastname,
            phone: form.phone,
            phone_type: form.phoneType,
            gender: form.gender
        })

        if (request.status === 201) {
            // const requestUser = await requests.get(API.USERS + "?search=" + form.email)
            // if (requestUser.status === 200) {
            //     if (requestUser.response.length > 0) {
            //         setResult(prevState => ({
            //             ...prevState,
            //             data: {
            //                 ...prevState,
            //                 results: [requestUser.response[0], ...prevState.data.results]
            //             }
            //         }))
            //     }
            // }
            toast({
                type: 'success',
                icon: 'check circle',
                title: "Pozvánka odoslaná",
                description: "Po potvrdení bude vytvorený prístup.",
                animation: 'bounce',
                time: 5000,
            })
            onClose()
        }
        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group widths="equal">
                <SuperField as="input"
                    required
                    label={t('firstname')}
                    value={form.firstname}
                    onChange={(e, { value }) => setForm({ ...form, firstname: value })}
                />

                <SuperField as="input"
                    required
                    label={t('lastname')}
                    value={form.lastname}
                    onChange={(e, { value }) => setForm({ ...form, lastname: value })}
                />
            </Form.Group>

            <Form.Group style={{ display: 'block', paddingLeft: '0.5rem' }}>
                <b>{t('gender')}</b>
                <div style={{ display: 'flex' }}>
                    <SuperField as='radio' label={t('male')} checked={form.gender === 1} onChange={() => setForm({ ...form, gender: 1 })} style={{ marginRight: '1rem' }} />
                    <SuperField as='radio' label={t('female')} checked={form.gender === 2} onChange={() => setForm({ ...form, gender: 2 })} />
                </div>
            </Form.Group>

            <Divider />
            <Header as="h3" content={t('user_access')} />
            <Divider />

            <Form.Group widths="equal">
                <PhoneField
                    required
                    placeholder={t('enter_number')}
                    value={form.phone}
                    setValue={(e, { value }) => setForm({ ...form, phone: value })}
                    phoneType={form.phoneType}
                    setPhoneType={(e, { value }) => setForm({ ...form, phoneType: value })}
                />
                <EmailField
                    required
                    label={t('email')}
                    value={form.email}
                    setValue={(e, { value }) => setForm({
                        ...form,
                        username: value.split("@")[0],
                        email: value
                    })}
                />
            </Form.Group>

            <SuperField as="input"
                required
                label={t('username')}
                value={form.username}
                onChange={(e, { value }) => setForm({ ...form, username: value })}
            />

            <Divider />
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    disabled={isProcessing}
                    loading={isProcessing}
                    text={t('confirm')}
                />
            </Form.Field>
        </Form>
    );
};

export default UserDemoForm;