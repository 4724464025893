import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-semantic-toasts';
import { useSelector } from 'react-redux';
import moment from 'moment';
import '@store/languages';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { tzDateTime } from '@helpers/dates';
// components
import Icon from '@components/Icon';
import Action from '@components/general/Action';
import CanView from '@components/perms/CanView';
import SuperField from '@components/forms/SuperField';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import { Divider, Form, Button, Segment, Table, Popup } from 'semantic-ui-react';
// specific component
import ShiftManagement from '../components/ShiftManagement';

const ManageDayAttendance = () => {
    const { t } = useTranslation()
    const profile_id = useSelector(state => state?.user?.profile_id || null)
    const [loading, setLoading] = useState(false)
    const [isGenerated, setIsGenerated] = useState(false)
    const [day, setDay] = useState(moment().format("YYYY-MM-DD"))
    const [unit, setUnit] = useState("")
    const [employees, setEmployees] = useState([])
    const [loadingShifts, setLoadingShifts] = useState(false)
    const [shifts, setShifts] = useState([])
    const [savingAll, setSavingAll] = useState(false)

    const [loadingUnits, setLoadingUnits] = useState(false)
    const [units, setUnits] = useState([])


    const generateDayAttendance = async () => {
        setLoading(true)
        const query = "?query={id, name}"
        let request = await requests.get(API.UNITS + unit + "/employees/" + query)
        if (request.status === 200) {
            if (request.response.length > 0){
                const records = []
                const request_records = await requests.get(API.ATTENDANCE + "?date_from=" + moment(day).format('YYYY-MM-DD') + "&date_to=" + moment(day).format('YYYY-MM-DD'))
                if (request_records.status === 200) {
                    for (let i = 0; i < request.response.length; i++) {
                        const record_instance = request_records.response.find(item => request.response[i].id === item.employee.id)

                        // convert to timezone
                        let start_time = record_instance?.start ? tzDateTime(record_instance?.start).format("HH:mm") : ""
                        let end_time = record_instance?.end ? tzDateTime(record_instance?.end).format("HH:mm") : ""

                        records.push({
                            ...request.response[i],
                            record_id: record_instance?.id || 0,
                            shift: record_instance?.shift?.id || "",
                            lunch_voucher: record_instance?.lunch_voucher || "",
                            vacation: record_instance?.vacation || "",
                            absention: record_instance?.absention || "",
                            paragraph: record_instance?.paragraph || "",
                            sick_day: record_instance?.sick_day || "",
                            auto_break: record_instance?.auto_break || "",
                            time_from: start_time,
                            time_to: end_time,
                            duration: record_instance?.duration || "",
                            work_hours: record_instance?.work_hours || "",
                            errors: null,
                            is_processing: false,
                            closed: record_instance?.closed === undefined ? false : record_instance?.closed
                        })
                    }

                }
                setEmployees(records)
            }
        }
        setIsGenerated(true)
        setLoading(false)
    }

    const handleShiftConvertion = (shift) => {
        if (!["", null].includes(shift.time_from)) {
            let datetime_from = moment(moment().format("YYYY-MM-DD") + " " + shift.time_from).format("YYYY-MM-DD HH:mm")
            // convert to Localtime:
            shift.time_from = tzDateTime(datetime_from).format("HH:mm")
        }

        if (!["", null].includes(shift.time_to)) {
            let datetime_to = moment(moment().format("YYYY-MM-DD") + " " + shift.time_to).format("YYYY-MM-DD HH:mm")
            // convert to UTC:
            shift.time_to = tzDateTime(datetime_to).format("HH:mm")
        }

        if (!["", null].includes(shift.pause_length)) {
            // convert from minutes to hours:
            if (parseFloat(shift.pause_length) > 0) {
                shift.pause_length = parseFloat(shift.pause_length)
            }
        }
        return shift
    } 

    const fetchShifts = async () => {
        setLoadingShifts(true)
        const request = await requests.get(API.ATTENDANCE_BASE + "shifts/")
        
        if (request.status === 200) {
            setShifts(request.response.map(item => ({ ...handleShiftConvertion(item), is_processing: false, errors: null })))
        }
        setLoadingShifts(false)
    }

    const fetchUnits = async () => {
        setLoadingUnits(true)
        const request = await requests.get(API.UNITS + "?query={id, name}&only_from_active_company=true&responsible_persons=" + profile_id)
        if (request.status === 200) {
            const unitList = request.response.map(item => ({
                id: item.id,
                value: item.id,
                text: item.name
            })) 
            setUnits(unitList)

            if (unitList.length === 1) setUnit(unitList[0].value)
        }
        setLoadingUnits(false)
    }

    useEffect( () => {
        fetchShifts()
        fetchUnits()
        // eslint-disable-next-line
    }, [])

    const handleChange = (index, value, attribute) => {
        setEmployees(prev => prev.filter((item, idx) => {
            if (index === idx) {
                item[attribute] = value
            }
            
            return item
        }))
    }

    const autoFillShift = (shift_id, idx) => {
        const shift = shifts.find(item => item.id === shift_id)

        if (shift) {
            if (![null, ""].includes(shift.time_from)) {
                handleChange(idx, shift.time_from, "time_from")
            }
            if (![null, ""].includes(shift.time_to)) {
                handleChange(idx, shift.time_to, "time_to")
            }

            handleChange(idx, shift.length, "duration")
            handleChange(idx, ["", null, 0, undefined].includes(shift.pause_length) ? false : true, "auto_break")
        }
    }

    const onSaveAllChanges = async () => {
        setSavingAll(true)
        // save all available records by checking employee record id to decide if its update or create action (speed-up action)
        for (let i = 0; i < employees.length; i++) {
            await onRecordConfirm(i)
        }
        setSavingAll(false)
    }

    const onRecordDelete = async (id, index) => {
        handleChange(index, true, "is_processing")

        const request = await requests.post(API.ATTENDANCE + id + "/delete/")
        if (request.status === 200) {
            setEmployees(prev => prev.filter(item => {
                if (item.record_id === id) {
                    item.record_id = 0
                    item.shift = ""
                    item.vacation = ""
                    item.absention = ""
                    item.paragraph = ""
                    item.sick_day = ""
                    item.auto_break = ""
                    item.time_from = ""
                    item.time_to = ""
                    item.duration = ""
                    item.work_hours = ""
                    item.errors = null
                }

                return item
            }))
        }

        handleChange(index, false, "is_processing")
    } 

    const onRecordConfirm = async (index) => {
        handleChange(index, true, "is_processing")
        handleChange(index, null, "errors")
        const record = employees.find((item, idx) => idx === index)
        
        if (record) {
            let time_from = record.time_from
            if (![null, ""].includes(time_from)) {
                time_from = moment(day + " " + time_from)
                time_from = tzDateTime(time_from, true).format("YYYY-MM-DD HH:mm:ss")
            } else {
                time_from = null
            }
            
            let time_to = record.time_to
            if (![null, ""].includes(time_to)) {
                time_to = moment(day + " " + time_to)
                time_to = tzDateTime(time_to, true).format("YYYY-MM-DD HH:mm:ss")
            } else {
                time_to = null
            }

            if (time_to !== null && time_from !== null) {
                // do diff and if its negative then increment day for time_to 
                const duration = moment.duration(moment(time_to).diff(moment(time_from)))
                const hours = duration.asHours()

                if (hours < 0) { // add day to time_to
                    time_to = moment(time_to)
                    time_to = time_to.add('days', 1).format("YYYY-MM-DD HH:mm:ss")
                }
            }

            const data = {
                date: day,
                start: time_from,
                end: time_to,
                lunch_voucher: record.lunch_voucher === "" ? null : record.lunch_voucher,
                duration: record.duration === "" ? null : record.duration,
                // "travel_bonus": "string",
                auto_break: record.auto_break === "" ? false : record.auto_break,
                vacation: record.vacation === "" ? null : record.vacation,
                absention: record.absention === "" ? null : record.absention,
                paragraph: record.paragraph === "" ? null : record.paragraph,
                sick_day: record.sick_day === "" ? null : record.sick_day,
                // "require_manual_correction": true,
                // "note": "string",
                employee: record.id,
                shift: record.shift === "" ? null : record.shift
            }

            let request = null
            if (record.record_id === 0) { // create
                request = await requests.post(API.ATTENDANCE_MANUAL, data)
            } else {
                request = await requests.patch(API.ATTENDANCE + record.record_id + "/", data)
            }

            if (request?.status === 400) handleChange(index, request.response, "errors")
            if (request?.status === 201) handleChange(index, request.response.id, "record_id")

            if ([200, 201].includes(request?.status)) {
                handleChange(index, request.response.duration, "duration")
                handleChange(index, request.response.work_hours, "work_hours")
                handleChange(index, request.response?.shift?.id || "", "shift")
                handleChange(index, request.response?.closed || "", "closed")
                toast({
                    type: 'success',
                    icon: 'check circle',
                    title: t('success'),
                    description: "",
                    animation: 'bounce',
                    time: 3000,
                })
            }

        } else {
            // alert with error/warning
            toast({
                type: 'error',
                icon: 'warning circle',
                title: t('error'),
                description: "",
                animation: 'bounce',
                time: 5000,
            })
        }

        
        handleChange(index, false, "is_processing")
    }

    const applyToAll = (field) => {
        const findRecord = employees[0]
        if (findRecord) {
            for (let i = 0; i < employees.length; i++) {
                handleChange(i, findRecord?.[field] || "", field)
                if (field === "shift") {
                    autoFillShift(findRecord?.[field], i)
                }
            }
        }
    }

    const handleCloseAction = async (record) => {
        const request = await requests.patch(API.ATTENDANCE + record.record_id + "/", { closed: !record.closed })
        if (request.status === 200) {
            if (request.response.end !== null) {
                toast({
                    type: 'error',
                    icon: 'warning',
                    title: t('record_can_not_be_changed_already_locked_hint'),
                    animation: 'pulse',
                    time: 5000,
                })
            } else {
                setEmployees(prev => prev.map(item => {
                    if (item.record_id === record.record_id) {
                        item = {
                            ...item,
                            closed: request.response.closed,
                        }
                    }
    
                    return item
                }))
            }
        }
    }

    return (
        <>
            <Form>
                <Form.Group>
                    <SuperField as="choice"
                        search
                        width="2"
                        value={unit}
                        customOptions={units}
                        disabled={loadingUnits}
                        loading={loadingUnits}
                        onChange={(e, { value }) => {
                            if (value === "") setIsGenerated(false)
                            setUnit(value)
                        }}
                    />
                    <SuperField as="datepicker"
                        style={{ marginTop: "0.25rem" }}
                        value={day}
                        onChange={(e, { value }) => {
                            if (value === "") setIsGenerated(false)
                            setDay(value)
                        }}
                    />
                    <Form.Field style={{ marginTop: "0.3rem" }}>
                        <Button 
                            primary
                            type="button"
                            disabled={unit === "" || day === ""}
                            onClick={() => generateDayAttendance()}
                        >
                            { t('confirm') }
                        </Button>
                    </Form.Field>
                    <CanView permissions={['attendance.c_manage_shifts']}>
                        <Form.Field style={{ marginTop: "0.3rem" }}>
                            <SuperDuperModal
                                size="large"
                                header={t('manage_shifts')}
                                trigger={
                                    <Button 
                                        disabled={loadingShifts}
                                        type="button"
                                        style={{ background: "var(--dark)", color: "var(--white)" }}
                                    >
                                        { t('manage_shifts') }
                                    </Button>
                                }
                                content={
                                    <ShiftManagement shifts={shifts} setShifts={setShifts}/>
                                }
                            />
                        </Form.Field>
                    </CanView>
                </Form.Group>
            </Form>
            <Divider/>
            <Segment 
                loading={loading}
                style={{ 
                    padding: 0, 
                    background: "transparent", 
                    boxShadow: "none", 
                    border: "none",
                    marginBottom: "1rem",
                    marginTop: "1rem",
                }}
            >
                { loading && 
                    <p style={{ textAlign: "center", color: "var(--dark)", paddingTop: "6rem" }}> 
                    { t('loading_day_attendance') } 
                    </p>
                }

                { (!isGenerated && !loading) && 
                    <p style={{ textAlign: "center", color: "var(--dark)", paddingTop: "1rem", fontWeight: "bold" }}> 
                        { t('select_parameters_to_generate_day_attendance') } 
                    </p>
                }

                { (!loading && isGenerated) && 
                <Form>
                    <div style={{ padding: 0, overflow: "auto", paddingBottom: "10rem", width: "100%" }}>
                        <Table striped selectable unstackable className="table responsive" size="small">
                            <Table.Header>
                                <Table.Row verticalAlign="middle">
                                    <Table.HeaderCell content={t('fullname')}/>
                                    <Table.HeaderCell>
                                        <span style={{ position: "relative", top: "-0.2rem", textTransform: "capitalize" }}> {t('shift')} </span>
                                        <Popup
                                            position="top center"
                                            trigger={
                                                <Icon 
                                                    name="chevron-down-circle-outline"
                                                    style={{
                                                        fontSize: "1.1rem",
                                                        marginLeft: "0.5rem",
                                                        cursor: "pointer",
                                                        fontWeight: "bold"
                                                    }}
                                                    onClick={() => applyToAll('shift')}
                                                />
                                            }
                                            content={t('apply_to_all_attendance_hint')}
                                        />
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <span style={{ position: "relative", top: "-0.2rem" }}> {t('time_from')} </span>
                                        <Popup
                                            position="top center"
                                            trigger={
                                                <Icon 
                                                    name="chevron-down-circle-outline"
                                                    style={{
                                                        fontSize: "1.1rem",
                                                        marginLeft: "0.5rem",
                                                        cursor: "pointer",
                                                        fontWeight: "bold"
                                                    }}
                                                    onClick={() => applyToAll('time_from')}
                                                />
                                            }
                                            content={t('apply_to_all_attendance_hint')}
                                        />
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <span style={{ position: "relative", top: "-0.2rem" }}> {t('time_to')} </span>
                                        <Popup
                                            position="top center"
                                            trigger={
                                                <Icon 
                                                    name="chevron-down-circle-outline"
                                                    style={{
                                                        fontSize: "1.1rem",
                                                        marginLeft: "0.5rem",
                                                        cursor: "pointer",
                                                        fontWeight: "bold"
                                                    }}
                                                    onClick={() => applyToAll('time_to')}
                                                />
                                            }
                                            content={t('apply_to_all_attendance_hint')}
                                        />
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <span style={{ position: "relative", top: "-0.2rem" }}> {t('duration')} </span>
                                        <Popup
                                            position="top center"
                                            trigger={
                                                <Icon
                                                    name="information-circle-outline"
                                                    style={{
                                                        fontSize: "1.1rem",
                                                        marginLeft: "0.5rem",
                                                    }}
                                                />
                                            }
                                            content={t('enter_value_excluding_pause')}
                                        />
                                        <Popup
                                            position="top center"
                                            trigger={
                                                <Icon 
                                                    name="chevron-down-circle-outline"
                                                    style={{
                                                        fontSize: "1.1rem",
                                                        marginLeft: "0.5rem",
                                                        cursor: "pointer",
                                                        fontWeight: "bold"
                                                    }}
                                                    onClick={() => applyToAll('duration')}
                                                />
                                            }
                                            content={t('apply_to_all_attendance_hint')}
                                        />
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <span style={{ position: "relative", top: "-0.2rem" }}> {t('pause')} </span>
                                        <Popup
                                            position="top center"
                                            trigger={
                                                <Icon 
                                                    name="chevron-down-circle-outline"
                                                    style={{
                                                        fontSize: "1.1rem",
                                                        marginLeft: "0.5rem",
                                                        cursor: "pointer",
                                                        fontWeight: "bold"
                                                    }}
                                                    onClick={() => applyToAll('auto_break')}
                                                />
                                            }
                                            content={t('apply_to_all_attendance_hint')}
                                        />
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <span style={{ position: "relative", top: "-0.2rem" }}> {t('working_hours')} </span>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell textAlign="center" >
                                        <span style={{ position: "relative", top: "-0.2rem" }}> {t('lunch_voucher')} </span>
                                        <Popup
                                            position="top center"
                                            trigger={
                                                <Icon 
                                                    name="chevron-down-circle-outline"
                                                    style={{
                                                        fontSize: "1.1rem",
                                                        marginLeft: "0.5rem",
                                                        cursor: "pointer",
                                                        fontWeight: "bold"
                                                    }}
                                                    onClick={() => applyToAll('lunch_voucher')}
                                                />
                                            }
                                            content={t('apply_to_all_attendance_hint')}
                                        />
                                    </Table.HeaderCell>
                                    <Table.HeaderCell textAlign="center" >
                                        <span style={{ position: "relative", top: "-0.2rem" }}> {t('absention')} </span>
                                        <Popup
                                            position="top center"
                                            trigger={
                                                <Icon 
                                                    name="chevron-down-circle-outline"
                                                    style={{
                                                        fontSize: "1.1rem",
                                                        marginLeft: "0.5rem",
                                                        cursor: "pointer",
                                                        fontWeight: "bold"
                                                    }}
                                                    onClick={() => applyToAll('absention')}
                                                />
                                            }
                                            content={t('apply_to_all_attendance_hint')}
                                        />
                                    </Table.HeaderCell>
                                    <Table.HeaderCell textAlign="center" >
                                        <span style={{ position: "relative", top: "-0.2rem" }}> {t('vacation')} </span>
                                        <Popup
                                            position="top center"
                                            trigger={
                                                <Icon 
                                                    name="chevron-down-circle-outline"
                                                    style={{
                                                        fontSize: "1.1rem",
                                                        marginLeft: "0.5rem",
                                                        cursor: "pointer",
                                                        fontWeight: "bold"
                                                    }}
                                                    onClick={() => applyToAll('vacation')}
                                                />
                                            }
                                            content={t('apply_to_all_attendance_hint')}
                                        />
                                    </Table.HeaderCell>
                                    <Table.HeaderCell textAlign="center" >
                                        <span style={{ position: "relative", top: "-0.2rem" }}> {t('paragraph')} </span>
                                        <Popup
                                            position="top center"
                                            trigger={
                                                <Icon 
                                                    name="chevron-down-circle-outline"
                                                    style={{
                                                        fontSize: "1.1rem",
                                                        marginLeft: "0.5rem",
                                                        cursor: "pointer",
                                                        fontWeight: "bold"
                                                    }}
                                                    onClick={() => applyToAll('paragraph')}
                                                />
                                            }
                                            content={t('apply_to_all_attendance_hint')}
                                        />
                                    </Table.HeaderCell>
                                    <Table.HeaderCell textAlign="center" >
                                        <span style={{ position: "relative", top: "-0.2rem" }}> {t('sick_day')} </span>
                                        <Popup
                                            position="top center"
                                            trigger={
                                                <Icon 
                                                    name="chevron-down-circle-outline"
                                                    style={{
                                                        fontSize: "1.1rem",
                                                        marginLeft: "0.5rem",
                                                        cursor: "pointer",
                                                        fontWeight: "bold"
                                                    }}
                                                    onClick={() => applyToAll('sick_day')}
                                                />
                                            }
                                            content={t('apply_to_all_attendance_hint')}
                                        />
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width="2">
                                        <Button
                                            fluid
                                            primary
                                            type="button"
                                            size="tiny"
                                            disabled={employees.length === 0 || savingAll}
                                            loading={savingAll}
                                            content={t('save_all_changes')}
                                            onClick={() => onSaveAllChanges()}
                                        />
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                { employees.length === 0 &&
                                    <Table.Row verticalAlign="middle" textAlign="center">
                                        <Table.Cell colspan="13" content={t('no_data')}/>
                                    </Table.Row>
                                }
                                { employees.map((employee, index) => (
                                    <Table.Row verticalAlign="middle" key={index}>
                                        <Table.Cell width="2"> { employee.name } </Table.Cell>
                                        <Table.Cell width="2"> 
                                            <SuperField as="choice"
                                                search
                                                value={employee?.shift || ""}
                                                loading={loadingShifts}
                                                disabled={loadingShifts}
                                                customOptions={shifts.map(item => ({
                                                    key: item.id,
                                                    value: item.id,
                                                    text: item.title
                                                }))}
                                                style={{ marginTop: "0.25rem" }}
                                                onChange={(e, { value }) => {
                                                    autoFillShift(value, index)
                                                    handleChange(index, value, "shift")
                                                }}
                                            />
                                        </Table.Cell>
                                        <Table.Cell width="1"> 
                                            <SuperField 
                                                as="timepicker"
                                                style={{ width: "100px" }}
                                                value={employee?.time_from || ""}
                                                onChange={(e, { value }) => handleChange(index, value, "time_from")}
                                            /> 
                                        </Table.Cell>
                                        <Table.Cell width="1"> 
                                            <SuperField 
                                                as="timepicker"
                                                style={{ width: "100px" }}
                                                value={employee?.time_to || ""}
                                                onChange={(e, { value }) => handleChange(index, value, "time_to")}
                                            /> 
                                        </Table.Cell>
                                        <Table.Cell width="1"> 
                                            <SuperField 
                                                as="input"
                                                style={{ width: "100px" }}
                                                value={employee?.duration || ""}
                                                onChange={(e, { value }) => handleChange(index, value.replace(",", "."), "duration")}
                                            /> 
                                        </Table.Cell>
                                        <Table.Cell width="1" textAlign="center"> 
                                            <SuperField 
                                                as="checkbox"
                                                style={{ width: "100px" }}
                                                checked={employee?.auto_break || false}
                                                onChange={() => handleChange(index, !employee?.auto_break, "auto_break")}
                                            /> 
                                        </Table.Cell>
                                        <Table.Cell width="1" textAlign="left"> 
                                            <strong>
                                                { employee?.work_hours 
                                                    ? <> { employee?.work_hours || 0 } <span style={{ textTransform: "lowercase" }}>{t('hours_shortcut')}</span>.</>
                                                    : "--" 
                                                }
                                            </strong>
                                        </Table.Cell>
                                        <Table.Cell width="1" textAlign="center"> 
                                            <SuperField 
                                                as="input"
                                                style={{ width: "100px" }}
                                                value={employee?.lunch_voucher}
                                                className="input-centered-value"
                                                onChange={(e, {value}) => handleChange(index, value, "lunch_voucher")}
                                            /> 
                                        </Table.Cell>
                                        <Table.Cell width="1" textAlign="center"> 
                                            <SuperField 
                                                as="input"
                                                style={{ width: "100px" }}
                                                value={employee?.absention}
                                                className="input-centered-value"
                                                onChange={(e, {value}) => handleChange(index, value, "absention")}
                                            /> 
                                        </Table.Cell>
                                        <Table.Cell width="1" textAlign="center"> 
                                            <SuperField 
                                                as="input"
                                                style={{ width: "100px" }}
                                                value={employee?.vacation}
                                                className="input-centered-value"
                                                onChange={(e, {value}) => handleChange(index, value, "vacation")}
                                            /> 
                                        </Table.Cell>
                                        <Table.Cell width="1" textAlign="center"> 
                                            <SuperField 
                                                as="input"
                                                style={{ width: "100px" }}
                                                value={employee?.paragraph}
                                                className="input-centered-value"
                                                onChange={(e, {value}) => handleChange(index, value, "paragraph")}
                                            /> 
                                        </Table.Cell>
                                        <Table.Cell width="1" textAlign="center"> 
                                            <SuperField 
                                                as="input"
                                                style={{ width: "100px" }}
                                                value={employee?.sick_day}
                                                className="input-centered-value"
                                                onChange={(e, {value}) => handleChange(index, value, "sick_day")}
                                            /> 
                                        </Table.Cell>
                                        <Table.Cell width="2"> 
                                            <Button.Group size="tiny" widths="equal">
                                                <Button
                                                    type="button"
                                                    size="tiny"
                                                    content={t('save')}
                                                    style={{
                                                        background: "var(--dark)",
                                                        color: "var(--white)"
                                                    }}
                                                    disabled={employee.is_processing}
                                                    loading={employee.is_processing}
                                                    onClick={() => onRecordConfirm(index)}
                                                />
                                                <Button
                                                    size="large"
                                                    type="button"
                                                    style={{ background: "transparent" }}
                                                    loading={employee.is_processing}
                                                    disabled={employee.is_processing}
                                                >
                                                    { employee?.record_id > 0 && 
                                                        <Action as="delete"
                                                            tooltip={t('delete')}
                                                            text={t('are_you_sure_that_you_want_to_remove_record')}
                                                            onClick={() => onRecordDelete(employee?.record_id, index)}
                                                        />
                                                    }
                                                </Button>
                                            </Button.Group>
                                            { employee?.record_id > 0 && 
                                                <Popup
                                                    position="right center"
                                                    content={employee?.closed === true ? t('reopen_closed_day') : t('close_day')}
                                                    trigger={
                                                        <Icon
                                                            style={{ cursor: "pointer", fontSize: "1.2rem", color: employee?.closed === true ? "var(--dark)" : "green" }}
                                                            onClick={() => handleCloseAction(employee)}
                                                            name={employee?.closed === true ? "lock-closed" : "lock-open"}
                                                        />
                                                    }
                                                />
                                            }    
                                        </Table.Cell>
                                    </Table.Row>
                                )) }
                            </Table.Body>
                        </Table>
                    </div>
                </Form>
                }
            </Segment>
        </>
    );
};

export default ManageDayAttendance;