import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
// store
import { routes } from '@routes';
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { thousandsSeparators } from '@helpers/functions';
import { useHasPermission, useIsMount } from '@helpers/hooks';
// components
import Icon from '@components/Icon';
import ListView from '@components/ListView';
import AvatarList from '@components/AvatarList';
import Attachments from '@components/Attachments';
import SuperField from '@components/forms/SuperField';
import LabelsList from '@components/lists/LabelsList';
import { Form, Popup, Label } from 'semantic-ui-react';
import GenerateDocuments from '@components/GenerateDocuments';
// shared components
import OrderState from '../shared/OrderState';
import OrderDetail from '../shared/OrderDetail';
// specific components
import SaleOrderForm from './components/SaleOrderForm';
import SaleOrderFulfillment from './components/SaleOrderFulfillment';

const SaleOrder = ({ isPurchase }) => {
    const { t } = useTranslation()
    const isMount = useIsMount()
    
    const [attachmentsOpen, setAttachmentsOpen] = useState(0)
    const canManage = useHasPermission('orders.c_manage_order')

    const [accounts, setAccounts] = useState([])
    const [employees, setEmployees] = useState([])
    const [labels, setLabels] = useState([])
    const [units, setUnits] = useState([])
    const [isPurchaseOrder, setIsPurchaseOrder] = useState(isPurchase)

    useEffect(() => {
        async function fetchAccounts(){
            const request = await requests.get(API.ACCOUNTS + `business_details/?query={id, name}&${isPurchaseOrder ? "is_supplier" : "is_account"}=true&is_active=true&exclude_unit=true`)
            if (request.status === 200) {
                setAccounts(request.response.map(item => ({ key: item.id, value: item.id, text: item.name })))
            }
        }

        async function fetchEmployees(){
            const request = await requests.get(API.EMPLOYEES + "?only_basic_info=true&is_active=true")
            if (request.status === 200) {
                setEmployees(request.response.map(item => ({ key: item.id, value: item.id, text: item.fullname_with_titles })))
            }
        }

        async function fetchLabels(){
            const request = await requests.get(API.ORDERS + "labels/?query={id, name}")
            if (request.status === 200) {
                setLabels(request.response.map(item => ({ key: item.id, value: item.id, text: item.name })))
            }
        }
        async function fetchUnits(){
            const request = await requests.get(API.UNITS + "?only_from_active_company=true&is_business=true&query={id, name}")
            if (request.status === 200) {
                setUnits(request.response.map(item => ({ key: item.id, value: item.id, text: item.name })))
            }
        }

        fetchAccounts()
        if (isMount) {
            fetchEmployees()
            fetchLabels()
            fetchUnits()
        }
        // eslint-disable-next-line
    }, [isPurchaseOrder])

    const saleItemPricePreview = (items) => {
        if (items.length === 0) return 0
        const currencies = []
        for (const item of items) {
            if (!currencies.includes(item.currency)) {
                currencies.push(item.currency)
            }
        }

        let totals = []
        for (let i = 0; i < currencies.length; i++) {
            // calculate total for currency
            let total = 0
            let currency = currencies[i]
            for (let y = 0; y < items.length; y++) {
                if (items[y].currency === currency) {
                    total += items[y].price_without_tax * items[y].quantity
                }
            }    
            
            totals.push({
                currency: currency,
                sum: total
            })
        }

        return (
            <div style={{ display: "flex", flexDirection: "column" }}>
                {
                    totals.map(total => (
                        <span> { thousandsSeparators(parseFloat(total.sum).toFixed(2)) } { total.currency } </span>
                    ))
                }
            </div>
        )
    }

    return (
        <ListView
            as="table"
            allowSearch
            allowSelection
            actionsCellWidth="2"
            isSoftDeleteAllowed
            activeLabelOnRender={isPurchase ? 1 : 0 }
            endpoint={API.ORDERS}
            initialFilters={{
                is_purchase_order: isPurchaseOrder,
                ordering: "-created_on",
                account: "",
                labels: [],
                assigned_to: [],
                order_manager: "",
                owner: "",
                order_status: "",
                is_active: true,
                resource_type: "saleorder"
            }}
            onResponse={(response, filters) => {
                setIsPurchaseOrder(filters.is_purchase_order)
            }}
            bulkActions={ (selected) => [
                { 
                    as: "modal",
                    name: t('generate_documents'),
                    icon: "document-text-outline",
                    modal: <GenerateDocuments source="orders.SaleOrder" selected={selected} />
                }
            ]}
            listAdditionActions={() => [
                {
                    as: 'filter',
                    index: 0,
                    name: t('received_orders'),
                    filters: {
                        is_purchase_order: false,
                    },
                },
                {
                    as: 'filter',
                    index: 1,
                    name: t('issued_orders'),
                    filters: {
                        is_purchase_order: true,
                    },
                },
            ]}
            tableHeaders={[
                { title: t('order_num'), orderBy: "order_number" },
                { title: t('name'), orderBy: "name" },
                { title: isPurchaseOrder ? t('account') : t('supplier'), orderBy: "owner__name" },
                { title: isPurchaseOrder ? t('supplier') : t('account'), orderBy: "business_detail__name" },
                { title: t('items') },
                { title: t('price_without_tax') },
                { title: t('labels') },
                { title: t('order_manager'), orderBy: "order_manager__fullname" },
                { title: t('responsible_persons')},
                { title: t('state'), orderBy: "order_status" },
                { title: t('documents') },
                // { title: t('status'), orderBy: "is_active" },
            ]}
            exports={[
                {
                    endpoint: API.EXPORTS + "dynamic_sale_order/",
                    permissions: ['orders.c_view_order'],
                    apply_filters: true,
                    type: "xlsx",
                    filename: "sale_orders"
                },
            ]}
            renderFilterFields={(filters, setFilters) => (
                <>
                    <SuperField as="choice"
                        search
                        key={isPurchaseOrder}
                        label={isPurchaseOrder ? t('supplier') : t('account')}
                        value={filters.account}
                        customOptions={accounts}
                        onChange={(e, { value }) => setFilters({ ...filters, account: value })}
                    />
                    <Form.Group widths="equal">
                        <SuperField as="choice"
                            search
                            label={t('order_manager')}
                            value={filters.order_manager}
                            customOptions={employees}
                            onChange={(e, { value }) => setFilters({ ...filters, order_manager: value })}
                        />
                        <SuperField as="choice"
                            search
                            key={isPurchaseOrder}
                            label={isPurchaseOrder ? t('account') : t('supplier')}
                            value={filters.owner}
                            customOptions={units}
                            onChange={(e, { value }) => setFilters({ ...filters, owner: value })}
                        />
                    </Form.Group>
                    <SuperField as="choice"
                        search
                        multiple
                        label={t('responsible_persons')}
                        value={filters.assigned_to}
                        customOptions={employees}
                        onChange={(e, { value }) => setFilters({ ...filters, assigned_to: value })}
                    />

                    <SuperField as="choice"
                        search
                        multiple
                        label={t('labels')}
                        value={filters.labels}
                        customOptions={labels}
                        onChange={(e, { value }) => setFilters({ ...filters, labels: value })}
                    />
                    <Form.Group widths="equal">
                        <SuperField as="choice"
                            label={t('state')}
                            value={filters.order_status}
                            type="order_status_choices"
                            onChange={(e, { value }) => setFilters({ ...filters, order_status: value })}
                        />
                        <SuperField as="choice"
                            label={t('status')}
                            value={filters.is_active}
                            customOptions={[
                                { key: 1, value: true, text: t('active_order') },
                                { key: 2, value: false, text: t('closed_order') },
                            ]}
                            onChange={(e, { value }) => setFilters({ ...filters, is_active: value })}
                        />
                    </Form.Group>
                </>
            )}
            actions={[
                {
                    as: "modal",
                    type: "add",
                    name: t('create_order'),
                    modal: <SaleOrderForm isPurchase={isPurchaseOrder} accounts={accounts} employees={employees} owners={units}/>,
                    permissions: ['orders.c_manage_order'],
                },
                {
                    as: "modal",
                    type: "custom",
                    icon: "add-circle-outline",
                    name: t('create_fulfillment'),
                    customIconColor: "var(--dark)",
                    modal: (item, setData) => <SaleOrderFulfillment
                        order={item}
                        setData={setData}
                    />,
                    permissions: ['orders.c_manage_order_fulfillment']
                },
                
                {
                    as: "modal",
                    type: "custom",
                    modalSize: "large",
                    icon: "eye-outline",
                    name: t('order_detail'),
                    customIconColor: "var(--primary)",
                    modal: (item) => <OrderDetail id={item.id}/>,
                    permissions: ['orders.c_view_order']
                },
                {
                    as: "modal",
                    type: "edit",
                    name: t('edit'),
                    permissions: ['orders.c_manage_order'],
                    modal: <SaleOrderForm isPurchase={isPurchaseOrder} accounts={accounts} employees={employees} owners={units}/>,
                },
                {
                    name: t('delete'),
                    type: "delete",
                    as: "delete",
                    text: t('delete_order'),
                    permissions: ['orders.c_delete_order'],
                },
            ]}
            renderCells={(order, setData) => ([
                { width: 1, content: order.order_number },
                { customWidth: "150px", content: 
                    <>
                        <strong title={order.name}> { order.name } </strong>
                        { order.business_contract_reference && <small><br/> { order.business_contract_reference } </small> }
                    </>
                },
                { content: order.owner?.name || "" },
                { width: 1, content: 
                    <>
                        { order?.business_detail?.id ?
                            <>
                                <Link target="_blank" to={(isPurchase ? routes.SUPPLIERS : routes.ACCOUNTS) + order.business_detail.id}>
                                    { order.business_detail?.name }
                                </Link>
                            </>
                            : "--"
                        }
                    </>
                },
                { content: order.items.length },
                { content: 
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        { saleItemPricePreview(order.items) }
                        <sup style={{ marginLeft: "0.5rem" }}>
                            <Popup
                                basic
                                trigger={
                                    <Icon name="information-circle" style={{ fontSize: "1rem" }}/>
                                }
                                content={
                                    <>
                                        <strong>{ t('total_fulfilled') }: { " " }</strong> <br/>
                                        <>
                                            { order.total_price?.length === 0 && 0 + " EUR" }
                                            <div>
                                                { order.total_price?.map((currency, index) => (
                                                    <>
                                                        { thousandsSeparators(currency?.sum || 0) + " " + currency.sum_currency } <br/>
                                                    </>
                                                )) }
                                            </div>
                                        </> 
                                    </>
                                }
                            />
                        </sup>
                    </div>
                },
                { customWidth: "200px", content: 
                    <LabelsList
                        key={order.id}
                        rowID={order.id}
                        labels={order.labels}
                        endpoint={API.ORDERS}
                        additionalFilters="&lead_tag=false"
                        resourcetype={order.resourcetype}
                        endpointLabels={API.ORDERS + "labels/"}
                        label_key={"labels"}
                    />
                },
                {  width: 2, content: 
                    <>
                        { order?.order_manager?.id ?
                            <Link target="_blank" to={routes.EMPLYOEES_DETAIL + order?.order_manager?.id}>
                                <span>{ order?.order_manager?.fullname }</span>
                            </Link>
                            : "--"
                        }
                    </>
                },
                { width: 2, content: 
                    <AvatarList 
                        size="25"
                        alignDirection="start"
                        persons={
                            order.assigned_to.map(item => ({
                                id: item.id,
                                name: item.fullname,
                                avatar: item.profile_picture,
                                displayName: item?.fullname_with_titles || item.fullname,
                            }))
                        }
                    />
                },
                { width: 2, content: <OrderState order={order} setData={setData}/> },
                { content: 
                    <Label basic>
                        <Popup
                            style={{ zIndex: "1000", minWidth: "450px" }}
                            open={attachmentsOpen === order.id}
                            onOpen={() => setAttachmentsOpen(order.id)}
                            hoverable
                            position='left center'
                            trigger={
                                <div style={{ cursor: "pointer" }}>
                                    <Icon name="reader-outline" style={{ marginRight: "0.5rem" }}/> 
                                    <span style={{ position: "relative", top: "-0.1rem" }}>{ order.attachments.length }</span>
                                </div>
                            }
                            content={
                                <Attachments 
                                    viewOnly={!canManage}
                                    record={order}
                                    allowFolders
                                    moduleName={"SaleOrder"}
                                    setData={setData}
                                    sourceEndpoint={API.ORDERS}
                                    closeView={() => setAttachmentsOpen(0)}
                                    additionalData={{ resourcetype: "SaleOrder" }}
                                />
                            }
                        />
                    </Label>
                },
            ])}
        />
    );
};

export default SaleOrder;