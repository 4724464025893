import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { toast } from 'react-semantic-toasts';
import moment from 'moment';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import { Grid, Header, Divider, Table, Checkbox, Form, Button } from 'semantic-ui-react';

const SaleOrderFulfillment = ({ order, setData, customDataHandler, onClose }) => {
    const { t } = useTranslation()

    const [processing, setProcessing] = useState(false)
    const [selectedItems, setSelectedItems] = useState([])

    const orderedItems = order.items
    const fulfillments = order.fulfillments

    const getItemTitle = (orderItem) => {
        let title = ""
        let orderedItem = orderedItems.find(item => item.id === orderItem?.id)
        
        if (orderedItem) {
            title = `${orderedItem.item.code && orderedItem.item.code + " - "}${orderedItem.item.title}`
        }

        return title
    }

    const getQuantities = (orderItem) => {
        const orderItemFulfillments = fulfillments.filter(item => item.order_item === orderItem.id)
        const orderedQuantity = orderItem.quantity
        let totalFulfilled = 0
        for (let i = 0; i < orderItemFulfillments.length; i++) {
            totalFulfilled += orderItemFulfillments[i]?.quantity || 1
        } 

        return {
            remaining: orderedQuantity - totalFulfilled,
            fulfilled: totalFulfilled,
            required: orderedQuantity
        }
    }

    const isValid = (orderItem, value) => {
        const remaining = parseInt(getQuantities(orderItem).remaining)

        if (String(value).includes(".")) return false
        if (String(value).includes(",")) return false

        value = parseInt(value)

        if (isNaN(value) || value === "") return false
        if (value <= 0 ) return false
        if (value > remaining ) return false

        return true
    }

    const isFormValid = () => {
        let checkValid = 0
        for (let i = 0; i < selectedItems.length; i++) {
            if (
                isValid(selectedItems[i].order_item, selectedItems[i].quantity) && 
                selectedItems[i].delivery_date !== "" &&
                moment(selectedItems[i].delivery_date, "YYYY-MM-DD", true).isValid()
            ) {
                checkValid = checkValid + 1
            }
        }

        if (checkValid === selectedItems.length) return true
        return false
    }

    const handleSubmit = async () => {
        setProcessing(true)

        let totalProcessed = 0
        for (let i = 0; i < selectedItems.length; i++) {
            let total_fee = parseFloat(selectedItems[i].order_item.price_without_tax * selectedItems[i].quantity).toFixed(2)
            const request = await requests.post(API.ORDERS + "fulfillments/", {
                order: order.id,
                resourcetype: "SaleFulfillment", 
                total_fee: total_fee,
                order_item: selectedItems[i].order_item.id,
                currency: selectedItems[i].currency,
                quantity: selectedItems[i].quantity,
                delivery_date: selectedItems[i].delivery_date
            })

            if (request.status === 201) {
                totalProcessed = totalProcessed + 1
            }
        }

        if ( totalProcessed === selectedItems.length) {
            // fetch order
            const getOrderRequest = await requests.get(API.ORDERS + order.id + "/")
            if (getOrderRequest.status === 200) {
                if ( customDataHandler ) {
                    customDataHandler(getOrderRequest.response)
                } else {
                    setData(prev => prev.map(item => {
                        if (item.id === order.id) {
                            item = getOrderRequest.response
                        }
                        return item
                    }))
                }

                toast({
                    type: 'success',
                    icon: 'check circle',
                    title: t('fulfillment_created_successfully'),
                    animation: 'pulse',
                    time: 2000,
                })
                onClose()
            }
        }

        setProcessing(false)
    }

    const sortedItems = orderedItems.slice().sort((a, b) => {
        const remainingQuantityA = getQuantities(a).remaining
        const remainingQuantityB = getQuantities(b).remaining

        if (remainingQuantityA === 0 && remainingQuantityB === 0) {
            // If both items have remaining quantity of 0, maintain their original order
            return orderedItems.indexOf(a) - orderedItems.indexOf(b);
          } else if (remainingQuantityA === 0) {
            // Move items with remaining quantity of 0 to the bottom
            return 1;
          } else if (remainingQuantityB === 0) {
            // Keep items with remaining quantity greater than 0 at the top
            return -1;
          }
    
        return remainingQuantityA - remainingQuantityB
    });

    return (
        <>
            <Grid style={{ padding: 0, margin: 0 }}>
                <Grid.Row columns={2} style={{ padding: 0, margin: 0 }}>
                    <Grid.Column textAlign='left' style={{ paddingLeft: 0 }}>
                        <Header as="h4" content={t("select_items")}/>
                    </Grid.Column>
                    <Grid.Column textAlign='right' style={{ paddingRight: 0 }}>
                        {t("number_of_selected") + ": " } <strong>{selectedItems.length}</strong>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider />

            <Form onSubmit={(e) => e.preventDefault()}>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>#</Table.HeaderCell>
                            <Table.HeaderCell>{t('item')}</Table.HeaderCell>
                            <Table.HeaderCell>{t('quantity')}</Table.HeaderCell>
                            <Table.HeaderCell style={{ width: "160px" }}>{t('delivery_date')}</Table.HeaderCell>
                            <Table.HeaderCell>{t('state')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        { sortedItems?.map(item => (
                            <Table.Row key={item.id} verticalAlign="top" style={{ opacity: getQuantities(item).remaining > 0 ? 1 : 0.5 }}>
                                <Table.Cell>
                                    { getQuantities(item).remaining > 0 && 
                                        <Checkbox
                                            disabled={processing}
                                            checked={ selectedItems.find(selected => selected.order_item.id === item.id) !== undefined }
                                            onChange={() => {
                                                if (selectedItems.find(selected => selected.order_item.id === item.id) !== undefined) { // remove
                                                    setSelectedItems(prev => prev.filter(selected => selected.order_item.id !== item.id))
                                                } else { // add
                                                    setSelectedItems(prev => [...prev, {
                                                        order_item: item,
                                                        quantity: getQuantities(item)?.remaining, // add what left to fulfill on select
                                                        delivery_date: moment().format("YYYY-MM-DD"), // add current date on select
                                                        currency: item.currency
                                                    }])
                                                }
                                            }}
                                        />
                                    }
                                </Table.Cell>
                                <Table.Cell><strong>{ getItemTitle(item) }</strong></Table.Cell>
                                <Table.Cell>
                                    <Form.Group style={{ marginBottom: 0 }}>
                                        <Form.Field>
                                            <SuperField
                                                min="0"
                                                as="input"
                                                size="mini"
                                                type="number"
                                                style={{ width: "100px" }}
                                                error={isValid(item, selectedItems.find(selected => selected.order_item.id === item.id)?.quantity || 0) ? false : true}
                                                disabled={selectedItems.find(selected => selected.order_item.id === item.id) ===  undefined}
                                                value={ selectedItems.find(selected => selected.order_item.id === item.id)?.quantity || "" }
                                                onChange={(e, { value }) => setSelectedItems(prev => prev.filter(selected => {
                                                    if (selected.order_item.id === item.id) {
                                                        selected.quantity = value
                                                    }
                                                    
                                                    return selected
                                                }))}
                                            />
                                            <small style={{ opacity: "0.8" }}>
                                                { getQuantities(item).remaining === 0 
                                                    ? <span style={{ color: "green" }}> { t('already_fulfilled') } </span>
                                                    : <> Max. { getQuantities(item)?.remaining || 0 } </>
                                                }
                                                
                                            </small>
                                        </Form.Field>
                                    </Form.Group>
                                </Table.Cell>
                                <Table.Cell style={{ width: "160px" }}>
                                    <Form.Group style={{ marginBottom: 0 }}>
                                        <SuperField 
                                            size="mini"
                                            as="datepicker"
                                            style={{ width: "160px" }}
                                            disabled={selectedItems.find(selected => selected.order_item.id === item.id) ===  undefined}
                                            value={ selectedItems.find(selected => selected.order_item.id === item.id)?.delivery_date || "" }
                                            onChange={(e, { value }) => setSelectedItems(prev => prev.filter(selected => {
                                                if (selected.order_item.id === item.id) {
                                                    selected.delivery_date = value
                                                }
                                                
                                                return selected
                                            }))}
                                        />
                                    </Form.Group>
                                </Table.Cell>
                                
                                <Table.Cell>
                                    { getQuantities(item)?.fulfilled || 0 } / { getQuantities(item)?.required || 0 } ( { getQuantities(item).fulfilled > 0 ? `${parseFloat((getQuantities(item).fulfilled/getQuantities(item).required) * 100).toFixed(2)}%` : "0%" })
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </Form>

            <Divider/>
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose}/>
                <Button
                    primary
                    type="button"
                    loading={processing}
                    content={t('confirm')}
                    onClick={() => handleSubmit()}
                    disabled={!isFormValid() || processing || selectedItems.length === 0}
                />
            </Form.Field>
        </>
    );
};

export default SaleOrderFulfillment;