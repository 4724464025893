import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
// store
import { routes } from '@routes';
import { API } from '@store/config';
import { createRequest } from '@services/ServiceCommon';
//components
import Icon from '@components/Icon';
import { Header, Grid, Button, Divider } from 'semantic-ui-react';
import PreviewCard from '@components/cards/PreviewCard';
import CanView from '@components/perms/CanView';
import Action from '@components/general/Action';

const PositionPreview = ({ record }) => {

    const data = record

    const { t } = useTranslation();
    const history = useHistory()
    const [contractHide, setContractHide] = useState(false)
    const [salaryHide, setSalaryHide] = useState(false)

    return (
        <div>
            <Grid>
                <Grid.Row>
                    <Grid.Column mobile={10} tablet={10} computer={10}>
                        <Grid.Row>
                            <Header size="medium" content={data.title} />
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column mobile={6} tablet={6} computer={6}>
                        <CanView permissions={["jobs.c_manage_job_positions"]}>
                            <Action as="confirm" type="icon" icon="copy-outline" tooltip={t('duplicate')} size="huge" floated="right" iconColor="var(--info)"
                                onClick={async () => {
                                    const request = await createRequest(API.JOB_POSITIONS + data.id + "/duplicate/")

                                    if (request.status === 201) {
                                        history.push(routes.UPDATE_JOB_POSITION + request.response.id + "/")
                                    }
                                }}
                                text={t('duplicate_position_confirm')}
                            />
                        </CanView>
                        <Action as='link' type='icon' icon="pencil-outline" to={routes.UPDATE_JOB_POSITION + data.id} iconColor="var(--black)" floated="right" size="huge" />
                    </Grid.Column>
                </Grid.Row>
                <Divider />
                <Grid.Row style={{ paddingBottom: 0 }}>
                    <Grid.Column>
                        <Header size="medium" content={t('description')} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row >
                    <Grid.Column>
                        {data.description ?
                            <div style={{ whiteSpace: "pre-line" }}> {data.description} </div>
                            : t('no_data_available')
                        }
                    </Grid.Column>
                </Grid.Row>
                {data.work_factors &&
                    <Grid.Row >
                        <Grid.Column>
                            <p><strong>{t('work_factors')}:</strong>{" "} {data.work_factors}</p>
                        </Grid.Column>
                    </Grid.Row>
                }

                {data.work_activity &&
                    <Grid.Row >
                        <Grid.Column>
                            <p><strong>{t('work_activity')}:</strong>{" "} {data.work_activity}</p>
                        </Grid.Column>
                    </Grid.Row>
                }
                <Divider/>
                <Grid.Row style={{paddingBottom:0}}>
                    <Grid.Column mobile={14} tablet={14} computer={14}>
                        <Header size="medium" content={t('contract_information')} />
                    </Grid.Column>
                    <Grid.Column mobile={2} tablet={2} computer={2}>
                        {contractHide ?
                            <Button floated="right" size="medium" onClick={() => setContractHide(false)} icon={<Icon name="chevron-down-outline" />} style={{ paddingTop: 0, background: "none", marginBottom: "0.5rem" }} />
                            :
                            <Button floated="right" size="medium" onClick={() => setContractHide(true)} icon={<Icon name="chevron-up-outline" />} style={{ paddingTop: 0, background: "none", marginBottom: "0.5rem" }} />
                        }
                    </Grid.Column>
                </Grid.Row>
                {!contractHide && data?.job_contract &&
                    <>
                        <Grid.Row style={{ paddingTop: 0 }}>
                            <Grid.Column mobile={8} tablet={8} computer={8} style={{ paddingRight: "0.4rem" }}>
                                {data?.job_contract?.contract_type_display &&
                                    <PreviewCard
                                        name={t('contract_type')}
                                        data={data?.job_contract?.contract_type_display}
                                    />
                                }
                                {data?.job_contract?.work_schedule_display &&
                                    <PreviewCard
                                        name={t('work_schedule_')}
                                        data={data?.job_contract?.work_schedule_display}
                                    />
                                }
                                {data?.job_contract?.working_hours_per_day &&
                                    <PreviewCard
                                        name={t('hours_per_day_')}
                                        data={data?.job_contract?.working_hours_per_day}
                                    />
                                }
                            </Grid.Column>
                            <Grid.Column mobile={8} tablet={8} computer={8} style={{ paddingLeft: "0.4rem" }}>
                                {data?.job_contract?.work_shift_display &&
                                    <PreviewCard
                                        name={t('work_shifts_')}
                                        data={data?.job_contract?.work_shift_display}
                                    />
                                }
                            </Grid.Column>
                        </Grid.Row>
                    </>
                }
                {!contractHide && !data?.job_contract &&
                    <Grid.Row >
                        <Grid.Column>
                            {t('no_data_available')}
                        </Grid.Column>
                    </Grid.Row>
                }
                <Divider />
                <Grid.Row style={{ paddingBottom: 0 }}>
                    <Grid.Column mobile={14} tablet={14} computer={14}>
                        <Header size="medium" content={t('salary_information')} />
                    </Grid.Column>
                    <Grid.Column mobile={2} tablet={2} computer={2}>
                        {salaryHide ?
                            <Button floated="right" size="medium" onClick={() => setSalaryHide(false)} icon={<Icon name="chevron-down-outline" />} style={{ paddingTop: 0, background: "none", marginBottom: "0.5rem" }} />
                            :
                            <Button floated="right" size="medium" onClick={() => setSalaryHide(true)} icon={<Icon name="chevron-up-outline" />} style={{ paddingTop: 0, background: "none", marginBottom: "0.5rem" }} />
                        }
                    </Grid.Column>
                </Grid.Row>
                {!salaryHide && data?.job_contract?.salary && <>
                    <Grid.Row style={{ paddingTop: 0 }}>
                        <Grid.Column mobile={8} tablet={8} computer={8} style={{ paddingRight: "0.4rem" }}>
                            {data?.job_contract?.salary?.salary_type_display &&
                                <PreviewCard
                                    name={t('salary_type')}
                                    data={data?.job_contract?.salary?.salary_type_display}
                                />
                            }
                            {data?.job_contract?.salary?.min &&
                                <PreviewCard
                                    name={t('min')}
                                    data={data?.job_contract?.salary?.min}
                                />
                            }
                            {data?.job_contract?.salary?.max &&
                                <PreviewCard
                                    name={t('max')}
                                    data={data?.job_contract?.salary?.max}
                                />
                            }
                        </Grid.Column>
                        <Grid.Column mobile={8} tablet={8} computer={8} style={{ paddingLeft: "0.4rem" }}>
                            {data?.job_contract?.salary?.currency &&
                                <PreviewCard
                                    name={t('currency')}
                                    data={data?.job_contract?.salary?.currency}
                                />
                            }
                        </Grid.Column>
                    </Grid.Row>
                </>}
                {!salaryHide && !data?.job_contract?.salary &&
                    <Grid.Row >
                        <Grid.Column>
                            {t('no_data_available')}
                        </Grid.Column>
                    </Grid.Row>
                }
            </Grid>
        </div>
    );
};

export default PositionPreview;