import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { useSelectOptions } from '@helpers/hooks';
import { setDotSeparator } from '@helpers/functions';
// components
import SuperField from '@components/forms/SuperField';
import Address from '@components/forms/Address';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import { Form, Divider, Header } from 'semantic-ui-react';
import NonFieldErrors from '@components/NonFieldErrors';

const ContractForm = ({ onClose, record, setData, setTotal, employees, employers, positions }) => {
    const { t } = useTranslation();
    const [isProcessing, setIsProcessing] = useState(false)
    const [contractTypes, setContractTypes] = useSelectOptions(API.CONTRACTS_TYPES + "?query={id, title}", "title")

    // eslint-disable-next-line
    const [errors, setErrors] = useState(null)
    const [form, setForm] = useState({
        status: record?.status || 4,
        type: record?.type?.id || "",
        employee: record?.employee?.id || "",
        employer: record?.employer?.id || "",
        work_position: record?.work_position?.id || "",
        work_level: record?.work_level?.toString() || "",
        valid_from: record?.valid_from || "",
        valid_until: record?.valid_until || "",
        fond: record?.fond || "",
        fond_per_unit: record?.fond_per_unit || "1",
        salary: record?.salary || 0,
        currency: record?.currency || "EUR",
        per_unit: record?.per_unit?.toString() || "",
        trial_period: record?.trial_period || "",
        expected_end_date: record?.expected_end_date || "",

        // additional
        shift: record?.shift?.id || "",
        strict_work_start: record?.strict_work_start || false,
        strict_work_end: record?.strict_work_end || false,
    })

    const [workplace, setWorkplace] = useState({
        street: record?.workplace_address?.street || "",
        city: record?.workplace_address?.city || "",
        postcode: record?.workplace_address?.postcode || "",
        country: record?.workplace_address?.country || "SK",
        address_line: record?.workplace_address?.address_line || "",
        state: record?.workplace_address?.state || "",
        number: record?.workplace_address?.number || "",
        orientation_number: record?.workplace_address?.orientation_number || ""
    })

    const isWorkplaceFilled = () => {
        if (
            workplace.street === "" || 
            workplace.city === "" || 
            workplace.postcode === "" || 
            workplace.country === ""
        ) {
            return false
        }
        return true
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setErrors([])
        setIsProcessing(true)
        const data = {
            valid_from: form.valid_from,
            valid_until: form.valid_until === "" ? null : form.valid_until,
            fond: form.fond,
            fond_per_unit: form.fond_per_unit,
            work_level: form.work_level,
            trial_period: form.trial_period === "" ? null : form.trial_period,
            employer: form.employer,
            type: form.type,
            currency: form.currency,
            salary: form.salary,
            per_unit: form.per_unit,
            work_position: form.work_position,
            employee: form.employee,
            expected_end_date: form.expected_end_date === "" ? null : form.expected_end_date,
            shift: form.shift === "" ? null : form.shift,
            strict_work_start: form.strict_work_start,
            strict_work_end: form.strict_work_end,
            workplace_address: isWorkplaceFilled() ? workplace : null
        }

        if (record === undefined) {
            const request = await requests.post(API.CONTRACTS, data)
            if (request.status === 400) setErrors(request.response)
            if (request.status === 201) {
                setData(prev => [request.response, ...prev])
                setTotal(prev => prev + 1)
                onClose()
            }
        } else {
            const request = await requests.patch(API.CONTRACTS + record.id + "/", data)
            if (request.status === 400) setErrors(request.response)
            if (request.status === 200) {
                setData(prev => prev.map(item => {
                    if (item.id === record.id) {
                        item = request.response
                    }

                    return item
                }))

                onClose()
            }
        }
        setIsProcessing(false)
    }

    const handleAddItem = async (response) => {
        setContractTypes({ ...contractTypes, isLoading: true })
        const request = await requests.post(API.CONTRACTS_TYPES, {
            title: response
        })

        if (request.status === 201) {
            setContractTypes(prev => {
                return {
                    isLoading: false,
                    options: [{
                        key: request.response.id,
                        value: request.response.id,
                        text: request.response.title
                    }, ...prev.options]
                }
            })

            setForm(prev => {
                return {
                    ...prev,
                    contractType: request.response.id
                }
            })
        }
    }

    useEffect(() => {
        if (form.work_position !== ""){
            const position = positions.find(item => item.id === form.work_position)
            if (position?.job_contract) {
                const level = position.job_contract.work_level
                setForm(prev => ({
                    ...prev,
                    work_level: level?.toString() || ""
                }))
            }
        }
        // eslint-disable-next-line
    }, [form.work_position])

    return (
        <Form onSubmit={handleSubmit}>
            <NonFieldErrors errors={errors}/>
            <Form.Group widths="equal">
                <SuperField as="choice"
                    search
                    required
                    label={t('employer')}
                    value={form.employer}
                    customOptions={employers.map(item => ({
                        key: item.id, value: item.id, text: item.name
                    }))}
                    onChange={(e, { value }) => setForm({ ...form, employer: value })}
                    error={errors?.employer?.[0] || false}
                />
                <SuperField as="choice"
                    search
                    required
                    label={t('employee')}
                    value={form.employee}
                    onChange={(e, { value }) => setForm({ ...form, employee: value })}
                    customOptions={employees.map(item => ({
                        key: item.id, value: item.id, text: item.fullname_with_titles
                    }))}
                    error={errors?.employee?.[0] || false}
                />
            </Form.Group>

            <Form.Group widths="equal">
                <SuperField as="choice"
                    allowAdditions
                    search
                    required
                    text="title"
                    size="small"
                    label={t('contract_type')}
                    value={form.type}
                    help={t('add_contract_type_hint')}
                    disabled={contractTypes.isLoading}
                    loading={contractTypes.isLoading}
                    customOptions={contractTypes.options}
                    onAddItem={(e, { value }) => handleAddItem(value)}
                    onChange={(e, { value }) => setForm({ ...form, type: value })}
                    error={errors?.type?.[0] || false}
                />
                <SuperField as="choice"
                    search
                    required
                    size="small"
                    label={t('work_position')}
                    value={form.work_position}
                    customOptions={positions.map(item => ({ key: item.id, value: item.id, text: item.title }))}
                    onChange={(e, { value }) => setForm({ ...form, work_position: value })}
                    error={errors?.work_position?.[0] || false}
                />
            </Form.Group>

            <Form.Group widths="equal">
                <SuperField as="choice"
                    search
                    required
                    label={t('work_level')}
                    type="contracts_work_levels"
                    value={form.work_level}
                    onChange={(e, { value }) => setForm({ ...form, work_level: value })}
                    error={errors?.work_level?.[0] || false}
                />
            </Form.Group>

            <Form.Group widths="equal">
                <SuperField as="datepicker"
                    required
                    closable
                    label={t('work_starts')}
                    value={form.valid_from}
                    onChange={(e, { value }) => setForm({ ...form, valid_from: value })}
                    error={errors?.valid_from?.[0] || false}
                />
                <SuperField as="datepicker"
                    closable
                    label={t('work_ends')}
                    help={t('work_ends_hint')}
                    value={form.valid_until}
                    onChange={(e, { value }) => setForm({ ...form, valid_until: value })}
                    error={errors?.valid_until?.[0] || false}
                />
            </Form.Group>

            <Form.Group widths="equal">
                <SuperField as="datepicker"
                    closable
                    label={t('trial_period')}
                    value={form.trial_period}
                    onChange={(e, { value }) => setForm({ ...form, trial_period: value })}
                    error={errors?.trial_period?.[0] || false}
                />
                <SuperField as="datepicker"
                    closable
                    label={t('expected_work_ends')}
                    value={form.expected_end_date}
                    onChange={(e, { value }) => setForm({ ...form, expected_end_date: value })}
                    error={errors?.expected_end_date?.[0] || false}
                />
            </Form.Group>

            <Form.Group widths="equal">
                <SuperField as="input"
                    required
                    label={t('work_fond')}
                    value={form.fond || ""}
                    onChange={(e, { value }) => setForm({ ...form, fond: value.replace(",", ".") })}
                    error={errors?.fond?.[0] || false}
                />
                <SuperField as="choice"
                    type="fond_per_type"
                    label={t('work_fond_type')}
                    value={form.fond_per_unit.toString()}
                    onChange={(e, { value }) => setForm({ ...form, fond_per_unit: value })}
                    error={errors?.fond_per_unit?.[0] || false}
                />
            </Form.Group>

           
            <Header as="h3">{t('salary')}</Header>
            <Divider />
            <Form.Group widths="equal">
                <SuperField as="input"
                    required
                    label={t('sum')}
                    value={form.salary}
                    onChange={(e, { value }) => setForm({ ...form, salary: setDotSeparator(value) })}
                    error={errors?.salary?.[0] || false}
                />
                <SuperField as="choice"
                    search
                    required
                    label={t('currency')}
                    type="currency_codes"
                    value={form.currency}
                    onChange={(e, { value }) => setForm({ ...form, currency: value })}
                    error={errors?.currency?.[0] || false}
                />
                <SuperField as="choice"
                    required
                    type="job_salary_type"
                    label={t('salary_type')}
                    value={form.per_unit}
                    onChange={(e, { value }) => setForm({ ...form, per_unit: value })}
                    error={errors?.per_unit?.[0] || false}
                />
            </Form.Group>

            <Header as="h3" content={t('workplace')} style={{ marginBottom: "0rem" }}/>
            <small><strong>{ t('leave_it_blank_if_employer_address_is_the_same_as_workplace_address') }.</strong></small>
            <Divider />
            <Address 
                isForm 
                address={workplace}
                setAddress={setWorkplace}
            />

            <Header as="h3" content={t('attendance_configuration')} style={{ marginBottom: "0rem" }}/>
            <Divider />

            <SuperField as="choice"
                search
                text="title"
                label={t('work_shift')}
                value={form.shift}
                error={errors?.shift?.[0] || false}
                endpoint={API.ATTENDANCE_BASE + "shifts/"}
                onChange={(e, { value }) => setForm({ ...form, shift: value })}
            />

            <SuperField as="checkbox"
                label={t('strict_work_start_preference')}
                checked={form.strict_work_start}
                error={errors?.strict_work_start?.[0] || false}
                onChange={() => setForm({ ...form, strict_work_start: !form.strict_work_start })}
            />

            <SuperField as="checkbox"
                label={t('strict_work_end_preference')}
                checked={form.strict_work_end}
                error={errors?.strict_work_end?.[0] || false}
                onChange={() => setForm({ ...form, strict_work_end: !form.strict_work_end })}
            />


            <Divider />
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    loading={isProcessing}
                    disabled={
                        isProcessing ||
                        form.employee === "" ||
                        form.contract_type === "" ||
                        form.work_position === "" ||
                        form.fond === "" ||
                        form.valid_from === "" ||
                        form.work_level === "" ||
                        form.per_unit === "" ||
                        form.currency === "" ||
                        form.salary === ""
                    }
                    text={t('save')} />
            </Form.Field>
        </Form>
    )
};

export default ContractForm;