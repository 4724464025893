import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import '@store/languages';
// store
import { API } from '@store/config';
import { tzDateTime, getTimezoneOffset } from '@helpers/dates';
import { requests, fileDownload } from '@helpers/requests';
// components
import Icon from '@components/Icon';
import CanView from '@components/perms/CanView';
import SuperField from '@components/forms/SuperField';
import FlexRowWithToggle from '@components/FlexRowWithToggle';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import { Divider, Form, Button, Segment, Popup } from 'semantic-ui-react';
import { FlexRow, FlexItem } from '@components/tables/FlexTable';
// specific components
import WeekManagement from '../components/management/WeekManagement';
import ShiftManagement from '../components/ShiftManagement';

const ManageEmployee = () => {
    const { t } = useTranslation()
    const date_format = useSelector(state => state.date_format)
    
    const [month, setMonth] = useState(moment().format("MM"))
    const [year, setYear] = useState(moment().format("YYYY"))
    const [employee, setEmployee] = useState("")
    const [loading, setLoading] = useState(false)
    const [isGenerated, setIsGenerated] = useState(false)
    const [excludeWeekend, setExcludeWeekend] = useState(false)
    const [loadingShifts, setLoadingShifts] = useState(false)
    
    const [shifts, setShifts] = useState([])
    const [weeks, setWeeks] = useState([])
    const [records, setRecords] = useState([])
    const [weeksStats, setWeeksStats] = useState([])

    const fetchShifts = async () => {
        setLoadingShifts(true)
        const request = await requests.get(API.ATTENDANCE_BASE + "shifts/")
        
        if (request.status === 200) {
            setShifts(request.response.map(item => ({ ...handleShiftConvertion(item), is_processing: false, errors: null })))
        }
        setLoadingShifts(false)
    }

    useEffect( () => {
        fetchShifts()
        // eslint-disable-next-line
    }, [])

    const handleShiftConvertion = (shift) => {
        if (!["", null].includes(shift.time_from)) {
            let datetime_from = moment(moment().format("YYYY-MM-DD") + " " + shift.time_from).format("YYYY-MM-DD HH:mm")
            // convert to Localtime:
            shift.time_from = tzDateTime(datetime_from).format("HH:mm")
        }

        if (!["", null].includes(shift.time_to)) {
            let datetime_to = moment(moment().format("YYYY-MM-DD") + " " + shift.time_to).format("YYYY-MM-DD HH:mm")
            // convert to Localtime:
            shift.time_to = tzDateTime(datetime_to).format("HH:mm")
        }

        if (!["", null].includes(shift.pause_length)) {
            if (parseFloat(shift.pause_length) > 0) {
                shift.pause_length = parseFloat(shift.pause_length)
            }
        }
        return shift
    } 

    const monthList = [
        { key: 1, value: "01", text: t('january') },
        { key: 2, value: "02", text: t('february') },
        { key: 3, value: "03", text: t('march') },
        { key: 4, value: "04", text: t('april') },
        { key: 5, value: "05", text: t('may') },
        { key: 6, value: "06", text: t('june') },
        { key: 7, value: "07", text: t('july') },
        { key: 8, value: "08", text: t('august') },
        { key: 9, value: "09", text: t('september') },
        { key: 10, value: "10", text: t('october') },
        { key: 11, value: "11", text: t('november') },
        { key: 12, value: "12", text: t('december') },
    ]

    const getDates = (from, to) => {
        let dates = []
        // add logic to calculate days between two date range
        for (let day = from; day.isSameOrBefore(to); day.add(1, 'days')) {
            dates.push(day.format('YYYY-MM-DD'));
        }

        return dates
    }

    const generateXLSXReport = async (id) => {
        const firstDay = moment(year + "-" + month + "-01").startOf("month").format("YYYY-MM-DD")
        const lastDay = moment(year + "-" + month + "-01").endOf("month").format("YYYY-MM-DD")
        await fileDownload("GET", API.API_URL + `/exports/dynamic_attendance/?is_blob=true&employee=${employee}&date_from=${firstDay}&date_to=${lastDay}&timezone=${getTimezoneOffset()}`,"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", `attendance_monthly_report_${firstDay}__${lastDay}.xlsx`)
    }

    const generatePDFReport = async (id) => {
        const firstDay = moment(year + "-" + month + "-01").startOf("month").format("YYYY-MM-DD")
        const lastDay = moment(year + "-" + month + "-01").endOf("month").format("YYYY-MM-DD")
        await fileDownload("GET", API.API_URL + `/exports/attendance_pdf_download/${employee}/?is_blob=true&start_date=${firstDay}&end_date=${lastDay}&timezone=${getTimezoneOffset()}`,"application/pdf", `attendance_monthly_report_${firstDay}__${lastDay}.pdf`)
    }

    const generateMonthlyAttendance = async () => {
        setLoading(true)
        const firstDay = moment(year + "-" + month + "-01").startOf("month")
        const lastDay = moment(year + "-" + month + "-01").endOf("month")
        const dates = getDates(firstDay, lastDay)
        const duplicate_weeks = dates.map(date => moment(date).isoWeek())
        const unique_weeks = [...new Set(duplicate_weeks)]
        setWeeks(unique_weeks)
        const attendance = []

        

        for (let i = 0; i < dates.length; i++) {
            attendance.push({
                week: moment(dates[i]).isoWeek(),
                day: dates[i]
            })
        }
        
        const attendance_week_stats = []
        for (let i = 0; i < unique_weeks.length; i++) {
            // calculate week start and end date
            const weekDates = attendance.filter(item => item.week === unique_weeks[i])
            const first_day = weekDates[0]?.day
            const last_day = weekDates[weekDates.length - 1]?.day
            const request_week_stats = await requests.get(API.ATTENDANCE + `employee_stats/?date_from=${first_day}&date_to=${last_day}&employee=${employee}`)
            
            if (request_week_stats.status === 200) {
                // set response from the API call
                attendance_week_stats.push({
                    week: unique_weeks[i],
                    ...request_week_stats.response
                })
            } else {
                // set default on API call error
                attendance_week_stats.push({
                    week: unique_weeks[i],
                    working_hours: 0,
                    lunch_voucher: 0,
                    vacation: 0,
                    absention: 0,
                    paragraph: 0,
                    sick_day: 0
                })
            }
        }

        setWeeksStats(attendance_week_stats)
        setRecords(attendance)
        setTimeout(() => {
            setIsGenerated(true)
            setLoading(false)
        }, 500);
    }

    const getWeekDays = (week) => {
        return records.filter(record => record.week === week).filter(item => !isWeekendDay(item.day))
    }

    const isWeekendDay = (day) => {
        if (excludeWeekend === false) return false

        return [0,6].includes(moment(day).day())
    }

    const countStat = (attribute) => {
        let total = 0
        for (let i = 0; i < weeksStats.length ; i++) {
            const value = weeksStats[i][attribute]
            total += (isNaN(value) || value === undefined || value === null || value === "") ? 0 : parseFloat(value)
        }

        return attribute === "working_hours" ? parseFloat(total).toFixed(2) : total
    }

    const countWeekStat = (attribute, week) => {
        let total = 0
        const current_week_stats = weeksStats.find(item => item.week === week) 
        if (current_week_stats !== undefined){
            const value = current_week_stats?.[attribute] || 0
            total = (isNaN(value) || value === undefined || value === null || value === "") ? 0 : parseFloat(value)
            return attribute === "working_hours" ? parseFloat(total).toFixed(2) : total
        }

        return total
    }

    return (
        <>
            <Form>
                <Form.Group>
                    <SuperField as="choice"
                        search
                        width="3"
                        text="fullname_with_titles"
                        value={employee}
                        endpoint={API.EMPLOYEES + "?only_basic_info=true"}
                        onChange={(e, { value }) => {
                            if (value === "") setIsGenerated(false)
                            setEmployee(value)
                        }}
                    />
                    <SuperField as="choice"
                        search
                        width="2"
                        value={month}
                        customOptions={monthList}
                        onChange={(e, { value }) => setMonth(value) }
                    />
                    <SuperField as="yearpicker"
                        value={year}
                        width="2"
                        dateFormat="YYYY"
                        onChange={(e, { value }) => setYear(value)}
                    />
                    <SuperField as="checkbox"
                        style={{ marginTop: "0.8rem" }}
                        label={t('exclude_weekend')}
                        checked={excludeWeekend}
                        onChange={() => setExcludeWeekend(!excludeWeekend)}
                    />
                    <Form.Field>
                        <Button 
                            primary
                            type="button"
                            onClick={() => generateMonthlyAttendance()}
                            disabled={employee === "" || month === "" || year === ""}
                        >
                            { t('confirm') }
                        </Button>
                    </Form.Field>
                    <CanView permissions={['attendance.c_manage_shifts']}>
                        <Form.Field>
                            <SuperDuperModal
                                size="large"
                                header={t('manage_shifts')}
                                trigger={
                                    <Button 
                                        disabled={loadingShifts}
                                        type="button"
                                        style={{ background: "var(--dark)", color: "var(--white)" }}
                                    >
                                        { t('manage_shifts') }
                                    </Button>
                                }
                                content={
                                    <ShiftManagement shifts={shifts} setShifts={setShifts}/>
                                }
                            />
                        </Form.Field>
                    </CanView>
                </Form.Group>
            </Form>
            <Divider/>
            <Segment 
                loading={loading}
                style={{ 
                    padding: 0, 
                    background: "transparent", 
                    boxShadow: "none", 
                    border: "none",
                    marginBottom: "1rem",
                    marginTop: "1rem",
                }}
            >
                { loading && 
                    <p style={{ textAlign: "center", color: "var(--dark)", paddingTop: "6rem" }}> 
                    { t('loading_monthly_attendance') } 
                    </p>
                }

                { (!isGenerated && !loading) && 
                    <p style={{ textAlign: "center", color: "var(--dark)", paddingTop: "1rem", fontWeight: "bold" }}> 
                        { t('select_employee_to_generate_monthly_attendance') } 
                    </p>
                }

                { (!loading && isGenerated) && 
                    <Form>
                        <FlexRow>
                            <FlexItem fontSize="0.6rem">
                                <small>
                                    <strong> { monthList.find(item => item.value === month)?.text || "" } { year } </strong> <br/>
                                    <Icon name="calendar-outline" style={{ marginRight: "0.5rem" }}/>
                                    <span style={{ position: "relative", top: "-0.1rem" }}>
                                        { moment(moment(year + "-" + month + "-01").startOf("month")).format(date_format) }
                                    </span>
                                    <Icon name="calendar-outline" style={{ marginLeft: "1rem", marginRight: "0.5rem" }}/> 
                                    <span style={{ position: "relative", top: "-0.1rem" }}>
                                        { moment(moment(year + "-" + month + "-01").endOf("month")).format(date_format) }
                                    </span>
                                </small>
                            </FlexItem>
                            <FlexItem basis="30%" textAlign="center">
                                <small>
                                    <strong>
                                    { t('total_days') }
                                    </strong> <br/>
                                    <span> { records.filter(item => !isWeekendDay(item.day)).length } </span>
                                </small>
                            </FlexItem>
                            <FlexItem basis="30%" textAlign="center">
                                <small>
                                    <strong>
                                        { t('working_hours') }
                                    </strong> <br/>
                                    <span> { countStat('working_hours') } </span>
                                </small>
                            </FlexItem>
                            <FlexItem basis="30%" textAlign="center">
                                <small>
                                    <strong>
                                        { t('lunch_voucher') }
                                    </strong> <br/>
                                    <span> { countStat('lunch_voucher') } </span>
                                </small>
                            </FlexItem>
                            <FlexItem basis="30%" textAlign="center">
                                <small>
                                    <strong>
                                        { t('vacation') }
                                    </strong> <br/>
                                    <span> { countStat('vacation') } </span>
                                </small>
                            </FlexItem>
                            <FlexItem basis="30%" textAlign="center">
                                <small>
                                    <strong>
                                        { t('absention') }
                                    </strong> <br/>
                                    <span> { countStat('absention') } </span>
                                </small>
                            </FlexItem>
                            <FlexItem basis="30%" textAlign="center">
                                <small>
                                    <strong>
                                        { t('paragraph') }
                                    </strong> <br/>
                                    <span> { countStat('paragraph') } </span>
                                </small>
                            </FlexItem>
                            <FlexItem basis="30%" textAlign="center">
                                <small>
                                    <strong>
                                        { t('sick_day') }
                                    </strong> <br/>
                                    <span> { countStat('sick_day') } </span>
                                </small>
                            </FlexItem>
                            <FlexItem basis="20%" textAlign="right">
                                <CanView permissions={['attendance.c_view_all_records', 'company.c_manage_only_assigned_unit_employees']}>
                                    <Popup
                                        position="left center"
                                        content={t('download_monthly_attendance_as_xlsx')}
                                        trigger={
                                            <Icon name="download-outline" style={{ cursor: "pointer" }} onClick={() => generateXLSXReport()}/>
                                        }
                                    />
                                </CanView>
                                <CanView permissions={['attendance.c_view_all_records', 'company.c_manage_only_assigned_unit_employees']}>
                                    <Popup
                                        position="left center"
                                        content={t('download_monthly_attendance_as_pdf')}
                                        trigger={
                                            <Icon name="document-text-outline" style={{ cursor: "pointer", marginLeft: "1rem", color: "var(--danger)" }} onClick={() => generatePDFReport()}/>
                                        }
                                    />
                                </CanView>
                            </FlexItem>
                        </FlexRow>
                        <Divider/>
                        { weeks.map((week, idx) => (
                            <FlexRowWithToggle
                                background={"var(--light)"}
                                key={idx}
                                loading={false}
                                isOpen={moment().isoWeek() === week}
                                rowContent={
                                    <>
                                        <FlexItem fontSize="0.6rem">
                                            <small>
                                                <strong>{ t('week') } { week }: </strong>
                                                <Icon name="calendar-outline" style={{ marginLeft: "1rem", marginRight: "0.5rem" }}/> 
                                                <span style={{ position: "relative", top: "-0.1rem" }}>
                                                    { moment(getWeekDays(week)?.[0]?.day).format(date_format) }
                                                </span>
                                                <Icon name="calendar-outline" style={{ marginLeft: "1rem", marginRight: "0.5rem" }}/>
                                                <span style={{ position: "relative", top: "-0.1rem" }}>
                                                    { moment(getWeekDays(week)?.[getWeekDays(week).length - 1]?.day).format(date_format) }
                                                </span>
                                            </small>
                                        </FlexItem>
                                        <FlexItem basis="30%" textAlign="center">
                                            <small>
                                                <strong>
                                                { t('total_days') }
                                                </strong> <br/>
                                                <span> { records.filter(record => record.week === week).filter(item => !isWeekendDay(item.day)).length } </span>
                                            </small>
                                        </FlexItem>
                                        <FlexItem basis="30%" textAlign="center">
                                            <small>
                                                <strong>
                                                    { t('working_hours') }
                                                </strong> <br/>
                                                <span> { countWeekStat('working_hours', week) } </span>
                                            </small>
                                        </FlexItem>
                                        <FlexItem basis="30%" textAlign="center">
                                            <small>
                                                <strong>
                                                    { t('lunch_voucher') }
                                                </strong> <br/>
                                                <span> { countWeekStat('lunch_voucher', week) } </span>
                                            </small>
                                        </FlexItem>
                                        <FlexItem basis="30%" textAlign="center">
                                            <small>
                                                <strong>
                                                    { t('vacation') }
                                                </strong> <br/>
                                                <span> { countWeekStat('vacation', week) } </span>
                                            </small>
                                        </FlexItem>
                                        <FlexItem basis="30%" textAlign="center">
                                            <small>
                                                <strong>
                                                    { t('absention') }
                                                </strong> <br/>
                                                <span> { countWeekStat('absention', week) } </span>
                                            </small>
                                        </FlexItem>
                                        <FlexItem basis="30%" textAlign="center">
                                            <small>
                                                <strong>
                                                    { t('paragraph') }
                                                </strong> <br/>
                                                <span> { countWeekStat('paragraph', week) } </span>
                                            </small>
                                        </FlexItem>
                                        <FlexItem basis="30%" textAlign="center">
                                            <small>
                                                <strong>
                                                    { t('sick_day') }
                                                </strong> <br/>
                                                <span> { countWeekStat('sick_day', week) } </span>
                                            </small>
                                        </FlexItem>
                                    </>
                                }
                                content={
                                    <WeekManagement
                                        key={idx}
                                        week={week}
                                        weekDates={records.filter(item => item.week === week)}
                                        employee={employee}
                                        excludeWeekend={excludeWeekend}
                                        setWeeksStats={setWeeksStats}
                                        loadingShifts={loadingShifts}
                                        shifts={shifts}
                                    />
                                }
                            />
                        )) }
                    </Form>
                }
            </Segment>
        </>
    );
};

export default ManageEmployee;