import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { toast } from 'react-semantic-toasts';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
// store
import { API } from '@store/config';
import { routes } from '@store/routes';
import { icons } from '@store/icons';
// import { tzDateTime } from '@helpers/dates';
import { requests } from '@helpers/requests';
import { useHasPermissions } from '@helpers/hooks';
import { renderShortLongAddress } from '@helpers/functions';
import { thousandsSeparators, removeURLParameter } from '@helpers/functions';
// components
import Icon from '@components/Icon';
import ListView from '@components/ListView';
import CanView from '@components/perms/CanView';
import AvatarList from '@components/AvatarList';
import Attachments from '@components/Attachments';
import LabelsList from '@components/lists/LabelsList';
import BreadcrumbNav from '@components/BreadcrumbNav';
import GenerateDocuments from '@components/GenerateDocuments';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import { Container, Button, Checkbox, Label, Icon as SemanticIcon, Popup } from 'semantic-ui-react';
// component specific
import FilterForm from './components/FilterForm';
// import LogisticForm from './components/LogisticForm';
import DeliveryDateForm from './components/DeliveryDateForm';
import LogisticRecordForm from './components/LogisticRecordForm';
import LogisticRecordDetail from './components/LogisticRecordDetail';

const Logistics = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const dateFormat = useSelector(state => state.date_format)

    const order_status_choices = useSelector(state => state.choices.order_status_choices)
    const statuses = Object.entries(order_status_choices).map((choice, idx) => (
        { key: idx, text: choice[1], value: choice[0] }
    ))

    const canManage = useHasPermissions(['logistics.c_manage_all_transports', 'logistics.c_manage_user_transports'])
    
    const today = moment().format("YYYY-MM-DD")
    const accessPerms = ['logistics.c_view_all_transports', 'logistics.c_view_user_transports']

    const [attachmentsOpen, setAttachmentsOpen] = useState(null)
    const [view, setView] = useState(1)

    const [employees, setEmployees] = useState([])
    const [labels, setLabels] = useState([])
    const [commodities, setCommodities] = useState([])
    const [businessDetails, setBusinessDetails] = useState([])
    const [carriers, setCarriers] = useState([])

    // status modal change
    const [open, setOpen] = useState(null)
    const [action, setAction] = useState(null)

    useEffect(() => {
        async function fetchEmployees(){
            const request = await requests.get(API.EMPLOYEES + "?only_basic_info=true&is_active=true&has_user=true")
            if (request.status === 200) {
                setEmployees(request.response.map(item => ({ key: item.id, value: item.id, text: item.fullname_with_titles })))
            }
        }

        async function fetchCatalogueItems() {
            const request = await requests.get(API.ORDERS + "catalogue_items/?query={id, code, title}")
            if (request.status === 200) {
                setCommodities(request.response)
            }
        }

        async function fetchLabels(){
            const request = await requests.get(API.LOGISTICS + "labels/?query={id, name}")
            if (request.status === 200) {
                setLabels(request.response.map(item => ({ key: item.id, value: item.id, text: item.name, color: item.color })))
            }
        }

        async function fetchBusinessDetails(){
            const requestSuppliers = await requests.get(API.ACCOUNTS + "business_details/?query={id, name, identification_number}&is_supplier=true")
            const requestAccounts = await requests.get(API.ACCOUNTS + "business_details/?query={id, name, identification_number}&is_account=true")
            const requestEmployers = await requests.get(API.BUSINESS_DETAIL + "?query={id, name, identification_number}&is_employer=true")
            if (requestSuppliers.status === 200 && requestAccounts.status === 200 && requestEmployers.status === 200) {
                let suppliers = requestSuppliers.response.map(item => ({ key: item.id, value: item.id, text: `${item.name} - ${item.identification_number}` }))
                let accounts = requestAccounts.response.map(item => ({ key: item.id, value: item.id, text: `${item.name} - ${item.identification_number}` }))
                let employers = requestEmployers.response.map(item => ({ key: item.id, value: item.id, text: `${item.name} - ${item.identification_number}` }))
                setBusinessDetails([...suppliers, ...accounts/*, ...employers*/])

                setCarriers([...suppliers, ...employers])
            }
        }

        fetchEmployees()
        fetchLabels()
        fetchBusinessDetails()
        fetchCatalogueItems()
    }, [])

    const onStatusChange = async (status, record, filters, setData, setTotal) => {
        const request = await requests.patch(API.LOGISTICS + "records/" + record.id + "/", { status: parseInt(status) })
        if (request.status === 200) {
            if (filters.status.includes(status) || filters.status.length === 0) {
                setData(prev => prev.filter(item => {
                    if (item.id === record.id) {
                        item.status = request.response.status
                        item.status_display = request.response.status_display
                    }

                    return item
                }))
            } else {
                setData(prev => prev.filter(item => item.id !== record.id))
                setTotal(prev => prev - 1)
            }

            toast({
                type: 'success',
                icon: 'check circle',
                title: t('record_status_changed'),
                animation: 'pulse',
                time: 2000,
            })
        }
    }

    // const getCommodityDisplay = (commodity) => {
    //     let item = commodities.find(item => item.id === )
    // }

    const determinateStatusColor = (record) => {
        let iconColor = "var(--dark)"

        if ( record?.status === 1 ) iconColor = "var(--primary)"
        if ( record?.status === 2 ) iconColor = "var(--info)"
        if ( record?.status === 3 ) iconColor = "var(--blue)"
        if ( record?.status === 4 ) iconColor = "var(--success)"
        if ( record?.status === 5 ) iconColor = "var(--warning)"
        if ( record?.status === 6 ) iconColor = "var(--danger)"

        return iconColor
    }

    return (
        <CanView permissions={accessPerms} redirect>
            <BreadcrumbNav items={[
                { 'name': t('logistics'), 'icon': icons.LOGISTICS, 'href': "" }
            ]}/>

            <Container fluid>
                <Button.Group basic size="small" style={{ borderRadius: '0' }}>
                    <Button disabled active={view === 0} onClick={() => {
                        history.push(routes.LOGISTICS)
                        setView(0)
                    }}>
                        <Icon name="bar-chart-outline" style={{ marginRight: "0.5rem" }}/>
                        <span style={{ position: "relative", top: "-0.1rem" }}>
                            { t('overview') } 
                        </span>
                    </Button>  
                        <Button active={view === 1} onClick={() => {
                            history.push(routes.LOGISTICS)
                            setView(1)
                        }}>
                            <Icon name={icons.LOGISTICS} style={{ marginRight: "0.5rem" }}/> 
                            <span style={{ position: "relative", top: "-0.1rem" }}>
                                { t('buy') } 
                            </span>
                        </Button>
                        <Button active={view === 2} onClick={() => {
                            history.push(routes.LOGISTICS)
                            setView(2)
                        }}>
                            <Icon name={icons.LOGISTICS} style={{ marginRight: "0.5rem" }}/> 
                            <span style={{ position: "relative", top: "-0.1rem" }}>
                                { t('sell') } 
                            </span>
                        </Button>
                </Button.Group>
            </Container>

            { view !== 0 &&
                <>
                    <ListView as="table"
                        key={view}
                        allowSearch
                        allowSelection
                        isSoftDeleteAllowed
                        endpoint={API.LOGISTICS + "records/"}
                        initialFilters={{
                            type: view,
                            labels: [],
                            assigned_to: [],
                            account: "",
                            order: "",
                            commodity: "",
                            carrier: "",
                            type_of_transport: "",
                            is_archived: false,
                            is_fragile: "",
                            status: ["1", "2", "3"],
                            ordering: "-estimated_delivery_date",
                            date_from: today,
                            date_to: today,
                        }}
                        customActionRenderer={(filters, setFilters, fetchData, params) => (
                            <>
                                <Button.Group size="tiny" basic>
                                    <Button 
                                        active={moment(filters.date_from).format("YYYY-MM-DD") === today && moment(filters.date_to).format("YYYY-MM-DD") === today}
                                        onClick={async () => {
                                            setFilters(prev => ({
                                                ...prev,
                                                date_from: today,
                                                date_to: today,
                                                status: ["1", "2", "3"]
                                            }))
        
                                            await fetchData(`&date_from=${today}&date_to=${today}&status=1&status=2&status=3&is_archived=false`, true)
                                        }}
                                    >
                                            { t('todays_delivery') }
                                    </Button>
                                    <Button 
                                        active={filters.date_from === "" && filters.date_to === ""}
                                        onClick={async () => {
                                            setFilters(prev => ({
                                                ...prev,
                                                date_from: "",
                                                date_to: "",
                                                status: []
                                            }))
        
                                            let customParams = params 
                                            customParams = removeURLParameter(customParams, "date_from") 
                                            customParams = removeURLParameter(customParams, "date_to") 
                                            customParams = removeURLParameter(customParams, "status") 
                                            await fetchData("", true, customParams + "&ordering=-estimated_delivery_date")
                                        }}
                                    >
                                            { t('all_records') }
                                    </Button>
                                </Button.Group>
                                <Checkbox
                                    checked={filters.status.includes("4")}
                                    onClick={async () => {
                                        if (filters.status.includes("4")) {
                                            setFilters(prev => ({
                                                ...prev,
                                                status: ["1", "2", "3"]
                                            }))
        
                                            let customParams = removeURLParameter(params, "status") 
                                            await fetchData("", true, customParams + "&status=1&status=2&status=3")
                                        } else {
                                            setFilters(prev => ({
                                                ...prev,
                                                status: ["4"]
                                            }))
        
                                            await fetchData(`&status=4`, true)
                                        }
                                    }}
                                    style={{ marginLeft: "0.5rem" }}
                                    label={t('show_delivered')}
                                />
                            </>
                        )}
                        renderFilterFields={(filters, setFilters) => 
                            <FilterForm
                                labels={labels}
                                filters={filters}
                                carriers={carriers}
                                employees={employees}
                                setFilters={setFilters}
                                businessDetails={businessDetails}
                                catalogueItems={commodities.map(item => ({
                                    key: item.id,
                                    value: item.id,
                                    text: `${item.code ? item.code + " - " : ""}${item.title}`
                                }))}
                                view={view}
                            />
                        }
                        bulkActions={ (selected) => [
                            { 
                                as: "modal",
                                name: t('generate_documents'),
                                icon: "document-text-outline",
                                modal: <GenerateDocuments source="logistics.LogisticRecord" selected={selected} />
                            }
                        ]}
                        exports={[
                            {
                                endpoint: API.EXPORTS + "dynamic_logistic_records/",
                                permissions: ['logistics.c_view_all_transports', 'logistics.c_view_user_transports'],
                                apply_filters: true,
                                type: "xlsx",
                                filename: "logistic_records"
                            },
                        ]}
                        actions={[
                            {
                                as: "modal",
                                type: "add",
                                modalSize: "tiny",
                                modalCentered: false,
                                name: t('create_logistic_records'),
                                modal: <LogisticRecordForm type={view} employees={employees}/>,
                                permissions: ['logistics.c_manage_all_transports', 'logistics.c_manage_user_transports'],
                            },
                            {
                                as: "modal",
                                type: "custom",
                                modalSize: "large",
                                icon: "eye-outline",
                                name: t('detail'),
                                customIconColor: "var(--primary)",
                                modal: (item) => <LogisticRecordDetail record={item}/>,
                                permissions: ['logistics.c_view_all_transports', 'logistics.c_view_user_transports']
                            },
                            {
                                as: "modal",
                                type: "edit",
                                modalCentered: false,
                                name: t('edit'),
                                permissions: ['logistics.c_manage_all_transports', 'logistics.c_manage_user_transports'],
                                modal: (item, setData, setTotal) => <LogisticRecordForm record={item} setData={setData} setTotal={setTotal} initialOrder={item?.order || undefined} type={view} employees={employees}/>,
                            },
                            {
                                name: t('delete'),
                                type: "delete",
                                as: "delete",
                                text: t('are_you_sure_that_you_want_to_remove_this_logistic_record'),
                                permissions: ['logistics.c_delete_all_transports']
                            },
                        ]}
                        tableHeaders={[
                            { title: t('reference') },
                            { title: t('transport') },
                            { title: t('commodity') },
                            { title: t('carrier') },
                            { title: t('transport_information') },
                            { title: t('dates') },
                            { title: t('weight') },
                            { title: t('packages') },
                            { title: t('paid_price') },
                            { title: view === 1 ? t('supplier') : t('account') },
                            { title: t('order') },
                            { title: t('labels') },
                            { title: t('responsible_persons') },
                            { title: t('attachments') },
                            { title: t('status') },
                        ]}
                        renderCells={(record, setData, setTotal, idx, filters) => ([
                            {
                                content: record.reference_number || "--"
                            },
                            {
                                content: record.type_of_transport?.title || "--"
                            },
                            {
                                content: record.order_commodity ? `${record.order_commodity?.item?.code ? record.order_commodity?.item?.code : ""} ${record.order_commodity?.item?.title}` : "--"
                            },
                            {
                                content: record.carrier?.name || "--"
                            },
                            {
                                content: 
                                <>
                                    { t('trans_number') }: <strong>{ record.transport_info }</strong> <br/>
                                    { record.type_of_wagon ? <> { t('type') }: <strong>{ record.type_of_wagon }</strong> <br/> </> : "" }
                                    { record.group ? <> { t('group') } / { t('RWB') }: <strong>{ record.group }</strong> <br/> </> : "" }
                                    { t('loading_address') }: <strong>{ renderShortLongAddress(record.loading_address) }</strong><br/>
                                    { t('destination_address') }: <strong>{ renderShortLongAddress(record.destination_address) }</strong><br/>
                                </>
                            },
                            
                            {
                                content: 
                                <>
                                    { t('loading_date') }: <strong>{ record?.loading_date ? moment(record?.loading_date).format(dateFormat) : "--" }</strong> <br/>
                                    { t('delivery_date_expected') }: <strong>{ moment(record?.estimated_delivery_date).format(dateFormat) }</strong><br/>
                                    { t('delivery_date_real') }: <strong>{ record?.delivery_date ? moment(record?.delivery_date).format(dateFormat) : "--" }</strong><br/>
                                </>
                                
                            },
                            {
                                content: `${record?.weight_total ? record?.weight_total + " " + (record.measure_unit ? record.measure_unit?.abbreviation : "") : "" }`
                            },
                            {
                                content: `${record?.package_total || ""}`
                            },
                            {
                                content: (thousandsSeparators(record.price_paid || 0)) + " " + record.currency
                            },
                            {
                                content: record.account?.name || "--"
                            },
                            {
                                content: record.order ? `${record.order?.order_number}` : "--"
                            },
                            { 
                                content:
                                    <LabelsList
                                        key={record.labels}
                                        rowID={record.id}
                                        label_key="labels"
                                        labels={record.labels}
                                        endpoint={API.LOGISTICS + "records/"}
                                        endpointLabels={API.LOGISTICS + "labels/"}
                                    />
                            },
                            { content: 
                                <AvatarList 
                                    size="25"
                                    alignDirection="start"
                                    persons={
                                        record.assigned_to.map(item => ({
                                            id: item.id,
                                            name: item.fullname,
                                            avatar: item.profile_picture,
                                            displayName: item?.fullname_with_titles || item.fullname,
                                        }))
                                    }
                                />
                            },
                            {
                                content: 
                                <Label basic>
                                    <Popup
                                        style={{ zIndex: "1000", minWidth: "450px" }}
                                        open={attachmentsOpen === record.id}
                                        onOpen={() => setAttachmentsOpen(record.id)}
                                        hoverable
                                        position='bottom center'
                                        trigger={
                                            <div style={{ cursor: "pointer" }}>
                                                <Icon name="reader-outline" style={{ marginRight: "0.5rem" }}/> 
                                                <span style={{ position: "relative", top: "-0.1rem" }}>{ record.attachments.length }</span>
                                            </div>
                                        }
                                        content={
                                            <Attachments 
                                                viewOnly={!canManage}
                                                record={record}
                                                setData={setData}
                                                sourceEndpoint={API.LOGISTICS + "records/"}
                                                closeView={() => setAttachmentsOpen(null)}
                                            />
                                        }
                                    />
                                </Label>
                            },
                            {
                                content: 
                                <>
                                    { canManage
                                    ? 
                                        <Popup 
                                            basic
                                            hoverable
                                            position='bottom center'
                                            style={{ padding: 0 }}
                                            open={open === record?.id}
                                            onOpen={() => setOpen(record?.id)}
                                            onClose={() => {
                                                if (open === record?.id && action === "edit_status") {
                                                    setOpen(record?.id)
                                                } else {
                                                    setOpen(null)
                                                    setAction(null)
                                                }
                                            }}
                                            trigger={
                                                <div>
                                                    <SemanticIcon size="small" name="circle" style={{ color: determinateStatusColor(record), position: "relative", top: "-0.1rem" }}/>
                                                    { record.status_display }
                                                    <Icon name="chevron-down" style={{ marginLeft: "0.4rem", position: "relative", top: "0.2rem" }}/>
                                                </div>
                                            }
                                            content={
                                                <div style={{ width: "100%", textAlign: "left", display: "flex", flexDirection: "column"}}>
                                                    { statuses.map(item => 
                                                        item.value === "4" 
                                                        ? 
                                                        <SuperDuperModal key={item.key}
                                                            size="mini"
                                                            header={t('add_delivery_date')}
                                                            trigger={ 
                                                                <Button 
                                                                    basic
                                                                    size="tiny"
                                                                    type="button" 
                                                                    onClick={() => setAction("edit_status")}
                                                                    style={{ marginRight: 0, textAlign: "left" }}
                                                                    active={parseInt(item.value) === parseInt(record.status)}
                                                                    content={
                                                                        <>
                                                                            <SemanticIcon size="small" name="circle" style={{ color: determinateStatusColor({ status: parseInt(item.value) }), position: "relative", top: "-0.1rem" }}/>
                                                                            { item.text }
                                                                        </>
                                                                    }
                                                                />
                                                            }
                                                            content={<DeliveryDateForm setAction={setAction} record={record} setData={setData} setTotal={setTotal} filters={filters}/>}
                                                        />
                                                        :
                                                        <Button 
                                                            basic
                                                            size="tiny"
                                                            type="button" 
                                                            style={{ marginRight: 0 }}
                                                            active={parseInt(item.value) === parseInt(record.status)}
                                                            key={item.key}
                                                            content={
                                                                <>
                                                                    <SemanticIcon size="small" name="circle" style={{ color: determinateStatusColor({ status: parseInt(item.value) }), position: "relative", top: "-0.1rem" }}/>
                                                                    { item.text }
                                                                </>
                                                            }
                                                            onClick={() => onStatusChange(item.value, record, filters, setData, setTotal)}
                                                        />
                                                    )}
                                                </div>
                                            }
                                        />
                                    : 
                                        <Label style={{ color: "var(--light)", background: determinateStatusColor(record), cursor: "pointer" }}>
                                            <span style={{ position: "relative", top: "0.1rem" }}>{ record.status_display }</span>
                                        </Label>
                                    }
                                </>
                            },
                        ])}
                    />
                </>
            }
        </CanView>
    );
};

export default Logistics;