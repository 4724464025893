import React, { useState, useEffect } from 'react';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import OrderForm from './OrderForm';
// import SaleOrderForm from '../../orders_v2/sales/components/SaleOrderForm';
// import TransportOrderForm from '../../orders_v2/transport/components/TransportOrderForm';
// import EmploymentOrderForm from '../../orders_v2/employment/components/EmploymentOrderForm';
// import ServiceOrderForm from '../../orders_v2/service/components/ServiceOrderForm';
// import HROrderForm from '../../orders_v2/recruitment/components/HROrderForm';

const OrderStageForm = ({ isDuplicate, isPurchase, stageTypeTitle, stage, handleConfirm, resourceType, onClose }) => {
    const [accounts, setAccounts] = useState([])
    const [employees, setEmployees] = useState([])
    const [units, setUnits] = useState([])
    // eslint-disable-next-line
    const [vehicles, setVehicles] = useState([])
    // eslint-disable-next-line
    const [projects, setProjects] = useState([])
    // eslint-disable-next-line
    const [externalCarriers, setExternalCarriers] = useState([])

    // eslint-disable-next-line
    const stageTitle = stage?.type?.title || stageTypeTitle 
    const record = stage?.order_display

    useEffect(() => {
        async function fetchAccounts(){
            const request = await requests.get(API.ACCOUNTS + `business_details/?query={id, name}&${isPurchase ? "is_supplier" : "is_account"}=true&is_active=true&exclude_unit=true`)
            if (request.status === 200) {
                setAccounts(request.response.map(item => ({ key: item.id, value: item.id, text: item.name })))
            }
        }

        async function fetchEmployees(){
            const request = await requests.get(API.EMPLOYEES + "?only_basic_info=true&is_active=true")
            if (request.status === 200) {
                setEmployees(request.response.map(item => ({ key: item.id, value: item.id, text: item.fullname_with_titles })))
            }
        }

        async function fetchUnits(){
            const request = await requests.get(API.UNITS + "?only_from_active_company=true&is_business=true&query={id, name}")
            if (request.status === 200) {
                setUnits(request.response.map(item => ({ key: item.id, value: item.id, text: item.name })))
            }
        }

        // async function fetchVehicles(){
        //     const request = await requests.get(API.VEHICLES + "?query={id, title, car_plate_number}")
        //     if (request.status === 200) {
        //         setVehicles(request.response.map(item => ({ key: item.id, value: item.id, text: `${item.car_plate_number} - ${item.title}` })))
        //     }
        // }

        // async function fetchExternalCarriers(){
        //     const request = await requests.get(API.ACCOUNTS + `business_details/?query={id, name}&is_supplier=true&is_active=true&exclude_unit=true`)
        //     if (request.status === 200) {
        //         setExternalCarriers(request.response.map(item => ({ key: item.id, value: item.id, text: `${item.name}` })))
        //     }
        // }

        // async function fetchProjects(){
        //     const request = await requests.get(API.PROJECTS + "?query={id, name}")
        //     if (request.status === 200) {
        //         setProjects(request.response.map(item => ({ key: item.id, value: item.id, text: item.name })))
        //     }
        // }

        fetchAccounts()
        fetchEmployees()
        fetchUnits()

        // if (resourceType === "transportOrder") {
        //     fetchVehicles()
        //     fetchExternalCarriers()
        // }

        // if (resourceType === "serviceorder") {
        //     fetchProjects()
        // }
        // eslint-disable-next-line
    }, [])

    const onCreateHandler = (response, processGroup) => {
        handleConfirm(response, processGroup)
    }

    const setResourceType = (resourceType) => {
        let resourcetype = "" 

        if (resourceType === "saleorder") resourcetype = "SaleOrder"
        if (resourceType === "transportorder") resourcetype = "TransportOrder"
        if (resourceType === "hrorder") resourcetype = "HROrder"

        return resourcetype
    }

    return (
        <>  
            <OrderForm
                isDuplicate={isDuplicate}
                stageTitle={stageTitle}
                record={record}
                stage={stage}
                owners={units}
                onClose={onClose}
                accounts={accounts}
                setAccounts={setAccounts}
                employees={employees}
                isPurchase={isPurchase}
                customSubmitHandler={onCreateHandler}
                resourceType={setResourceType(resourceType)}
            />
            {/* { resourceType === "saleorder" && <SaleOrderForm record={record} customSubmitHandler={onCreateHandler} isPurchase={isPurchase} accounts={accounts} employees={employees} owners={units} onClose={onClose}/>}   
            { resourceType === "transportorder" && <TransportOrderForm record={record} customSubmitHandler={onCreateHandler} isPurchase={isPurchase} accounts={accounts} employees={employees} owners={units} vehicles={vehicles} externalCarriers={externalCarriers} onClose={onClose}/>}   
            { resourceType === "hrorder" && <HROrderForm record={record} customSubmitHandler={onCreateHandler} isPurchase={isPurchase} accounts={accounts} employees={employees} owners={units} onClose={onClose}/>}    */}
            
            {/* Maybe later... ignore for no */}
            {/* { resourceType === "employmentorder" && <EmploymentOrderForm record={record} customSubmitHandler={onCreateHandler} isPurchase={isPurchase} accounts={accounts} employees={employees} owners={units} onClose={onClose}/>}   
            { resourceType === "serviceorder" && <ServiceOrderForm record={record} customSubmitHandler={onCreateHandler} isPurchase={isPurchase} projects={projects} accounts={accounts} employees={employees} owners={units} onClose={onClose}/>}    */}
        </>
    );
};

export default OrderStageForm;