import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { isEmailValid, isPhoneNumberValid } from '@helpers/validation';
import { getRandomColor, renderAddress } from '@helpers/functions';
// components
import Icon from '@components/Icon';
import CanView from '@components/perms/CanView';
import Address from '@components/forms/Address';
import SuperField from '@components/forms/SuperField';
import NonFieldErrors from '@components/NonFieldErrors';
import ModalCancel from '@components/buttons/ModalCancel';
import ConfirmModal from '@components/modals/ConfrimModal';
import EmailField from '@components/forms/common/EmailField';
import PhoneField from '@components/forms/common/PhoneField';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import { Form, Divider, Header, Message, Button } from 'semantic-ui-react';

const getAccountType = (record) => {
    if (record === undefined) return 1
    if (!record.is_non_business_client && !record.is_freelancer && !record.is_account_employer) return 1
    if (record.is_non_business_client && !record.is_freelancer && !record.is_account_employer) return 4
    if (!record.is_non_business_client && record.is_freelancer && !record.is_account_employer) return 2
    if (!record.is_non_business_client && !record.is_freelancer && record.is_account_employer) return 3

    return 1
}

const AccountForm = ({ isDirect, record, setData, setTotal, isSupplier, onClose, handleAddToList }) => {
    const { t } = useTranslation()
    
    const [loadingNaceCodes, setLoadingNaceCodes] = useState(false)
    const [naceCodes, setNaceCodes] = useState([])
    const [processing, setProcessing] = useState(false)
    const [errors, setErrors] = useState(null)
    const [view, setView] = useState(getAccountType(record)) // select view (1 - business person, 2 - Self employed, 3 - Employer, 4 - Individual person)
    const [form, setForm] = useState({
        is_supplier: record?.is_supplier !== undefined ? record?.is_supplier : (isSupplier ? true : false),
        is_account: record?.is_account !== undefined ? record?.is_account : (isSupplier ? false : true),
        name: record?.name || "",
        identification_number: record?.identification_number || "", // ičo
        dit_number: record?.dit_number || "", // dič
        vat_number: record?.vat_number || "", // ič dph
        business_register_folder: record?.business_register_folder || "",
        tax_mode: record?.tax_mode?.toString() || "1",
        executive_manager: record?.executive_manager || "",
        description: record?.description || "",
        website: record?.website || "",
        unit: record?.unit?.id || "",
        nace_code: record?.nace_code?.id || "",
        color: record?.color || getRandomColor(),
    })

    const [assignedTo, setAssignedTo] = useState(record?.assigned_to?.map(item => item.id) || [])
    const [tags, setTags] = useState(record?.tags?.map(item => item.id) || [])
    // eslint-disable-next-line
    const [contacts, setContacts] = useState(record?.contacts.map(item => ({
        id: item.id, 
        phone: item?.phone || "",
        email: item?.email || "",
        is_primary: item?.is_primary || false,
        is_invoicing: item?.is_invoicing || false,
        contact_person_name: item?.contact_person_name || "",
    })) || [])

    const [billingAddress, setBillingAddress] = useState({
        street: record?.billing_address?.street || "",
        number: record?.billing_address?.number || "",
        orientation_number: record?.billing_address?.orientation_number || "",
        city: record?.billing_address?.city || "",
        postcode: record?.billing_address?.postcode || "",
        country: record?.billing_address?.country || "SK",
        state: record?.billing_address?.state || "",
        address_line: record?.billing_address?.address_line || "",
    })

    const [shippingAddress, setShippingAddress] = useState({
        street: record?.shipping_address?.street || "",
        number: record?.shipping_address?.number || "",
        orientation_number: record?.shipping_address?.orientation_number || "",
        city: record?.shipping_address?.city || "",
        postcode: record?.shipping_address?.postcode || "",
        country: record?.shipping_address?.country ||"SK",
        state: record?.shipping_address?.state || "",
        address_line: record?.shipping_address?.address_line || "",
    })

    const [primaryBank, setPrimaryBank] = useState({
        id: record?.bank_accounts?.find(item => item.default)?.id || 0,
        iban: record?.bank_accounts?.find(item => item.default)?.iban || "",
        currency: record?.bank_accounts?.find(item => item.default)?.currency || "EUR",
        swift_code: record?.bank_accounts?.find(item => item.default)?.swift_code || "",
    })

    useEffect(() => {
        async function fetchNaceCodes() {
            setLoadingNaceCodes(true)
            const request = await requests.get(API.NACE_CODE + "?query={id, code, name}")
            if (request.status === 200) {
                setNaceCodes(request.response.map(item => ({ 
                    key: item.id,
                    value: item.id,
                    text: `${item.code} - ${item.name}`
                 })))
            }
            setLoadingNaceCodes(false)
        }

        fetchNaceCodes()
    }, [])

    const isAddressValid = (address) => {
        if (
            address.street === "" || 
            address.city === "" || 
            address.postcode === "" || 
            address.country === ""
        ) {
            return false
        }
        return true
    }

    const handleSubmit = async () => {
        setErrors(null)
        setProcessing(true)
        
        let data = form

        // handle flags
        if (view === 1) {
            data.is_freelancer = false
            data.is_non_business_client = false
            data.is_account_employer = false
        }

        if (view === 2) {
            data.is_freelancer = true
            data.is_non_business_client = false
            data.is_account_employer = false
        }

        if (view === 3) {
            data.is_freelancer = false
            data.is_non_business_client = false
            data.is_account_employer = true
        }

        if (view === 4) {
            data.is_freelancer = false
            data.is_non_business_client = true
            data.is_account_employer = false
        }

        // handle tags
        if (record?.id === undefined) {
            data.tags = { add: tags }
        } else {
            data.tags = { remove: record.tags.map(item => item.id), add: tags }
        }

        // handle addresses:
        if (isAddressValid(billingAddress)) {
            data.billing_address = billingAddress
        } else {
            data.billing_address = null
        }
        
        if (isAddressValid(shippingAddress)) {
            data.shipping_address = shippingAddress
        } else {
            data.shipping_address = null
        }
        
        // handle assigned to
        if (record?.id === undefined) {
            data.assigned_to = { add: assignedTo }
        } else {
            data.assigned_to = { remove: record.assigned_to.map(item => item.id), add: assignedTo }
        }

        // handle primary bank account
        if (primaryBank.iban !== "" && primaryBank.swift_code !== "") {
            if (primaryBank.id === 0) {
                data.bank_accounts = {
                    create: [{
                        default: true,
                        iban: primaryBank.iban,
                        swift_code: primaryBank.swift_code,
                        currency: primaryBank.currency
                    }]
                }
            } else if (primaryBank.id > 0) {
                data.bank_accounts = {
                    update: {
                        [primaryBank.id]: {
                            default: true,
                            iban: primaryBank.iban,
                            swift_code: primaryBank.swift_code,
                            currency: primaryBank.currency
                        }
                    }
                }
            }
        }

        // handle contacts
        let dataContacts = {
            toCreate: contacts.filter(item => item.id === 0),
            toUpdate: contacts.filter(item => item.id > 0)
        }
        
        if ( dataContacts.toCreate.length === 0 && dataContacts.toUpdate.length === 0 ) {
            data.contacts = null
        } 

        data.contacts = {}
        if (dataContacts.toCreate.length > 0) {
            data.contacts['create'] = []
            const toCreate = dataContacts.toCreate
            for (let i = 0; i < toCreate.length; i++) {
                data.contacts['create'].push({
                    type: 4, // account/supplier
                    phone: toCreate[i].phone,
                    email: toCreate[i].email,
                    is_invoicing: toCreate[i].is_invoicing,
                    contact_person_name: toCreate[i].contact_person_name,
                })
            }
        }

        if (dataContacts.toUpdate.length > 0) {
            const toUpdate = dataContacts.toUpdate
            data.contacts['update'] = {}
            for (let i = 0; i < toUpdate.length; i++) {
                data.contacts['update'][toUpdate[i].id] = {
                    type: 4, // account/supplier
                    phone: toUpdate[i].phone,
                    email: toUpdate[i].email,
                    is_invoicing: toUpdate[i].is_invoicing,
                    contact_person_name: toUpdate[i].contact_person_name,
                }
            }
        }

        let request = undefined
        if (record?.id === undefined) {
            request = await requests.post(API.ACCOUNTS + "business_details/", data)
        } else { 
            request = await requests.patch(API.ACCOUNTS + "business_details/" + record.id +"/", data)
        }

        if (request?.status === 400) setErrors(request?.response)
        if (request?.status === 201) {
            if (handleAddToList) {
                handleAddToList(request.response, onClose)
            } else {
                setTotal(prev => prev + 1)
                setData(prev => ([request.response, ...prev]))
            }
        } 
        if (request?.status === 200){
            if (isDirect) {
                setData(request.response)
                onClose()
            } else {
                if (isSupplier) {
                    if (form.is_supplier === false) {
                        setData(prev => prev.filter(item => item.id !== record.id))
                    } else {
                        setData(prev => prev.map(item => {
                            if (item.id === record?.id) {
                                item = request.response
                            }
                            return item
                        }))
                    }
                } else {
                    if (form.is_account === false) {
                        setData(prev => prev.filter(item => item.id !== record.id))
                    } else {
                        setData(prev => prev.map(item => {
                            if (item.id === record?.id) {
                                item = request.response
                            }
                            return item
                        }))
                    }
                }
            }
            
        }

        setProcessing(false)
    }

    const isFormValid = () => {
        if (processing) return false // can not be submitted while processing

        // validate data if not processing
        if (view === 0) return false
        if (form.name === "") return false
        if ([1, 2, 3].includes(view) && form.identification_number === "") return false
        if ([1, 2, 3].includes(view) && form.tax_mode === "") return false

        if (primaryBank.iban !== "" && primaryBank.swift_code === "") return false

        return true
    }

    const getFieldErrors = (field) => {
        return errors?.[field]?.[0] || false
    }

    const isBusiness = () => { 
        return [1, 2, 3].includes(view) 
    }

    const setViewChoices = () => {
        let options = []

        options.push({ key: 1, value: 1, text: t('business_person') })
        options.push({ key: 2, value: 2, text: t('self_employed_singular') })
        
        if (isSupplier === false || isSupplier === undefined) {
            options.push({ key: 3, value: 3, text: t('account_employer') })
            options.push({ key: 4, value: 4, text: t('individual_person') })
        }

        return options
    }

    const handleContactRemove = async (contact, idx) => {
        if (contact.id > 0) { // permanently remove
            const request = await requests.del(API.CONTACTS + "records/" + contact.id + "/")
            if (request.status === 204) {
                setContacts(prev => prev.filter(item => item.id !== contact.id))
            }
        } else { // remove from view (local delete)
            setContacts(prev => prev.filter((item, index) => index !== idx))
        }
    }

    return (
        <Form onSubmit={(e) => e.preventDefault()}>
            <NonFieldErrors errors={errors}/>

            <Form.Group>
                <SuperField as="input"
                    required
                    autoFocus
                    width="16"
                    label={t('name')}
                    value={form.name}
                    placeholder={t('enter_name')}
                    errors={getFieldErrors("name") }
                    onChange={(e, { value }) => setForm(prev => ({...prev, name: value}))}
                />
                <SuperField as="choice"
                    search
                    required
                    width="10"
                    value={view}
                    label={t('type')}
                    clearable={false}
                    customOptions={setViewChoices()}
                    // disabled={record?.id !== undefined}
                    onChange={(e, { value }) => setView(parseInt(value))}
                />
            </Form.Group>

            { record?.id === undefined 
                ? 
                    <SuperField as="checkbox"
                        help={t('if_you_want_it_to_be_displayed_in_both_agendas')}
                        label={isSupplier ? t('mark_as_account_also') : t('mark_as_supplier_also')}
                        checked={ isSupplier ? form.is_account : form.is_supplier }
                        onChange={() => setForm(prev => ({
                            ...prev,
                            [isSupplier ? "is_account" : "is_supplier"]: isSupplier ? !form.is_account : !form.is_supplier 
                        }))}
                    />
                : 
                <>
                    { (record?.is_account && record?.is_supplier) 
                        ? 
                        <>
                            <div style={{ fontWeight: "bold", marginBottom: "0.5rem" }}>{ t('shared_agendas_select_to_remove') }:</div>
                            <SuperField as="checkbox"
                                label={t('remove_from_suppliers')}
                                checked={ !form.is_supplier }
                                disabled={!form.is_account && form.is_supplier}
                                onChange={() => setForm(prev => ({
                                    ...prev,
                                    is_supplier: !form.is_supplier 
                                }))}
                            />
                            <SuperField as="checkbox"
                                label={t('remove_from_accounts')}
                                checked={ !form.is_account }
                                disabled={!form.is_supplier && form.is_account}
                                onChange={() => setForm(prev => ({
                                    ...prev,
                                    is_account: !form.is_account 
                                }))}
                            />
                        </>
                        : 
                        <SuperField as="checkbox"
                            help={t('if_you_want_it_to_be_displayed_in_both_agendas')}
                            label={isSupplier ? t('mark_as_account_also') : t('mark_as_supplier_also')}
                            checked={ isSupplier ? form.is_account : form.is_supplier }
                            onChange={() => setForm(prev => ({
                                ...prev,
                                [isSupplier ? "is_account" : "is_supplier"]: isSupplier ? !form.is_account : !form.is_supplier 
                            }))}
                        />
                    }
                </>
            }
            
            { isBusiness() && 
                <>
                    <Divider/>
                    <Form.Group widths="equal">
                        <SuperField as="input"
                            required
                            width="10"
                            label={t("id_number")}
                            value={form.identification_number}
                            errors={getFieldErrors("identification_number") }
                            onChange={(e, { value }) => setForm(prev => ({...prev, identification_number: value}))}
                        />
                        <SuperField as="input"
                            width="10"
                            label={t("dit_number")}
                            value={form.dit_number}
                            errors={getFieldErrors("dit_number") }
                            onChange={(e, { value }) => setForm(prev => ({...prev, dit_number: value}))}
                        />
                        <SuperField as="input"
                            label={t("vat_number")}
                            value={form.vat_number}
                            errors={getFieldErrors("vat_number") }
                            onChange={(e, { value }) => setForm(prev => ({...prev, vat_number: value}))}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <SuperField as="choice"
                            required
                            clearable={false}
                            label={t('tax_mode')}
                            type="tax_mode"
                            value={form.tax_mode}
                            errors={getFieldErrors("tax_mode") }
                            onChange={(e, { value }) => setForm({ ...form, tax_mode: value })}
                        />
                        <SuperField as="choice"
                            search
                            // text="name"
                            label={t('nace_code')}
                            value={form.nace_code}
                            customOptions={naceCodes}
                            loading={loadingNaceCodes}
                            disabled={loadingNaceCodes}
                            // endpoint={API.NACE_CODE}
                            errors={getFieldErrors("nace_code") }
                            onChange={(e, { value }) => setForm({ ...form, nace_code: value })}
                        />
                    </Form.Group>
                    <SuperField as="input"
                        label={t('register_folder')}
                        placeholder={t('register_folder')}
                        value={form.business_register_folder}
                        errors={getFieldErrors("business_register_folder") }
                        onChange={(e, { value }) => setForm({ ...form, business_register_folder: value })}
                    />

                    <SuperField as="input"
                        label={t("executive_manager")}
                        value={form.executive_manager}
                        errors={getFieldErrors("executive_manager") }
                        onChange={(e, { value }) => setForm(prev => ({...prev, executive_manager: value}))}
                    />

                    <Form.Field>
                        <Header as="h3" content={t('primary_bank_account_details')}/>
                    </Form.Field>
                    <Form.Group widths="equal">
                        <SuperField as="input"
                            label={t("iban")}
                            value={primaryBank.iban}
                            onChange={(e, { value }) => setPrimaryBank(prev => ({...prev, iban: value}))}
                        />
                        <SuperField as="input"
                            width="10"
                            label={t("swift")}
                            value={primaryBank.swift_code}
                            onChange={(e, { value }) => setPrimaryBank(prev => ({...prev, swift_code: value}))}
                            error={(primaryBank.iban !== "" && primaryBank.swift_code === "") ? t('can_not_be_empty') : false}
                        />
                        <SuperField as="choice"
                            search
                            width="10"
                            clearable={false}
                            type="currency_codes"
                            label={t("currency")}
                            value={primaryBank.currency}
                            onChange={(e, { value }) => setPrimaryBank(prev => ({...prev, currency: value}))}
                        />
                    </Form.Group>
                </>
            }

            <Divider/>

            <SuperField as="input"
                label={t('website')}
                value={form.website}
                onChange={(e, { value }) => setForm({...form, 
                    website: value.includes('https://') ? value : "https://" + value.replace('http://', '') 
                })}
                error={getFieldErrors("website")}
            />

            <SuperField as="textarea"
                label={t("description")}
                value={form.description}
                placeholder={t('enter_description')}
                error={getFieldErrors("description") }
                onChange={(e, { value }) => setForm({ ...form, description: value })}
            />
            
            <Divider/>

            <Form.Group widths="equal">
                <Form.Field>
                    <Header as="h3" content={t('billing_and_shipping_address')}/>
                    
                    <div>
                        <span style={{ opacity: 0.8 }}>
                            <Icon name="location-outline" style={{ marginRight: "0.5rem" }}/>
                            <span style={{ position: "relative", top: "-0.1rem" }}>
                                { isAddressValid(billingAddress) ? renderAddress(billingAddress) : t("undefined_billing_address") }
                            </span>
                        </span> <br/>
                        <SuperDuperModal
                            centered={false}
                            header={ isAddressValid(billingAddress) ? t('update_billing_address') : t('add_billing_address') }
                            trigger={
                                <strong className="ref-link" style={{ color: "var(--primary)" }}>
                                    { isAddressValid(billingAddress) ? t('update_billing_address') : t('add_billing_address') }
                                </strong>
                            }
                            content={
                                <AddressForm address={billingAddress} setAddress={setBillingAddress}/>
                            }
                        />
                    </div>

                    <div style={{ marginTop: "1rem" }}>
                        <span style={{ opacity: 0.8 }}>
                            <Icon name="location-outline" style={{ marginRight: "0.5rem" }}/>
                            <span style={{ position: "relative", top: "-0.1rem" }}>
                                { isAddressValid(shippingAddress) ? renderAddress(shippingAddress) : t("undefined_shipping_address") }
                            </span>
                        </span> <br/>
                        <SuperDuperModal
                            centered={false}
                            header={ isAddressValid(billingAddress) ? t('update_shipping_address') : t('add_shipping_address') }
                            trigger={
                                <strong className="ref-link" style={{ color: "var(--primary)" }}>
                                    { isAddressValid(shippingAddress) ? t('update_shipping_address') : t('add_shipping_address') }
                                </strong>
                            }
                            content={
                                <AddressForm address={shippingAddress} setAddress={setShippingAddress}/>
                            }
                        />
                    </div>
                </Form.Field>
                <Form.Field>
                    <div>
                        <Header as="h3" content={t('contacts')}/>
                        <span style={{ color: contacts.length > 0 ? "var(--dark)" : "var(--danger)", fontWeight: contacts.length > 0 ? "normal" : "bold" }}>
                        { contacts.length > 0 
                            ? 
                                contacts.map((contact, idx) => (
                                    <div key={idx}>
                                        <div style={{ display: "flex", flexDirection: "row", itemsAlign: "start", justifyContent: "space-between" }}>
                                            <div>
                                                { contact.contact_person_name !== "" && <strong>{ contact.contact_person_name } <br/></strong> } 
                                                { contact.phone !== "" ? <>{ contact.phone } <br/></> : ""}
                                                { contact.email !== "" ? <>{ contact.email } </> : ""}
                                                { contact.is_invoicing && 
                                                    <div style={{ opacity: 0.8, fontSize: "0.9rem", fontWeight: "bold", marginTop: "0.2rem" }}> 
                                                        {t('used_for_invoicing')} 
                                                    </div>
                                                }
                                            </div>
                                            <div style={{ textAlign: "right" }}>
                                                <span style={{ fontWeight: "bold", opacity: 0.5, marginRight: "1rem", position: "relative", top: "-0.1rem" }}>#{idx + 1}.</span>
                                                <SuperDuperModal
                                                    centered={false}
                                                    header={t('update_contact')}
                                                    trigger={
                                                        <Icon name="pencil" style={{ marginRight: "1rem", cursor: "pointer" }}/>
                                                    }
                                                    content={
                                                        <ContactForm setContacts={setContacts} contact={contact} index={idx}/>
                                                    }
                                                />
                                                <CanView permissions={['contacts.c_delete_contacts']}>
                                                    <ConfirmModal
                                                        description={t('are_you_sure_that_you_want_to_remove_this_contact')}
                                                        onConfirm={() => handleContactRemove(contact, idx)}
                                                        button={
                                                            <Icon name="close" style={{ cursor: "pointer", color: "var(--danger)" }}/>
                                                        }
                                                    />
                                                    
                                                </CanView>
                                            </div>
                                        </div>
                                        <Divider/>
                                    </div>
                                )) 
                            : <>{t('no_active_contact')} <br/></>
                        }
                        </span>
                        <SuperDuperModal
                            centered={false}
                            header={t('add_contact')}
                            trigger={
                                <strong className="ref-link" style={{ color: "var(--primary)" }}>
                                    { t('add_contact') }
                                </strong>
                            }
                            content={
                                <ContactForm setContacts={setContacts}/>
                            }
                        />
                        
                    </div>
                </Form.Field>
            </Form.Group>

            <Divider/>

            <Form.Group widths="equal">
                <SuperField as="choice"
                    search
                    text="name"
                    label={t('unit')}
                    value={form.unit}
                    endpoint={API.UNITS + "?query={id, name}&only_from_active_company=true"}
                    onChange={(e, { value }) => setForm({ ...form, unit: value })}
                />         

                <SuperField as="colorpicker"
                    label={t('color')}
                    help={t("color_to_improve_visual_in_graphs")}
                    color={form.color}
                    setColor={(value) => setForm(prev => ({...prev, color: value}))}
                />  
            </Form.Group>

            <SuperField as="choice"
                search
                multiple
                text="fullname_with_titles"
                label={t('responsible_persons')}
                value={assignedTo}
                endpoint={API.EMPLOYEES + "?is_active=true&only_basic_info=true"}
                onChange={(e, { value }) => setAssignedTo(value)}
            />

            <SuperField as="choice"
                search
                multiple
                text="name"
                value={tags}
                label={t('labels')}
                settings="types/account-labels"
                endpoint={API.ACCOUNTS + "labels/?query={id, name}"}
                onChange={(e, { value }) => setTags(value)}
            />

            <Divider/>
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose}/>
                <Button primary 
                    type="button"
                    content={t('confirm')}
                    loading={processing}
                    disabled={!isFormValid()}
                    onClick={() => handleSubmit()}
                />
            </Form.Field>
        </Form>
    );
};

const AddressForm = ({ address, setAddress, onClose }) => {
    const { t } = useTranslation()

    const isAddressValid = (address) => {
        if (
            address.street === "" || 
            address.city === "" || 
            address.postcode === "" || 
            address.country === ""
        ) {
            return false
        }
        return true
    }

    return (
        <Form>
            <Message
                info
                visible
                content={
                    <strong>
                        <Icon name="information-circle" style={{ marginRight: "0.5rem", fontSize: "1.2rem", position: "relative", top: "0.2rem" }}/> 
                        { t('address_form_hint')}.
                    </strong>
                }
            />
            <Address isForm address={address} setAddress={setAddress}/>
            <Divider/>
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose}/>
                <Button onClick={() => onClose()} type="button" primary disabled={!isAddressValid(address)}>
                    {t('confirm')}
                </Button>
            </Form.Field>
        </Form>  
    )
}

const ContactForm = ({ contact, setContacts, index, onClose }) => {
    const { t } = useTranslation()

    const [form, setForm] = useState({
        id: contact?.id || 0, 
        phone: contact?.phone || "",
        email: contact?.email || "",
        is_primary: contact?.is_primary || false,
        is_invoicing: contact?.is_invoicing || false,
        contact_person_name: contact?.contact_person_name || "",
    })

    const handleSubmit = async () => {
        if (index === undefined) {
            setContacts(prev => ([...prev, form]))
        } else {
            setContacts(prev => prev.map((item, idx) => {
                if (idx === index) {
                    item = form
                }
                return item
            }))
        }
        onClose()
    }

    return (
        <Form>
            <Message
                info
                visible
                content={
                    <strong>
                        <Icon name="information-circle" style={{ marginRight: "0.5rem", fontSize: "1.2rem", position: "relative", top: "0.2rem" }}/> 
                        { t('contact_form_hint')}.
                    </strong>
                }
            />
            <PhoneField
                hideType
                placeholder={t('phone')}
                value={form.phone}
                setValue={(e, { value }) => setForm(prev => ({...prev, phone: value}))}
            />

            <EmailField
                label={t('email')}
                placeholder={t('email')}
                value={form.email}
                setValue={(e, { value }) => setForm(prev => ({...prev, email: value}))}
            />
            <SuperField as="input"
                label={t('contact_person_name')}
                value={form.contact_person_name}
                onChange={(e, { value }) => setForm(prev => ({...prev, contact_person_name: value}))}
            />

            <SuperField as="checkbox"
                label={t('used_for_invoicing')}
                checked={form.is_invoicing}
                onChange={() => setForm(prev => ({...prev, is_invoicing: !form.is_invoicing}))}
            />

            {/* <SuperField as="checkbox"
                label={t('used_as_primary')}
                checked={form.is_primary}
                onChange={() => setForm(prev => ({...prev, is_primary: !form.is_primary}))}
            /> */}
            <Divider/>
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose}/>
                <Button 
                    primary
                    type="button"
                    onClick={() => handleSubmit()}
                    disabled={
                        (form.phone === "" && form.email === "") || 
                        (!isEmailValid(form.email) && form.email !== "") ||
                        (!isPhoneNumberValid(form.phone) && form.phone !== "")
                    }
                >
                    {t('confirm')}
                </Button>
            </Form.Field>
        </Form>  
    )
}

export default AccountForm;