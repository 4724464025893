import React from 'react';
import { useTranslation } from "react-i18next";
import { Divider, Form } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import SalariesFieldGroup from './SalariesFieldGroup';

const ContractsFieldGroup = ({ contract, setContract, salary, setSalary }) => {
    const { t } = useTranslation();

    return (
        <div>
            <Form.Group widths='equal'>
                <SuperField as="choice"
                    label={t('contract_type')}
                    type="contract_type_choices"
                    value={contract.contract_type}
                    onChange={(e, data) => setContract({
                        ...contract,
                        contract_type: data.value
                    })}
                    />
                <SuperField as="choice"
                    label={t('work_level')}
                    type="contracts_work_levels"
                    value={contract.work_level}
                    onChange={(e, { value }) => setContract({ ...contract, work_level: value})}
                />
                <SuperField as="choice"
                    label={t('seniority_level')}
                    type="seniority_level_types"
                    value={contract.seniority_level}
                    onChange={(e, { value }) => setContract({ ...contract, seniority_level: value})}
                />
            </Form.Group>

            <Form.Group widths='equal'>
                <SuperField as="choice"
                    type="work_schedule"
                    label={t('work_schedule')}
                    value={contract.work_schedule}
                    onChange={(e, data) => setContract({
                        ...contract,
                        work_schedule: data.value
                    })}
                />
                <SuperField as="choice"
                    type="work_shift"
                    label={t('work_shifts')}
                    value={contract.work_shift}
                    onChange={(e, data) => setContract({
                        ...contract,
                        work_shift: data.value
                    })}
                />
            </Form.Group>

            <Divider/>

            <Form.Field>
                <SalariesFieldGroup
                    salary={salary}
                    setSalary={setSalary}
                />
            </Form.Field>

        </div>
    );
};

export default ContractsFieldGroup;