import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config';
import { icons } from '@store/icons';
import { routes } from '@store/routes';
import { requests } from '@helpers/requests';
// components
import Action from '@components/general/Action';
import ListView from '@components/ListView';
import CanView from '@components/perms/CanView';
import BreadcrumbNav from '@components/BreadcrumbNav';
import GenerateDocuments from '@components/GenerateDocuments';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import { Icon as SemanticIcon, Button } from 'semantic-ui-react';
// specific
import TerminationForm from './components/TerminationForm';
import TerminationFilterForm from './components/TerminationFilterForm';
import TerminationUploadScannedFile from './components/TerminationUploadScannedFile';

const Terminations = () => {
    const { t } = useTranslation()
    const dateFormat = useSelector(state => state.date_format)

    const onDocumentDelete = async (id, setData) => {
        const request = await requests.del(API.CONTRACTS_TERMINATIONS + id + "/file/")

        if (request.status === 204) {
            setData(prev => prev.filter(item => {
                if (item.id === id) {
                    item.external_file = null
                }
                return item
            }))
        }
    }

    return (
        <CanView permissions={['contracts.c_view_all_contracts', 'contracts.c_view_user_contracts', 'company.c_manage_only_assigned_unit_employees', 'common.c_view_only_agency_employees', 'common.c_view_only_internal_employees', 'common.c_view_only_external_employees']} redirect>
            <BreadcrumbNav items={[{ name: t("terminations"), icon: icons.TERMINATIONS, href: '' }]} />
            <ListView
                as="table"
                allowSearch
                allowSelection
                isSoftDeleteAllowed
                endpoint={API.CONTRACTS_TERMINATIONS}
                actionsCellWidth="2"
                bulkActions={ (selected) => [
                    { 
                        as: "modal",
                        name: t('generate_documents'),
                        icon: "document-text-outline",
                        modal: <GenerateDocuments source="contracts.ContractTermination" selected={selected} />
                    }
                ]}
                exports={[
                    {
                        endpoint: API.EXPORTS + "dynamic_contract_terminations/",
                        permissions: ['contracts.c_view_all_contracts', 'contracts.c_view_user_contracts', 'company.c_manage_only_assigned_unit_employees', 'common.c_view_only_agency_employees', 'common.c_view_only_internal_employees', 'common.c_view_only_external_employees'],
                        apply_filters: true,
                        type: "xlsx",
                        filename: "terminations"
                    },
                ]}
                actions={[
                    {
                        name: t('note'),
                        type: "custom",
                        as: "modal",
                        icon: "chatbox-ellipses-outline",
                        modalSize: "tiny",
                        customIconColor: "var(--dark)", 
                        isHidden: (item) => (item.note === "" || item.note === null),
                        permissions: ['contracts.c_view_all_contracts', 'contracts.c_view_user_contracts', 'company.c_manage_only_assigned_unit_employees', 'common.c_manage_only_agency_employees', 'common.c_manage_only_internal_employees', 'common.c_manage_only_external_employees'],
                        modal: (item) => <p> {item.note !== "" ? item.note : t('note_is_empty')} </p>
                    },
                    {
                        name: t('edit'),
                        type: "edit",
                        as: "modal",
                        icon: "pencil-outline",
                        modalSize: "tiny",
                        customIconColor: "var(--dark)", 
                        permissions: ['contracts.c_manage_all_contracts', 'company.c_manage_only_assigned_unit_employees', 'common.c_manage_only_agency_employees', 'common.c_manage_only_internal_employees', 'common.c_manage_only_external_employees'],
                        modal: <TerminationForm/>
                    },
                    {
                        name: t('delete'),
                        type: "delete",
                        as: "delete",
                        text: t('delete_contract_message'),
                        permissions: ['contracts.c_delete_all_contracts', 'company.c_manage_only_assigned_unit_employees', 'common.c_delete_only_agency_employees', 'common.c_delete_only_external_employees', 'common.c_delete_only_internal_employees']
                    },
                ]}
                tableHeaders={[
                    { title: t("employee")},
                    { title: t("employer")},
                    { title: t("notice_period")},
                    { title: t("termination_type")},
                    { title: t("terminated_by")},
                    { title: t("reason")},
                    { title: t("document")},
                ]}
                initialFilters={{
                    type: "",
                    employee: "",
                    terminated_by: "",
                    notice_period_after: "",
                    notice_period_before: ""
                }}
                renderFilterFields={(filters, setFilters) => <TerminationFilterForm filters={filters} setFilters={setFilters}/>}
                renderCells={(termination, setData) => ([
                    { content: 
                        <>
                            <Link to={routes.EMPLYOEES_DETAIL + termination?.contract?.employee?.id} target="_blank">
                                <strong>{termination?.contract?.employee?.fullname}</strong>
                            </Link> <br />
                            <small>
                                { termination?.contract?.work_position?.title}
                            </small>
                        </>
                    },
                    { content: termination?.contract?.employer?.name },
                    { content: termination?.notice_period ? moment(termination?.notice_period).format(dateFormat) : "--" },
                    { content: termination?.type_display },
                    { content: termination?.terminated_by_display },
                    { content: termination?.reason?.title },
                    { content: 
                        <>
                            { termination.external_file 
                                ? 
                                <>
                                    <a href={termination?.external_file || ""} target="_blank" rel="noopener noreferrer">
                                        <SemanticIcon style={{ cursor: "pointer", color: "var(--danger)" }} size="large" name="file pdf" />
                                    </a>
                                    <CanView permissions={['contracts.c_manage_all_contracts', 'company.c_manage_only_assigned_unit_employees', 'common.c_manage_only_agency_employees', 'common.c_manage_only_internal_employees', 'common.c_manage_only_external_employees']}>
                                        <Action as="confirm" type="icon" icon="close-outline" tooltip={t('remove')} size="small" iconColor="var(--danger)"
                                            onClick={() => onDocumentDelete(termination.id, setData)}
                                            text={t('are_you_sure')}
                                        />
                                    </CanView>
                                </>
                                : 
                                <CanView permissions={['contracts.c_manage_all_contracts', 'company.c_manage_only_assigned_unit_employees', 'common.c_manage_only_agency_employees', 'common.c_manage_only_internal_employees', 'common.c_manage_only_external_employees']}>
                                    <SuperDuperModal
                                        trigger={<Button size="tiny" basic content={t('add_document')} />}
                                        content={<TerminationUploadScannedFile terminationId={termination.id} setTerminations={setData} />}
                                    />
                                </CanView>
                            }
                        </>
                        
                    },
                ])}
            />
        </CanView>
    );
};

export default Terminations;