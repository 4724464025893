import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { useHasPermission } from '@helpers/hooks';
import { setDotSeparator } from '@helpers/functions';
import { thousandsSeparators } from '@helpers/functions';
// components
import Icon from '@components/Icon';
import CanView from '@components/perms/CanView';
import SuperField from '@components/forms/SuperField';
import NonFieldErrors from '@components/NonFieldErrors';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import { Form, Divider, Grid, Header, Popup, Button, Icon as SemanticIcon } from 'semantic-ui-react';
// other module components
import OrderItemForm from '../../orders_v2/sales/components/OrderItemForm';
import AccountForm from '../../accounts_v2/components/AccountForm';

const isPriceValid = (price) => {
    if (isNaN(price)) return false
    if (price === "") return false
    if (price < 0) return false

    return true
}

const DistancePriceCalculator = ({ transportDetails, setTransportDetails, onClose }) => {
    const { t } = useTranslation()

    const unitPriceInitial = parseFloat(transportDetails.price_without_tax / transportDetails?.distance).toFixed(2)

    const [distance, setDistance] = useState(transportDetails?.distance || "")
    const [unitPrice, setUnitPrice] = useState(isPriceValid(unitPriceInitial) ? unitPriceInitial : "")

    const onSubmit = (e) => {
        e.preventDefault()
    }

    const calculate = () => {
        let total = (parseFloat(unitPrice) * parseFloat(distance))

        setTransportDetails(prev => ({
            ...prev,
            distance: distance,
            price_without_tax: isPriceValid(parseFloat(total.toFixed(2))) ? total.toFixed(2) : ""
        }))

        onClose()
    }

    return (
        <Form onSubmit={onSubmit}>
            <Form.Group widths="equal">
                <SuperField as="input"
                    required
                    autoFocus
                    label={t('unit_price')}
                    value={unitPrice}
                    onChange={(e, { value }) => setUnitPrice(value.replace(",", ".")) }
                />
                <SuperField as="input"
                    required
                    label={t('distance')}
                    value={distance}
                    onChange={(e, { value }) => setDistance(value) }
                />
            </Form.Group>
            <div style={{ marginBottom: "0.5rem" }}> 
                <strong>{ t("total_sum") }:</strong> { thousandsSeparators((parseFloat(unitPrice) * parseFloat(distance)) || 0) } { transportDetails.currency || "EUR" } 
            </div>

            <Divider/>
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose}/>
                <Button 
                    primary
                    type="button"
                    content={t('calculate')}
                    onClick={() => calculate()}
                    disabled={!isPriceValid(unitPrice) || !isPriceValid(distance) }
                />
            </Form.Field>
        </Form>
    )
}

const OrderForm = ({ isDuplicate, customSubmitHandler, stageTitle, stage, setData, setTotal, resourceType, record, isPurchase, onClose, accounts, setAccounts, owners, employees, setHeader }) => {
    const { t } = useTranslation()

    const user = useSelector(state => state.user)

    const canManageCC = useHasPermission('cost_centers.c_manage_cost_centers')

    const [errors, setErrors] = useState(null)
    const [processing, setProcessing] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loadingProcessGroup, setLoadingProcessGroup] = useState(false)
    const [customOrderName, setCustomOrderName] = useState(((record?.name !== "" && record !== undefined) || stageTitle !== "") ? true : false)

    const [costCenters, setCostCenters] = useState([])
    const [catalogue, setCatalogue] = useState([])
    const [loadingCatalogue, setLoadingCatalogue] = useState(false)

    const [processes, setProcesses] = useState([])
    const [processGroup, setProcessGroup] = useState(stage?.process?.id || "")

    const [form, setForm] = useState({
        account: isDuplicate ? "" : isPurchase ? record?.owner?.id || "" : record?.business_detail?.id || "",
        supplier: isDuplicate ? "" : isPurchase ? record?.business_detail?.id || "" : record?.owner?.id || "",
        order_manager: record?.order_manager?.id || "",
        cost_center: record?.cost_center?.id || "",
        order_number: isDuplicate ? "" : record?.order_number || "",
        name: record?.id ? record?.name : (stageTitle || ""),
        // order_reference_number: "",
        assigned_to: record?.assigned_to?.map(item => item.id) || [],
        description: record?.description || "", // can be used as note
        note: record?.note || "", // can be used as note
    })

    const [showMoreLoadingAddress, setShowMoreLoadingAddress] = useState(false)
    const [loadingAddress, setLoadingAddress] = useState({
        street: record?.loading_address?.street || "",
        postcode: record?.loading_address?.postcode || "",
        city: record?.loading_address?.city ||  "",
        country: record?.loading_address?.country || "",
        address_line: record?.loading_address?.address_line || "",
    })
    
    const [showMoreDestinationAddress, setShowMoreDestinationAddress] = useState(false)
    const [destinationAddress, setDestinationAddress] = useState({
        street: record?.destination_address?.street || "",
        postcode: record?.destination_address?.postcode || "",
        city: record?.destination_address?.city ||  "",
        country: record?.destination_address?.country || "",
        address_line: record?.destination_address?.address_line || "",
    })

    const [transportDetails, setTransportDetails] = useState({
        price_without_tax: record?.price?.sum || "",
        currency: record?.price?.currency || "EUR",
        loading_date: record?.loading_date || "",
        delivery_date: record?.fulfillment?.delivery_date || "",
        truck_plate_number: record?.external_truck || "",
        trailer_plate_number: record?.external_trailer || "",
        distance: record?.distance || "",
        contact_person_name: record?.contact_person_name || "",
        contact_person_email: record?.contact_person_email || "",
        contact_person_phone: record?.contact_person_phone || "",
    })
    
    const [items, setItems] = useState(record?.items?.map(recordItem => ({
        id: isDuplicate ? 0 : recordItem?.id || 0,
        item: recordItem?.item.id || "",
        item_title: recordItem?.item ? (recordItem?.item?.code ? recordItem?.item?.code  + " - " : "") + recordItem?.item?.title : "",
        quantity: recordItem?.quantity || 1,
        currency: recordItem?.currency || "EUR",
        price_without_tax: recordItem?.price_without_tax || "",
        price_with_tax: recordItem?.price_with_tax || "",
        tax: recordItem?.tax || "",
        note: recordItem?.note || "",
    })) || [])

    const [HRDetails, setHRDetails] = useState({
        target: record?.amount || 1,
        start_date: record?.start_date || "",
        end_date: record?.end_date || "",
        fee: record?.fee?.sum || "",
        currency: record?.fee?.currency || "EUR"
    })

    useEffect(() => {
        if (owners.length === 1 && record === undefined) {
            if (isPurchase) {
                setForm(prev => ({...prev, account: owners?.[0]?.value || ""}))
            } else {
                setForm(prev => ({...prev, supplier: owners?.[0]?.value || ""}))
            }
        }
        // eslint-disable-next-line
    }, [owners])

    useEffect(() => {
        async function fetchCatalogue() {
            if (resourceType === "SaleOrder") {
                setLoadingCatalogue(true)
                const request = await requests.get(API.CATALOGUE_ITEMS + "?is_archived=false")
                if (request.status === 200) setCatalogue(request.response)
                setLoadingCatalogue(false)
            }
        }

        async function fetchCostCenters() {
            setLoading(true)
            const request = await requests.get(API.COST_CENTERS + "?query={id, title}")
            if (request.status === 200) setCostCenters(request.response)
            setLoading(false)
        }

        async function fetchProcessGroups() {
            setLoadingProcessGroup(true)
            const request = await requests.get(API.ORDERS + "sale_processes/?query={id, title}")
            if (request.status === 200) setProcesses(request.response)
            setLoadingProcessGroup(false)
        }

        fetchCatalogue()
        fetchCostCenters()
        fetchProcessGroups()
        // eslint-disable-next-line
    }, [])

    const handleAddCostCenter = async (value) => {
        setLoading(true)
        const request = await requests.post(API.COST_CENTERS, {
            title: value,
            unit: form.owner?.id || null
        })

        if (request.status === 201) {
            setForm(prev => ({...prev, cost_center: request.response.id}))
            setCostCenters(prev => [...prev, request.response])
        }
        setLoading(false)
    }

    const handleAddProcessGroup = async (value) => {
        setLoadingProcessGroup(true)
        const request = await requests.post(API.ORDERS + "sale_processes/", {
            title: value
        })

        if (request.status === 201) {
            setProcessGroup(request.response.id)
            setProcesses(prev => [...prev, request.response])
        }
        setLoadingProcessGroup(false)
    }

    const isFormValid = () => {
        if (form.account === "" || form.supplier === "") return false

        if (resourceType === "TransportOrder") {
            if (transportDetails.price_without_tax === "") return false
            if (transportDetails.loading_date === "") return false
        }

        if (resourceType === "HROrder") {
            if (transportDetails.target === "") return false
            if (transportDetails.target <= 0) return false
            if (transportDetails.start_date === "") return false
        }

        if (resourceType === "SaleOrder") {
            if (items.length === 0) return false
        }

        return true
    }

    const onDelete = async (item, idx) => {
        if (item.id === 0) {
            setItems(prev => prev.filter((item, index) => index !== idx))
        } else {
            const request = await requests.post(API.ORDERS + "order_items/" + item.id + "/delete/")
            if (request.status === 200) {
                setItems(prev => prev.filter((listItem, index) => listItem.id !== item.id))
            }
        }
    }

    const handleSubmit = async () => {
        setProcessing(true)
        setErrors(null)

        const sharedData = {
            order_number: form.order_number || null,
            name: form.name || stageTitle || "--",
            order_manager: form.order_manager || null,
            cost_center: form.cost_center || null,
            note: form.note,
            is_purchase_order: isPurchase,
            order_type: 2,
            resourcetype: resourceType,
            is_active: true,
            order_status: 1,
            owner: isPurchase ? form.account : form.supplier,
            business_detail: isPurchase ? form.supplier : form.account,
        }

        let orderSpecificData = {}
        if (resourceType === "HROrder") {
            orderSpecificData = {
                amount: HRDetails.target || 1,
                start_date: HRDetails.start_date !== "" ? HRDetails.start_date : null,
                end_date: HRDetails.end_date !== "" ? HRDetails.end_date : null,
                fee: {
                    sum: isPriceValid(HRDetails.fee) ? HRDetails.fee : null,
                    currency: HRDetails.currency
                }
            }
        }

        if (resourceType === "SaleOrder") {
            if (items.length > 0) {
                let toCreate = items.filter(data => data.id === 0)
                let toUpdate = items.filter(data => data.id > 0)
    
                orderSpecificData["items"] = {}
                if(toCreate.length > 0) {
                    orderSpecificData["items"]["create"] = toCreate.map(item => ({
                        item: item.item,
                        quantity: item.quantity,
                        currency: item.currency,
                        price_without_tax: item.price_without_tax || null,
                        total_price: isPriceValid(parseFloat(item.price_without_tax * item.quantity).toFixed(2)) ? parseFloat(item.price_without_tax * item.quantity).toFixed(2) : 0,
                        note: item.note
                    }))
                }
                if(toUpdate.length > 0) {
                    orderSpecificData.items['update'] = {}
                    for (let i = 0; i < toUpdate.length; i++) {
                        orderSpecificData.items['update'][toUpdate[i].id] = {
                            item: toUpdate[i].item,
                            quantity: toUpdate[i].quantity,
                            currency: toUpdate[i].currency,
                            price_without_tax: toUpdate[i].price_without_tax || null,
                            total_price: isPriceValid(parseFloat(toUpdate[i].price_without_tax * toUpdate[i].quantity).toFixed(2)) ? parseFloat(toUpdate[i].price_without_tax * toUpdate[i].quantity).toFixed(2) : 0, 
                            note: toUpdate[i].note
                        }
                    }
                }
            }
        }

        if (resourceType === "TransportOrder") {
            orderSpecificData = {
                loading_address: loadingAddress,
                destination_address: destinationAddress,
                distance: transportDetails.distance,
                contact_person_name: transportDetails.contact_person_name,
                contact_person_email: transportDetails.contact_person_email,
                contact_person_phone: transportDetails.contact_person_phone,
                price: {
                    sum: transportDetails.price_without_tax,
                    currency: transportDetails.currency
                },
                external_truck: transportDetails.truck_plate_number,
                external_trailer: transportDetails.trailer_plate_number,
                loading_date: transportDetails.loading_date !== "" ? transportDetails.loading_date : null ,
            }
        }

        const data = {
            ...sharedData,
            ...orderSpecificData
        }

        // handle data processing and API calls
        const existingOrder = isDuplicate ? undefined : record 
        if (existingOrder === undefined) { // its create request
            if (form.assigned_to.length > 0) data['assigned_to'] = { add: form.assigned_to }

            const request = await requests.post(API.ORDERS, data)
            if (request.status === 400) setErrors(request.response)
            if (request.status === 201) {
                if (resourceType === "TransportOrder") {
                    // create fulfillment if there is unloading_date filled
                    if (transportDetails.delivery_date !== "") {
                        // create fulfillment for created order:
                        await requests.post(API.ORDERS + "fulfillments/", {
                            delivery_date: transportDetails.delivery_date,
                            resourcetype: "TransportFulfillment",
                            note: "",
                            currency: request.response.price.currency,
                            total_fee: request.response.price.sum,
                            order: request.response.id
                        })
                    }
                }

                let response = request.response

                if (customSubmitHandler) {
                    customSubmitHandler(response, processGroup)
                } else {
                    setTotal(prev => prev + 1)
                    setData(prev => ([response, ...prev]))
                    onClose()
                }
            }

        } else { // its update request
            data['assigned_to'] = { remove: record.assigned_to.map(item => item.id), add: form.assigned_to }

            const request = await requests.patch(API.ORDERS + record.id + "/", data)
            if (request.status === 400) setErrors(request.response)
            if (request.status === 200) {
                if (resourceType === "TransportOrder") {
                    // create fulfillment if there is unloading_date filled
                    if (transportDetails.delivery_date !== "") {
                        // create fulfillment for created order:
                        if (request.response.fulfillment === null) {
                            await requests.post(API.ORDERS + "fulfillments/", {
                                delivery_date: transportDetails.delivery_date,
                                resourcetype: "TransportFulfillment",
                                note: "",
                                currency: request.response.price.currency,
                                total_fee: request.response.price.sum,
                                order: request.response.id
                            })
                        } else {
                            await requests.patch(API.ORDERS + "fulfillments/" + request.response.fulfillment.id + "/", {
                                delivery_date: transportDetails.delivery_date,
                                resourcetype: "TransportFulfillment",
                                currency: request.response.price.currency,
                                total_fee: request.response.price.sum,
                            })
                        }
                    }
                }

                if (customSubmitHandler) {
                    customSubmitHandler(request.response, processGroup)
                } else {
                    setData(prev => prev.map(item => {
                        if (item.id === record.id) {
                            item = request.response
                        }
                        return item
                    }))
                    onClose()
                }
            }
        }

        setProcessing(false)
    }
    
    return (
        <Form onSubmit={handleSubmit}>
            { (record !== undefined && isDuplicate !== true) && (
                <>
                    <Header as="h3" content={t('update_order')}/>
                    <Divider/>
                </>
            )}

            <NonFieldErrors errors={errors}/>
            {/* <Message info>
                
            </Message> */}

            <Form.Group widths="equal">
                <SuperField as="input"
                    autoFocus
                    label={t('order_number')}
                    value={form.order_number}
                    error={errors?.order_number?.[0] || false}
                    onChange={(e, { value }) => setForm(prev => ({...prev, order_number: value}))}
                />

                <SuperField as="choice"
                    search
                    label={t('process_group')}
                    value={processGroup}
                    loading={loadingProcessGroup}
                    disabled={loadingProcessGroup}
                    help={t('process_group_of_orders_hint')}
                    allowAdditions={true}
                    onAddItem={(e, { value }) => handleAddProcessGroup(value)}
                    onChange={(e, { value }) => setProcessGroup(value)}
                    customOptions={processes.map(item => ({ key: item.id, value: item.id, text: item.title }))}
                />
            </Form.Group>

            <SuperField as="checkbox"
                label={t('set_custom_order_name')}
                checked={customOrderName}
                onChange={() => setCustomOrderName(!customOrderName)}
            />

            { customOrderName &&
                <SuperField as="input"
                    placeholder={t('custom_order_name')}
                    value={form.name}
                    error={errors?.name?.[0] || false}
                    onChange={(e, { value }) => setForm(prev => ({...prev, name: value}))}
                />
            }

            { isPurchase 
                ? 
                    <Form.Group widths="equal">
                        <Form.Field>
                            <SuperField as="choice"
                                search
                                required
                                value={form.supplier}
                                label={t('supplier')}
                                customOptions={accounts}
                                error={errors?.business_detail?.[0] || false}
                                onChange={(e, { value }) => setForm(prev => ({...prev, supplier: value}))}
                            />
                            <CanView permissions={['accounts.c_manage_all_accounts', 'accounts.c_manage_user_accounts', 'accounts.c_manage_assigned_accounts']}>
                                <SuperDuperModal
                                    size="small"
                                    header={t("create_new_supplier")}
                                    trigger={
                                        <div className="ref-link" style={{ color: "var(--primary)", marginTop: "0.3rem" }}>{t('create_new_supplier')}</div>
                                    }
                                    content={
                                        <AccountForm
                                            handleAddToList={(response, onClose) => {
                                                setAccounts(prev => ([...prev, { key: response.id, value: response.id, text: response.name }]))
                                                setForm(prev => ({...prev, supplier: response.id}))
                                                onClose()
                                            }}
                                            isSupplier={true}
                                        />
                                    }
                                />
                            </CanView>
                        </Form.Field>
                        <SuperField as="choice"
                            search
                            required
                            value={form.account}
                            label={t('account')}
                            customOptions={owners}
                            error={errors?.owner?.[0] || false}
                            onChange={(e, { value }) => setForm(prev => ({...prev, account: value}))}
                        />
                    </Form.Group>
                : 
                    <Form.Group widths="equal">
                        <SuperField as="choice"
                            search
                            required
                            value={form.supplier}
                            label={t('supplier')}
                            customOptions={owners}
                            error={errors?.owner?.[0] || false}
                            onChange={(e, { value }) => setForm(prev => ({...prev, supplier: value}))}
                        />
                        <Form.Field>
                            <SuperField as="choice"
                                search
                                required
                                value={form.account}
                                label={t('account')}
                                customOptions={accounts}
                                error={errors?.business_detail?.[0] || false}
                                onChange={(e, { value }) => setForm(prev => ({...prev, account: value}))}
                            />
                            <CanView permissions={['accounts.c_manage_all_accounts', 'accounts.c_manage_user_accounts', 'accounts.c_manage_assigned_accounts']}>
                                <SuperDuperModal
                                    size="small"
                                    header={t("create_new_account")}
                                    trigger={
                                        <div className="ref-link" style={{ color: "var(--primary)", marginTop: "0.3rem" }}>{t('create_new_account')}</div>
                                    }
                                    content={
                                        <AccountForm
                                            handleAddToList={(response, onClose) => {
                                                setAccounts(prev => ([...prev, { key: response.id, value: response.id, text: response.name }]))
                                                setForm(prev => ({...prev, account: response.id}))
                                                onClose()
                                            }}
                                            isSupplier={false}
                                        />
                                    }
                                />
                            </CanView>
                        </Form.Field>
                    </Form.Group>
            }

            <Form.Group widths="equal">
                <CanView permissions={["orders.c_assign_order_manager"]}>
                    <Form.Field>
                        <SuperField as="choice"
                            search
                            label={t('order_manager')}
                            value={form.order_manager}
                            customOptions={employees}
                            error={errors?.order_manager?.[0] || false}
                            onChange={(e, { value }) => setForm(prev => ({...prev, order_manager: value}))}
                        />
                        { user.profile?.id && 
                            <SuperField as="checkbox"
                                style={{ marginTop: "1rem" }}
                                label={t('assign_me_as_order_manager')}
                                checked={form.order_manager === user.profile?.id}
                                onChange={() => {
                                    if (form.order_manager !== user.profile?.id) {
                                        setForm(prev => ({
                                            ...prev,
                                            order_manager: user.profile?.id || "",
                                        }))
                                    } else {
                                        setForm(prev => ({ ...prev, order_manager: ""}))
                                    }
                                }}
                            />
                        }
                    </Form.Field>
                </CanView>
                <SuperField as="choice"
                    search
                    loading={loading}
                    label={t('cost_center')}
                    value={form.cost_center}
                    disabled={loading}
                    allowAdditions={canManageCC}
                    onAddItem={(e, { value }) => handleAddCostCenter(value)}
                    onChange={(e, { value }) => setForm(prev => ({...prev, cost_center: value}))}
                    customOptions={costCenters.map(item => ({ key: item.id, value: item.id, text: item.title }))}
                />
            </Form.Group>

            <Form.Group widths="equal">
                <SuperField as="choice"
                    search
                    multiple
                    label={t('responsible_persons')}
                    value={form.assigned_to}
                    customOptions={employees}
                    onChange={(e, { value }) => setForm({ ...form, assigned_to: value })}
                />
            </Form.Group>

            <SuperField as="textarea"
                label={t('note')}
                value={form.note}
                onChange={(e, { value }) => setForm({ ...form, note: value })}
            />

            {/* specific order details */}
            { resourceType === "TransportOrder" && 
                <>
                    <Form.Group widths="equal">
                        <SuperField as="datepicker"
                            required
                            label={t('loading_date')}
                            value={transportDetails.loading_date}
                            error={errors?.loading_date?.[0] || false}
                            onChange={(e, { value }) => setTransportDetails(prev => ({...prev, loading_date: value}))}
                        />
                        <SuperField as="datepicker"
                            label={t('unloading_date')}
                            value={transportDetails.delivery_date}
                            error={errors?.delivery_date?.[0] || false}
                            onChange={(e, { value }) => setTransportDetails(prev => ({...prev, delivery_date: value}))}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <SuperField as="input"
                            required
                            label={t('price_without_tax')}
                            value={transportDetails.price_without_tax}
                            error={errors?.price?.sum?.[0] || false}
                            icon={
                                <SuperDuperModal 
                                    size="tiny"
                                    header={t('hint_calculate_price_from_distance')}
                                    style={{ marginTop: "2rem" }}
                                    trigger={
                                        <SemanticIcon name="calculator" link>
                                            <Popup
                                                trigger={
                                                    <SemanticIcon style={{ paddingTop: "0.8rem", paddingLeft: "0.3rem", opacity: 0 }} name='calculator'/>
                                                }
                                                content={t('hint_calculate_price_from_distance')}
                                            />
                                        </SemanticIcon>
                                    }
                                    content={
                                        <DistancePriceCalculator 
                                            transportDetails={transportDetails}
                                            setTransportDetails={setTransportDetails}
                                        />
                                    }
                                />
                            }
                            onChange={(e, { value }) => setTransportDetails({ ...transportDetails, price_without_tax: setDotSeparator(value) })}
                        />
                        <SuperField as="choice"
                            search
                            required
                            type="currency_codes"
                            label={t('currency')}
                            value={transportDetails.currency}
                            error={errors?.price?.currency?.[0] || false}
                            onChange={(e, { value }) => setTransportDetails({ ...transportDetails, currency: value })}
                        />
                    </Form.Group>

                    <Header as="h3" content={t('route')}/>
                    <Divider/>

                    <Grid stackable>
                        <Grid.Row columns={2}>
                            <Grid.Column style={{ paddingLeft: 0 }}>
                                <Header as="h4" content={t('loading_address')}/>
                                <Divider/>
                                <Form.Group widths="equal" style={{ marginBottom: "0.5rem" }}>
                                    <SuperField as="input"
                                        label={t('city')}
                                        placeholder={t('city')}
                                        value={loadingAddress.city}
                                        onChange={(e, { value }) => setLoadingAddress(prev => ({
                                            ...prev,
                                            city: value
                                        }))}
                                    />
                                    <SuperField as="choice"
                                        search
                                        type="countries"
                                        label={t('country')}
                                        value={loadingAddress.country}
                                        onChange={(e, { value }) => setLoadingAddress(prev => ({
                                            ...prev,
                                            country: value
                                        }))}
                                    />
                                </Form.Group>
    
                                <div 
                                    className="ref-link"
                                    style={{ 
                                        color: "var(--primary)",
                                        marginBottom: "0.5rem"
                                    }}
                                    onClick={() => setShowMoreLoadingAddress(!showMoreLoadingAddress)}
                                >
                                    { showMoreLoadingAddress ? t('hide_address_details') : t('add_address_details') }
                                </div>
                                
                                { showMoreLoadingAddress && 
                                    <>
                                        <Form.Group>
                                            <SuperField as="input"
                                                width="10"
                                                placeholder={t('street')}
                                                value={loadingAddress.street}
                                                onChange={(e, { value }) => setLoadingAddress(prev => ({
                                                    ...prev, street: value
                                                }))}
                                            />
                                            <SuperField as="input"
                                                width="6"
                                                placeholder={t('postcode')}
                                                value={loadingAddress.postcode}
                                                onChange={(e, { value }) => setLoadingAddress(prev => ({
                                                    ...prev,
                                                    postcode: value
                                                }))}
                                            />
                                        </Form.Group>
                                        <SuperField as="input"
                                            placeholder={t('additional_information')}
                                            value={loadingAddress.address_line}
                                            onChange={(e, { value }) => setLoadingAddress(prev => ({
                                                ...prev,
                                                address_line: value
                                            }))}
                                        />
                                    </>
                                }
    
    
                            </Grid.Column>
    
                            <Grid.Column style={{ paddingRight: 0 }}>
                                <Header as="h4" content={t('unloading_address')}/>
                                <Divider/>
                                <Form.Group widths="equal" style={{ marginBottom: "0.5rem" }}>
                                    <SuperField as="input"
                                        label={t('city')}
                                        placeholder={t('city')}
                                        value={destinationAddress.city}
                                        onChange={(e, { value }) =>
                                            setDestinationAddress(prev => ({
                                                ...prev,
                                                city: value
                                            }))
                                        }
                                    />
                                    <SuperField as="choice"
                                        search
                                        label={t('country')}
                                        type="countries"
                                        value={destinationAddress.country}
                                        onChange={(e, { value }) =>
                                            setDestinationAddress(prev => ({
                                                ...prev,
                                                country: value
                                            }))
                                        }
                                    />
                                </Form.Group>
    
                                <div 
                                    className="ref-link"
                                    style={{ 
                                        color: "var(--primary)",
                                        marginBottom: "0.5rem"
                                    }}
                                    onClick={() => setShowMoreDestinationAddress(!showMoreDestinationAddress)}
                                >
                                    { showMoreDestinationAddress ? t('hide_address_details') : t('add_address_details') }
                                </div>
    
                                { showMoreDestinationAddress && 
                                    <>
                                        <Form.Group>
                                            <SuperField as="input"
                                                width="10"
                                                placeholder={t('street')}
                                                value={destinationAddress.street}
                                                onChange={(e, { value }) =>
                                                    setDestinationAddress(prev => ({
                                                        ...prev,
                                                        street: value
                                                    }))
                                                }
                                            />
                                            <SuperField as="input"
                                                width="6"
                                                placeholder={t('postcode')}
                                                value={destinationAddress.postcode}
                                                onChange={(e, { value }) =>
                                                    setDestinationAddress(prev => ({
                                                        ...prev,
                                                        postcode: value
                                                    }))
                                                }
                                            />
                                        </Form.Group>
                                        
                                        <SuperField as="input"
                                            placeholder={t('additional_information')}
                                            value={destinationAddress.address_line}
                                            onChange={(e, { value }) => setDestinationAddress(prev => ({
                                                ...prev,
                                                address_line: value
                                            }))}
                                        />
                                    </>
                                }
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                    <SuperField as="input"
                        name="distance"
                        label={t('distance')}
                        value={transportDetails.distance}
                        onChange={(e, { value }) => setTransportDetails(prev => ({...prev, distance: value}))}
                    />

                    <Header as="h3" content={t('vehicle')}/>
                    <Divider/>

                    <Form.Group widths={"equal"}>
                        <SuperField as="input"
                            label={t('truck_plate_number')}
                            value={transportDetails.truck_plate_number}
                            error={errors?.external_truck?.[0] || false}
                            onChange={(e, { value }) => setTransportDetails(prev => ({...prev, truck_plate_number: value}))}
                        />
                        <SuperField as="input"
                            label={t('trailer_plate_number')}
                            value={transportDetails.trailer_plate_number}
                            error={errors?.external_trailer?.[0] || false}
                            onChange={(e, { value }) => setTransportDetails(prev => ({...prev, trailer_plate_number: value}))}
                        />
                    </Form.Group>

                    <SuperField as="input"
                        label={t('contact_person')}
                        value={transportDetails.contact_person_name}
                        error={errors?.contact_person_name?.[0] || false}
                        onChange={(e, { value }) => setTransportDetails(prev => ({...prev, contact_person_name: value}))}
                    />
                    <Form.Group widths={"equal"}>
                        <SuperField as="input"
                            label={t('email')}
                            value={transportDetails.contact_person_email}
                            error={errors?.contact_person_email?.[0] || false}
                            onChange={(e, { value }) => setTransportDetails(prev => ({...prev, contact_person_email: value}))}
                        />
                        <SuperField as="input"
                            label={t('phone')}
                            value={transportDetails.contact_person_phone}
                            error={errors?.contact_person_phone?.[0] || false}
                            onChange={(e, { value }) => setTransportDetails(prev => ({...prev, contact_person_phone: value}))}
                        />
                    </Form.Group>
                </>
            }

            { resourceType === "HROrder" && 
                <>
                    <Form.Group widths='equal'>
                        <SuperField as="datepicker"
                            required
                            label={t('start_date')}
                            value={HRDetails.start_date}
                            error={errors?.start_date?.[0] || false}
                            onChange={(e, { value }) => setHRDetails({ ...HRDetails, start_date: value })}
                        />
                        <SuperField as="datepicker"
                            label={t('end_date')}
                            value={HRDetails.end_date}
                            error={errors?.end_date?.[0] || false}
                            onChange={(e, { value }) => setHRDetails({ ...HRDetails, end_date: value })}
                        />
                    </Form.Group>

                    <Form.Group widths="equal">
                        <SuperField as="input"
                            min="0"
                            required
                            type="number"
                            label={t('target')}
                            value={HRDetails.target}
                            error={errors?.amount?.[0] || false}
                            onChange={(e, { value }) => setHRDetails({ ...HRDetails, target: value })}
                        />
                        <SuperField as="input"
                            label={t('fee')}
                            value={HRDetails.fee}
                            error={errors?.fee?.[0] || false}
                            onChange={(e, { value }) => setHRDetails({ ...HRDetails, fee: setDotSeparator(value) })}
                        />
                        <SuperField as="choice"
                            search
                            type="currency_codes"
                            label={t('currency')}
                            value={HRDetails.currency}
                            error={errors?.fee?.[0] || false}
                            onChange={(e, { value }) => setHRDetails({ ...HRDetails, currency: value })}
                        />
                    </Form.Group>
                </>
            }

            { resourceType === "SaleOrder" && 
                <>
                    <Divider/>

                    {/* <Header as="h3" content={t('ordered_items')} style={{ marginBottom: "0.5rem" }}/> */}
                    <Form.Group style={{ fontWeight: "bold" }}>
                        <Form.Field width="10">
                            { t('item') }
                        </Form.Field>
                        <Form.Field width="5">
                            { t('unit_price') }
                        </Form.Field>
                        <Form.Field width="5">
                            { t('quantity') }
                        </Form.Field>
                        <Form.Field width="4">
                            { t('price_without_tax') }
                        </Form.Field>
                        <Form.Field width="5">
                            { t('note') }
                        </Form.Field>
                        <Form.Field width="2" style={{ textAlign: "center" }}>
                            { t('actions') }
                        </Form.Field>
                    </Form.Group>
                    <Divider/>

                    { items.length === 0 && 
                        <>
                            <p style={{ textAlign: "center" }}>
                                { t('no_item_has_been_added_yet') }
                            </p>
                            <Divider/>
                        </>
                    }
                    { items.map((item, idx) => (
                        <>
                            <Form.Group textAlign="middle">
                                <Form.Field width="10">
                                    { item?.item_title }
                                </Form.Field>
                                <Form.Field width="5">
                                    { item.price_without_tax }  { item.currency } 
                                </Form.Field>
                                <Form.Field width="5">
                                    { item.quantity }
                                </Form.Field>
                                <Form.Field width="4">
                                    { thousandsSeparators((item.price_without_tax * item.quantity) || 0) } { item.currency || "EUR" }
                                </Form.Field>
                                <Form.Field width="5">
                                    { item.note && 
                                        <Popup
                                            position="center top"
                                            trigger={
                                                <Icon name="document-outline" style={{ marginLeft: "0.5rem" }}/>
                                            }
                                            content={
                                                <div>
                                                    { item.note }
                                                </div>
                                            }
                                        />
                                    }
                                </Form.Field>
                                <Form.Field width="2" style={{ textAlign: "center" }}>
                                    <SuperDuperModal
                                        trigger={
                                            <Icon name="pencil-outline" style={{ color: "var(--dark)", cursor: "pointer", marginRight: "0.5rem" }}/>
                                        }
                                        content={<OrderItemForm catalogue={catalogue} setRecords={setItems} record={item} idx={idx}/>}
                                    />
                                    <Icon
                                        name="close-outline"
                                        style={{ 
                                            color: "var(--danger)",
                                            cursor: "pointer"
                                        }}
                                        onClick={() => onDelete(item, idx)}
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Divider/>
                        </>
                    ))}
                    <SuperDuperModal
                        trigger={
                            <Button disabled={loadingCatalogue} primary type="button" size="tiny" content={t('add_item')}/>
                        }
                        content={<OrderItemForm setRecords={setItems} catalogue={catalogue}/>}
                    />
                </>
            }
            
            <Divider/>
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose}/>
                <ModalSubmit
                    text={t('confirm')}
                    loading={processing}
                    disabled={processing || !isFormValid()}
                />
            </Form.Field>
        </Form>
    );
};

export default OrderForm;