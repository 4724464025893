import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import { useHistory, useParams } from "react-router-dom";
import { routes } from '@routes/index';
// store
import { API } from '@store/config';
import { icons } from '@store/icons';
import { useQueryPage } from '@helpers/hooks';
import { requests } from '@helpers/requests';
// components
import Icon from '@components/Icon';
import BreadcrumbNav from '@components/BreadcrumbNav';
import { Container, Grid, Sidebar, Segment, Menu, Button, Input, Ref } from 'semantic-ui-react';
import Loading from '@components/general/Loading';
import CanView from '@components/perms/CanView';
import { FlexItem } from '@components/tables/FlexTable';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import Paginator from '@components/Paginator';
import Action from '@components/general/Action';
// module specific
import IssueTableView from '../components/detail/IssueTableView';
import ProjectDetailStatHeader from '../components/detail/ProjectDetailStatHeader';
import ManagementTab from '../components/detail/ManagementTab';

import IssueFiltersForm from '../components/forms/IssueFiltersForm';
import IssueCreateForm from '../components/forms/IssueCreateForm';


const DetailView = () => {
    let page = useQueryPage()
    const { t } = useTranslation();
    const history = useHistory()
    const project_params = useParams();
    const segmentRef = useRef()
    const location = useLocation()
    const [visible, setVisible] = useState(false)
    const [search, setSearch] = useState([])
    const [view, setView] = useState(0)
    const [project, setProject] = useState({})
    const [statusView, setStatusView] = useState(0)
    const [managementPageView, setManagementPageView] = useState(0)
    const [limit, setLimit] = useState(10)
    const [issues, setIssues] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [readOnly, setReadOnly] = useState(true)


    useEffect(() => {
        async function fetchData() {
            setIsLoading(true)
            const request = await requests.get(API.PROJECTS + project_params.id + '/');

            if (request.status === 403) {
                history.push(routes.DASHBOARD)
                return
            }

            if (request.status === 200) {
                setProject(request.response);
            }
            setIsLoading(false);
        };

        fetchData()

        // eslint-disable-next-line
    }, []);


    const initState = {
        startDate: "",
        endDate: "",
        milestone: "",
        stage: "",
        group: "",
        state: "",
        onlyCurrentUser: "",
        labels: [],
        assigned: [],
        backlog: ""
    }
    const [filters, setFilters] = useState(initState)


    const setParams = () => {
        let params = "&search=" + search
        if( filters.startDate !== "") params += `&start_date=${filters.startDate}`
        if( filters.endDate !== "") params += `&end_date=${filters.endDate}`
        if( filters.milestone !== "") params += `&milestone=${filters.milestone}`
        if( filters.stage !== "") params += `&stage=${filters.stage}`
        if( filters.group !== "") params += `&group=${filters.group}`
        if( filters.state !== "") params += `&state=${filters.state}`
        if( filters.onlyCurrentUser !== "") params += `&only_current_user=${filters.onlyCurrentUser}`
        if( filters.backlog !== "") params += `&backlog=${filters.backlog}`

        if( filters.assigned.length > 0 ){
            for (let i = 0; i < filters.assigned.length; i++) {
                params += "&assigned="+ filters.assigned[i]
            }
        }

        if( filters.labels.length > 0 ){
            for (let i = 0; i < filters.labels.length; i++) {
                params += "&labels="+ filters.labels[i]
            }
        }

        return params
    }

    const fetchData = async (params, isFiltered) => {
        params = params || ""
        params += setParams()
        if (isFiltered) {
            page = 1
        } else {
            let searchParams = new URLSearchParams(location.search)
            const param = searchParams.get('page')
            page = (param !== null ? param : 1)
        }
        if ( !params.includes('page') ) {
            params += "&page=" + page
        }

        if ( !params.includes('limit')){
            params += "&limit=" + limit
        }

        setIsLoading(true)
        const request = await requests.get(API.PROJECT_ISSUES + `?paginate=true&project=${project_params.id}&is_template=false` + params)
        if (request.status === 200) {
            setIssues({
                ...issues,
                data: request.response
            })
        }
        setIsLoading(false)

    }

        useEffect(() => {
            fetchData('', true)
        // eslint-disable-next-line
    }, [search, statusView])

    const onCreate = (response) => {
        setIssues(prevState => ({
            ...prevState,
            data: {
                ...prevState.data,
                count: prevState.data.count + 1,
                results: [response, ...prevState.data.results]
            }
        }))
    }

    return (
        <CanView permissions={['projects.c_view_all_projects', 'projects.c_view_user_projects']} redirect>
        <BreadcrumbNav items={[
            { 'name': t("project"), 'icon': icons.PROJECTS, 'href': routes.PROJECT_LIST },
            { 'name': project?.name, 'icon': '', 'href': ""  }
        ]} />
        <Ref innerRef={segmentRef} >
        <Container fluid >
        <Grid>
            <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                    <ProjectDetailStatHeader project_params={project_params} />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={16}>
            <Sidebar.Pushable as={Segment} style={{ border: "none", background: "transparent", boxShadow: "none", padding: 0, margin: 0 }} className="fixed-sidebar">
                <Sidebar
                    style={{ width: "40rem", }}
                    as={Menu}
                    animation='overlay'
                    direction='right'
                    icon='labeled'
                    vertical
                    visible={visible}
                    onHide={() => setVisible(false)}
                    width='wide'
                    target={segmentRef}
                >
                <IssueFiltersForm
                    setVisible={setVisible}
                    setIssues={setIssues}
                    fetchData={fetchData}
                    initState = {initState}
                    filters={filters}
                    setFilters={setFilters}
                    search={search}
                    project_id={project_params.id}
                />
                </Sidebar>
                <Sidebar.Pusher>
                    <Grid>
                    <Grid.Row>
                        <Grid.Column style={{ marginTop: '5px', marginBottom: '5px' }} mobile={16} tablet={8} computer={7}>
                        { view !== 2 ?
                            <Button.Group basic size="small" style={{ borderRadius: "0" }}>
                                <Button active={statusView === 0} content={t('all')} onClick={() => {
                                    setStatusView(0)
                                    setFilters({
                                        ...filters,
                                        state: "",
                                        // backlog: ""
                                    })
                                }} />
                                <Button active={statusView === 1} content={t('open')} onClick={() => {
                                    setStatusView(1)
                                    setFilters({
                                        ...filters,
                                        state: 1,
                                        // backlog: ""
                                    })
                                }} />
                                <Button active={statusView === 2} content={t('closed')} onClick={() => {
                                    setStatusView(2)
                                    setFilters({
                                        ...filters,
                                        state: 2,
                                        // backlog: ""
                                    })
                                }} />
                                {/* <Button active={statusView === 3} content={t('backlog')} onClick={() => {
                                    setStatusView(3)
                                    setFilters({
                                        ...filters,
                                        state: "",
                                        backlog: true
                                    })
                                }} /> */}
                            </Button.Group>
                            : view !== 3 &&
                            <Button.Group basic size="small" style={{ borderRadius: "0" }}>
                                <Button active={managementPageView === 0} content={t('milestones')} onClick={() => {
                                    setManagementPageView(0)
                                }} />
                                <Button active={managementPageView === 1} content={t('settings')} onClick={() => {
                                    setManagementPageView(1)
                                }} />
                                <Button active={managementPageView === 2} content={t('members')} onClick={() => {
                                    setManagementPageView(2)
                                }} />
                                <Button active={managementPageView === 3} content={t('files')} onClick={() => {
                                    setManagementPageView(3)
                                }} />
                                <Button active={view === 0} content={t('issues')} onClick={() => {
                                    setView(0)
                                }} />
                            </Button.Group>
                        }
                        </Grid.Column>
                        <Grid.Column style={{ marginTop: '5px', marginBottom: '5px' }} textAlign='right' mobile={16} tablet={8} computer={4}>
                        { view !== 2 && view !== 3 &&
                            <Input size="small" icon='search' value={search} onChange={(e) => setSearch(e.target.value)} placeholder={t('search')} style={{ width: "100%" }} />
                        }
                        </Grid.Column>
                        <Grid.Column style={{ marginTop: '5px', marginBottom: '5px' }} textAlign='right' mobile={16} tablet={16} computer={5}>
                            {/* <Action as="custom" type="icon" icon="chart pie" tooltip={t('statistics')}
                                onClick={() => setView(3)}
                                size="medium"
                                iconColor = "var(--black)"
                                disabled={view===3}
                                paddingRight="1rem"
                                paddingLeft="1rem"
                            /> */}
                            <Action as="custom" type="icon" icon="settings-outline" tooltip={t('management')}
                                onClick={() => setView(2)}
                                size="medium"
                                iconColor = "var(--black)"
                                disabled={view===2}
                                paddingRight="1rem"
                                paddingLeft="1rem"
                            />
                            {/* <Action as="custom" type="icon" icon="sitemap" tooltip={t('kanban_view')}
                                onClick={() => setView(1)}
                                size="medium"
                                iconColor = "var(--black)"
                                disabled={view===1}
                                paddingRight="1rem"
                                paddingLeft="1rem"
                            /> */}
                            <Action as="custom" type="icon" icon="list-outline" tooltip={t('table_view')}
                                onClick={() => setView(0)}
                                size="medium"
                                iconColor = "var(--black)"
                                disabled={view===0}
                                paddingRight="1rem"
                                paddingLeft="1rem"

                            />

                            { view !== 2 && view !== 3 &&
                            <Button
                                size="medium"
                                style={{
                                background: "transparent",
                                color: "var(--black)",
                                paddingLeft: "1rem",
                                paddingRight: "1rem"
                                }}
                                onClick={() => setVisible(!visible)}
                            >
                                <Icon name='funnel-outline'/>
                            </Button>
                            }

                            { view !== 2 && view !== 3 &&
                            <CanView permissions={['projects.c_manage_issues']}>
                            <FlexItem>
                                <SuperDuperModal
                                    header={t('create_issue')}
                                    size="large"
                                    trigger={
                                        <Button size="small" primary>{t('create_issue')}</Button>
                                    }
                                    content={
                                        <IssueCreateForm projectParams={project_params} onConfirm={(response, type) => onCreate(response, type)} />
                                    }
                                />
                            </FlexItem>
                            </CanView>
                            }
                        </Grid.Column>
                    </Grid.Row>
                    </Grid>
                    <Grid.Row>
                        <Grid.Column mobile={16} tablet={16} computer={16}>
                            {view === 0 && isLoading ? <Loading /> :
                                <CanView permissions={['projects.c_view_issues']}>
                                    {view === 0 && <IssueTableView readOnly={readOnly} setReadOnly={setReadOnly} issues={issues} setIssues={setIssues} />}
                                </CanView>
                            }
                            {view === 1 && isLoading ? <Loading /> :
                                <>
                                    {view === 1 && <></>}
                                </>
                            }
                            {view === 2 && isLoading ? <Loading /> :
                                <>
                                    {view === 2 && <ManagementTab managementPageView={managementPageView} project={project} setProject={setProject} />}
                                </>
                            }
                            { issues?.data?.count > 10 & view !== 2 & view !== 3 ?
                            <Paginator
                                forcePage={page || 1}
                                limit={limit}
                                setLimit={setLimit}
                                length={issues?.data?.count || 0}
                                onChange={(params) => fetchData(params)}
                            /> : <></>
                            }
                        </Grid.Column>
                    </Grid.Row>
                </Sidebar.Pusher>
            </Sidebar.Pushable>
            </Grid.Column>

            </Grid.Row>
        </Grid>
        </Container>
        </Ref>
    </CanView>
    )
}

export default DetailView
