import React from 'react';
import { Redirect } from 'react-router-dom';
import { routes } from '../../store/routes/index';
import { useHasPermissions } from '@helpers/hooks';
// components
import CanAccess from '@components/perms/CanAccess';

const CanView = ({ permissions, modules, children, redirect, route, render, ...rest }) => {

    permissions = permissions || [];

    let canRender = useHasPermissions(permissions);
    if (permissions === undefined || permissions.length === 0) {
        canRender = true
    } else {
        canRender = render ? render : canRender
    }

    return (
        <CanAccess allowedModules={modules}>
            {canRender ? children : redirect && <Redirect to={route || routes.DASHBOARD} />}
        </CanAccess>
    )
}

export default CanView;