import React from 'react';
import { useTranslation } from 'react-i18next';
// components 
import { FlexRow, FlexItem } from '@components/tables/FlexTable';

const EmptyRow = ({ length, align }) => {
    const { t } = useTranslation()

    align = align || "center"

    return (
        <>
            { length === 0 &&
                <FlexRow fontSize="1rem" borders>
                    <FlexItem bold textAlign={align}>
                        { t('no_data') }
                    </FlexItem>
                </FlexRow>
            }
        </>
    )
}

export default EmptyRow;