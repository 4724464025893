import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// components
import { Form, Container, Button } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';

const TransactionForm = ({ payment, bankAccount, index, onTransactionChange, onTransactionRemove }) => {
    const { t } = useTranslation()

    useEffect( () => {
        let endToEndReference = "/VS" + (payment.variable_symbol || "") + "/SS" + (payment.specific_symbol || "") + "/KS" + (payment.constant_symbol || "")
        onTransactionChange(index, endToEndReference, "end_to_end_reference")

        // eslint-disable-next-line
    }, [payment.variable_symbol, payment.constant_symbol, payment.specific_symbol])

    const normalizeValue = (value) => {
        if (value === null) return ""
        if (value === "") return ""
        if (value === undefined) return ""

        value = value.normalize("NFD").replace(/\p{Diacritic}/gu, "") || ""
        value = value.replace(".", "") || ""
        value = value.replace("-", "") || ""
        value = value.replace(";", "") || ""
        value = value.replace("/", "") || ""
        value = value.replace("=", "") || ""
        value = value.replace("%", "") || ""
        value = value.replace("{", "") || ""
        value = value.replace("}", "") || ""
        value = value.replace("@", "") || ""
        value = value.replace("`", "") || ""
        value = value.replace("~", "") || ""

        return value
    }

    return (
        <Container fluid style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
            <Form.Group widths="equal">
                <SuperField as="input"
                    required
                    size="small"
                    label={t('receiver_name')}
                    value={payment.receiver_name}
                    onChange={(e, { value }) => onTransactionChange(index, normalizeValue(value), "receiver_name")}
                />
                <SuperField as="input"
                    required
                    size="small"
                    label={t('iban')}
                    value={payment.receiver_iban}
                    error={bankAccount.accountReference.iban === payment.receiver_iban ? t('invalid_receiver_iban_same_as_debtor') : false }
                    onChange={(e, { value }) => onTransactionChange(index, value?.replaceAll(' ', '') || "", "receiver_iban")}
                />
            </Form.Group>
            <Form.Group widths="equal">
                <SuperField as="input"
                    required
                    size="small"
                    label={t('bic')}
                    value={payment.receiver_bic}
                    onChange={(e, { value }) => onTransactionChange(index, value, "receiver_bic")}
                />
                <SuperField as="input"
                    required
                    size="small"
                    label={t('sum')}
                    value={payment.amount}
                    error={isNaN(payment.amount) ? "Not a number!" : false}
                    onChange={(e, { value }) => onTransactionChange(index, value?.replace(",", "."), "amount")}
                />
            </Form.Group>
            <Form.Group widths="equal">
                <SuperField as="input"
                    size="small"
                    label={t('variable_symbol')}
                    value={payment.variable_symbol}
                    onChange={(e, { value }) => onTransactionChange(index, value, "variable_symbol")}
                />
                <SuperField as="input"
                    size="small"
                    label={t('specific_symbol')}
                    value={payment.specific_symbol}
                    onChange={(e, { value }) => onTransactionChange(index, value, "specific_symbol")}
                />
                <SuperField as="input"
                    size="small"
                    label={t('constant_symbol')}
                    value={payment.constant_symbol}
                    onChange={(e, { value }) => onTransactionChange(index, value, "constant_symbol")}
                />
            </Form.Group>
            <SuperField as="textarea"
                size="small"
                label={t('note')}
                value={payment.receiver_note}
                onChange={(e, { value }) => onTransactionChange(index, value, "receiver_note")}
            />

            <Form.Field style={{ textAlign: "right" }}>
                <Button type="button" style={{ color: "var(--white)", background: "var(--danger)" }} size="small" content={t('remove')} onClick={() => onTransactionRemove(index, (payment?.id || 0))}/>
            </Form.Field>
        </Container>
    )
}

export default TransactionForm;