import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
// components
import Icon from '@components/Icon';
import { Popup } from 'semantic-ui-react';
import SuperDuperModal from '@components/modals/SuperDuperModal';
// module specific components
import PaymentVerification from './forms/PaymentVerification'

const InvoiceStatus = ({ invoice, setData, connection, canManage }) => {
    const { t } = useTranslation()

    return (
        <>
            <Icon
                name="ellipse"
                style={{
                    marginRight: "0.3rem",
                    color: 
                    invoice.status === 1
                        ? "var(--iceblue)"
                        : invoice.status === 3
                            ? "green"
                            : invoice.status === 4
                            ? "blue"
                            : ""
                }}
            />

            <span style={{ position: "relative", top: "-0.2rem" }}>
                { invoice.status === 2 && t('cancelled') }
                { invoice.status === 4 && t('draft') }
                { invoice.status === 1 &&
                    <>
                    { t('pending') }
                        { moment(invoice.date_due, "YYYY-MM-DD").isBefore(moment().format("YYYY-MM-DD"))
                            ?
                                <Popup
                                    position='right center'
                                    trigger={
                                        <Icon name="alert-outline" style={{ color: "var(--danger)", cursor: "pointer", marginLeft: "0.5rem" }}/>
                                    }
                                    content={ <span style={{ position: "relative", top: "-0.2rem" }}>{ t('past_due') }</span> }
                                />
                            : ""
                        }
                    </>
                }
                { invoice.status === 3 && t('paid') }

                { invoice.status === 3 && invoice.date_paid !== null
                    ?
                    <div style={{ marginTop: "0.5rem", marginLeft: "0rem", fontSize: "0.9rem" }}>
                        { invoice.payment_is_approved &&
                            <Popup content={t("payment_verified")} position='top center' trigger={
                                <Icon name="checkmark-circle" style={{ color: "var(--dark)", cursor: "pointer", marginRight: "0.3rem" }}/>
                            }/>
                        }
                        <strong style={{ position: "relative", top: "-0.1rem" }}>{ moment(invoice.date_paid).format("DD.MM.YYYY") }</strong>
                    </div>
                    :
                    <>
                        { (canManage && invoice.status !== 2) &&
                            <div style={{ marginTop: "0.2rem" }}>
                                <SuperDuperModal
                                    size="tiny"
                                    trigger={
                                        <small style={{ color: "var(--primary)" }} className="ref-link"> {t('verify_payment')} </small>
                                    }
                                    content={
                                        <PaymentVerification invoice={invoice} setInvoices={setData} connection={connection}/>
                                    }
                                />
                            </div>
                        }
                    </>
                }
            </span>
        </>
    );
};

export default InvoiceStatus;