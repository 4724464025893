import React from 'react';
import { useTranslation } from "react-i18next";
// store
import { routes } from '@routes';
import { API } from '@store/config';
// components
import ListView from '@components/ListView';
// module specific components
import LaunchNewRoomForm from '../components/forms/LaunchNewRoomForm';
import HiringRoomCard from './HiringRoomCard';

const HiringRoomListView = () => {
    const { t } = useTranslation();

    return (
        <ListView
            as="card"
            allowSearch
            searchKey="name"
            query={"&query={id, job_position, candidate_count, approval, date_from, date_to, is_active, name, target, hired_candidate_count}&is_active=true"}
            endpoint={API.HIRING_ROOMS}
            settings={[
                {
                    permissions: [],
                    as: "link",
                    linkTo: routes.HIRING_PROCESSES,
                    name: t('manage_hiring_processes'),
                    icon: "settings-outline"
                },
            ]}
            actions={[
                {
                    as: "modal",
                    type: "add",
                    name: t('launch_new_room'),
                    modal: <LaunchNewRoomForm btnName={t('launch')} />,
                    permissions: ['candidates.c_manage_recruitment'],
                },
                {
                    as: "link",
                    type: "detail",
                    icon: "eye-outline",
                    name: t('view_card'),
                    redirect: (item) => routes.HIRING_ROOMS + item.id,
                    permissions: ['candidates.c_view_recruitment']
                }
            ]}
            listAdditionActions={(data, setData, total, setTotal, response) => ([
                {
                    as: "filter",
                    index: 0,
                    name: t('active'),
                    filters: {
                        is_active: true,
                    },
                    // count: response.count || 0
                },
                {
                    as: "filter",
                    index: 1,
                    name: t('closed'),
                    filters: {
                        is_active: false,
                    },
                    // count: response.count || 0
                },
            ])}
            itemsPerRow={4}
            renderCard={(data, setData, setTotal, actions) => (
                <>
                    { data?.map(item => (
                        <HiringRoomCard
                            key={item.id}
                            item={item}
                            actions={actions}
                            setData={setData}
                            setTotal={setTotal}
                        />
                    ))}
                </>
            )}
        />
    );
};

export default HiringRoomListView;