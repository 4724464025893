import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// components
import { Button, Divider, Form } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import { API } from '@store/config';
import { createRequest, updateRequest } from '@store/services/ServiceCommon';
import BackTo from '../../../../courses/components/BackTo';
import { useSelectOptions } from '../../../../../../../store/services/helpers/hooks';

const ExternalPublicatonForm = ({
    externalPublication,
    offer,
    setExternalPublications,
    setViewMode,
    onClose,
    externalPublications,
    setOnPublicationCreate,
}) => {
    const { t } = useTranslation();

    const handleAddition = async (newItem) => {
        setPortals({ ...portals, isLoading: true });
        const request = await createRequest(API.GET_PORTALS, {
            name: newItem,
        });

        if (request.status === 201) {
            setPortals((prevState) => {
                return {
                    options: [
                        {
                            key: request.response.id,
                            value: request.response.id,
                            text: request.response.name,
                        },
                        ...prevState.options,
                    ],
                };
            });

            setData((prevState) => {
                return {
                    ...prevState,
                    portal: request.response.id,
                };
            });
        }
    };

    const [data, setData] = useState({
        title: externalPublication ? externalPublication.title : '',
        url: externalPublication ? externalPublication.url : '',
        published_when: externalPublication ? externalPublication.published_when : '',
        is_active: externalPublication ? externalPublication.is_active : true,
        is_featured: externalPublication ? externalPublication.is_featured : true,
        is_published: externalPublication ? externalPublication.is_published : true,
        portal: externalPublication?.portal?.id || "",
        job_offer: offer.id,
    });

    const [portals, setPortals] = useSelectOptions(API.GET_PORTALS, 'name');
    const [invalidUrl, setInvalidUrl] = useState(false);

    function isValidUrl(_string) {
        let url_string;
        try {
            url_string = new URL(_string);
        } catch (_) {
            return false;
        }
        return url_string.protocol === 'http:' || url_string.protocol === 'https:';
    }

    // Validate Form
    useEffect(() => {
        if (data.url) {
            if (isValidUrl(data.url)) {
                setInvalidUrl(false);
            } else {
                setInvalidUrl(t('enter_valid_url'));
            }
        }
        // eslint-disable-next-line
    }, [data.url]);

    const onSubmit = async () => {
        const dataForSubmittion = {
            ...data,
            published_when: data.published_when !== '' ? data.published_when : null,
        };

        if (externalPublication?.id) {
            const request = await updateRequest(
                API.EXTERNAL_PUBLICATIONS + externalPublication.id + '/',
                dataForSubmittion
            );
            if (request.status === 200) {
                setExternalPublications(
                    externalPublications.map((item) => {
                        if (item.id === externalPublication?.id) {
                            item.title = request.response.title;
                            item.url = request.response.url;
                            item.published_when = request.response.published_when;
                            item.is_active = request.response.is_active;
                            item.is_featured = request.response.is_featured;
                            item.is_published = request.response.is_published;
                            item.portal = request.response.portal;
                        }
                        return item;
                    })
                );

                onClose();
            }
        } else {
            const request = await createRequest(API.EXTERNAL_PUBLICATIONS, dataForSubmittion);
            if (request.status === 201) {
                setExternalPublications((prevState) => [...prevState, request.response]);
                setOnPublicationCreate(false);
            }
        }
    };

    return (
        <>
            {!externalPublication?.id ? (
                <BackTo header={t('external_publications')} onClick={() => setViewMode(0)} />
            ) : (
                ''
            )}
            <Form onSubmit={onSubmit}>
                <Form.Group widths="equal">
                    <SuperField
                        as="input"
                        autoFocus
                        required
                        label={t('title')}
                        value={data.title}
                        onChange={(e) => setData({ ...data, title: e.target.value })}
                    />
                    <SuperField
                        as="input"
                        error={invalidUrl}
                        label={t('url')}
                        value={data.url}
                        onChange={(e) => setData({ ...data, url: e.target.value })}
                    />
                </Form.Group>

                <Divider />

                <Form.Group widths="equal">
                    <SuperField
                        as="choice"
                        required
                        search
                        label={t('portal')}
                        text="name"
                        allowAdditions
                        disabled={portals.isLoading}
                        loading={portals.isLoading}
                        customOptions={portals.options}
                        onAddItem={(e, { value }) => handleAddition(value)}
                        // value={data.portal}
                        onChange={(e, { value }) => setData({ ...data, portal: value })}
                    />
                    <SuperField
                        as="datepicker"
                        label={t('published_when')}
                        value={data.published_when}
                        onChange={(e, { value }) => setData({ ...data, published_when: value })}
                    />
                </Form.Group>

                <Divider />
                <Button primary floated="right" style={{ marginBottom: '1rem' }}>
                    {t('save')}
                </Button>
            </Form>
        </>
    );
};

export default ExternalPublicatonForm;
