import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-semantic-toasts';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { tzDateTime } from '@helpers/dates';
// components
import Icon from '@components/Icon';
import Action from '@components/general/Action';
import SuperField from '@components/forms/SuperField';
import { Popup, Table, Form, Button } from 'semantic-ui-react';

const ShiftManagement = ({ shifts, setShifts }) => {
    const { t } = useTranslation()

    const onAddShift = () => {
        setShifts(prev => [...prev, {
            id: 0,
            custom_id: "",
            title: "",
            time_from: "",
            time_to: "",
            pause_length: "",
            is_night_shift: false,
            is_processing: false,
            errors: null,
        }])
    }

    const handleChange = (index, value, attribute) => {
        setShifts(prev => prev.filter((item, idx) => {
            if (idx === index) {
                item[attribute] = value
            }

            return item
        }))
    }

    const onDelete = async (id, idx) => {
        if (id === 0) {
            setShifts(prev => prev.filter((item, index) => index !== idx))
            return
        }

        const request = await requests.del(API.ATTENDANCE_BASE + "shifts/" + id + "/")
        if (request.status === 204) {
            setShifts(prev => prev.filter(item => item.id !== id))
        }
    }

    const onConfirm = async (id, idx) => {
        handleChange(idx, true, "is_processing")
        handleChange(idx, null, "errors")
        let data = {}
        if (id === 0) {
            data = shifts.find((item, index) => index === idx) || {}
        } else {
            data = shifts.find(item => item.id === id) || {}
        }

        if (data?.is_processing) delete data?.is_processing

        if (!["", null].includes(data.time_from)) {
            // convert to UTC:
            let time_from = moment(moment().format("YYYY-MM-DD") + " " + data.time_from)
            data.time_from = tzDateTime(time_from, true).format("HH:mm")
        }

        if (!["", null].includes(data.time_to)) {
            // convert to UTC:
            let time_to = moment(moment().format("YYYY-MM-DD") + " " + data.time_to)
            data.time_to = tzDateTime(time_to, true).format("HH:mm")
        }

        if (!["", null].includes(data.pause_length)) {
            if (parseFloat(data.pause_length) > 0) {
                data.pause_length = parseFloat(data.pause_length)
            }
        }

        // manage api calls
        let request = undefined
        if (id === 0) {
            request = await requests.post(API.ATTENDANCE_BASE + "shifts/", data)
        } else {
            request = await requests.patch(API.ATTENDANCE_BASE + "shifts/" + id + "/", data)
        }
        
        if ([200, 201].includes(request?.status)) {
            if (request?.status === 201) {
                handleChange(idx, request.response.id, "id")
            }

            if (data.pause_length > 0) {
                handleChange(idx, parseFloat(data.pause_length), "pause_length")
            }

            if (!["", null].includes(data.time_from)) {
                let datetime_from = moment(moment().format("YYYY-MM-DD") + " " + data.time_from).format("YYYY-MM-DD HH:mm")
                data.time_from = tzDateTime(datetime_from).format("HH:mm")
                handleChange(idx, data.time_from, "time_from")
            }
    
            if (!["", null].includes(data.time_to)) {
                let datetime_to = moment(moment().format("YYYY-MM-DD") + " " + data.time_to).format("YYYY-MM-DD HH:mm")
                data.time_to = tzDateTime(datetime_to).format("HH:mm")
                handleChange(idx, data.time_to, "time_to")
            }

            toast({
                type: 'success',
                icon: 'check circle',
                title: t('success'),
                description: "",
                animation: 'bounce',
                time: 5000,
            })
        } else if( request?.status === 400 ){
            handleChange(idx, request?.response, "errors")
            toast({
                type: 'error',
                icon: 'warning circle',
                title: t('error'),
                description: "",
                animation: 'bounce',
                time: 5000,
            })
        }
        handleChange(idx, false, "is_processing")
    }

    return (
        <>
            <Form onSubmit={(e) => e.preventDefault()}>
                <Table striped selectable unstackable className="table responsive" size="small">
                    <Table.Header verticalAlign="middle">
                        <Table.HeaderCell singleLine>{ t('id') }</Table.HeaderCell>
                        <Table.HeaderCell singleLine>{ t('title') }</Table.HeaderCell>
                        <Table.HeaderCell singleLine>{ t('time_from') }</Table.HeaderCell>
                        <Table.HeaderCell singleLine>{ t('time_to') }</Table.HeaderCell>
                        <Table.HeaderCell singleLine>{ t('duration') } 
                            <Popup
                                position="top center"
                                trigger={
                                    <Icon name="information-circle-outline" style={{ marginLeft: "0.5rem" }}/>
                                }
                                content={t('enter_value_in_hours_for_example_hint')}
                            />
                        </Table.HeaderCell>
                        <Table.HeaderCell singleLine>{ t('pause_duration') }
                            <Popup
                                position="top center"
                                trigger={
                                    <Icon name="information-circle-outline" style={{ marginLeft: "0.5rem" }}/>
                                }
                                content={t('enter_value_in_hours_for_example_hint_pause')}
                            />
                        </Table.HeaderCell>
                        <Table.HeaderCell singleLine textAlign="center">{ t('night_shift') }</Table.HeaderCell>
                        <Table.HeaderCell singleLine>{ t("actions") }</Table.HeaderCell>
                    </Table.Header>
                    <Table.Body>
                        { shifts.length === 0 && <Table.Cell textAlign="center" colspan="8" content={t('no_data')}/> }
                            { shifts.map((item, index) => (
                                <Table.Row verticalAlign="middle" key={index}>
                                    <Table.Cell width="1"> 
                                        <SuperField as="input" size="small"
                                            style={{ width: "80px" }}
                                            value={item.custom_id}
                                            title={item.errors?.custom_id?.[0] || ""}
                                            error={item.errors?.custom_id?.[0] || false}
                                            onChange={(e, { value }) => handleChange(index, value, "custom_id")}
                                        />
                                    </Table.Cell>
                                    <Table.Cell width="2">
                                        <SuperField as="input" size="small"
                                            value={item.title}
                                            title={item.errors?.title?.[0] || ""}
                                            error={item.errors?.title?.[0] || false}
                                            onChange={(e, { value }) => handleChange(index, value, "title")}
                                        />    
                                    </Table.Cell>
                                    <Table.Cell width="1"> 
                                        <SuperField as="timepicker" size="small"
                                            style={{ width: "100px" }}
                                            value={item.time_from}
                                            title={item.errors?.time_from?.[0] || ""}
                                            error={item.errors?.time_from?.[0] || false}
                                            onChange={(e, { value }) => handleChange(index, value, "time_from")}
                                        />       
                                    </Table.Cell>
                                    <Table.Cell width="1"> 
                                        <SuperField as="timepicker" size="small"
                                            style={{ width: "100px" }}
                                            value={item.time_to}
                                            title={item.errors?.time_to?.[0] || ""}
                                            error={item.errors?.time_to?.[0] || false}
                                            onChange={(e, { value }) => handleChange(index, value, "time_to")}
                                        />    
                                    </Table.Cell>
                                    <Table.Cell width="1"> 
                                        <SuperField as="input" size="small"
                                            style={{ width: "100px" }}
                                            value={item.length}
                                            title={item.errors?.length?.[0] || ""}
                                            error={item.errors?.length?.[0] || false}
                                            onChange={(e, { value }) => handleChange(index, value?.replace(",", "."), "length")}
                                        />    
                                    </Table.Cell>
                                    <Table.Cell width="1"> 
                                        <SuperField as="input" size="small"
                                            style={{ width: "100px" }}
                                            value={item.pause_length}
                                            title={item.errors?.pause_length?.[0] || ""}
                                            error={item.errors?.pause_length?.[0] || false}
                                            onChange={(e, { value }) => handleChange(index, value?.replace(",", "."), "pause_length")}
                                        />    
                                    </Table.Cell>
                                    <Table.Cell textAlign="center"> 
                                        <SuperField as="checkbox" size="small"
                                            checked={item.is_night_shift}
                                            title={item.errors?.is_night_shift?.[0] || ""}
                                            error={item.errors?.is_night_shift?.[0] || false}
                                            onChange={() => handleChange(index, !item.is_night_shift, "is_night_shift")}
                                        />    
                                    </Table.Cell>
                                    <Table.Cell width="3"> 
                                        <Button.Group size="tiny" fluid>
                                            <Button
                                                size="tiny"
                                                type="button"
                                                content={t('save')}
                                                disabled={item.is_processing}
                                                loading={item.is_processing}
                                                onClick={() => onConfirm(item.id, index)}
                                                style={{ 
                                                    background: "var(--dark)",
                                                    color: "var(--white)",
                                                    marginRight: "1rem"
                                                }}
                                            />
                                            <Action as='delete'
                                                tooltip={t('delete')} 
                                                size="huge"
                                                text={t('delete_shift')} 
                                                onClick={() => onDelete(item.id, index)}
                                            />
                                        </Button.Group>
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                    </Table.Body>
                </Table>
            </Form>
            <Button
                style={{ marginTop: "0.5rem" }}
                primary
                type="button"
                content={t('add_shift')}
                onClick={() => onAddShift()}
            />
        </>
    );
};

export default ShiftManagement;