import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next"
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// store
import { routes } from "@routes/index";
import { allowedModules as modules } from '@store/permissions/index';
// components
import { Grid, Card, Header, Form, Divider, Container } from 'semantic-ui-react';
import CanView from '@components/perms/CanView';
import SuperField from '@components/forms/SuperField';

const TypesList = () => {
    const { t } = useTranslation();
    const allowedModules = useSelector(state => state.allowed_modules)

    const types = [
        { title: t("hiring_sources"), url: "hiring-sources", category: "hiring", category_name: t('hiring_rooms'), module: modules.HIRING_ROOMS, can_view: [] },
        { title: t("cases_types"), url: "cases-types", category: "cases", category_name: t('cases'), module: modules.CASES, can_view: ['cases.c_view_all_cases', 'cases.c_view_assigned_user_cases'] },
        { title: t("person_types"), url: "person-types", category: "employees", category_name: t('employees'), module: modules.EMPLOYEES, can_view: [] },
        { title: t("phone_types"), url: "phone-types", category: "employees", category_name: t('employees'), module: modules.EMPLOYEES, can_view: [] },
        { title: t("unit_types"), url: "unit-types", category: "company", category_name: t('company'), module: modules.COMPANY, can_view: ['company.c_view_active_company', 'company.c_view_inactive_companies', 'company.c_view_units'] },
        { title: t("isco"), url: "isco", category: "positions", category_name: t('positions'), module: modules.POSITIONS, can_view: ['contracts.c_view_all_contracts', 'contracts.c_view_user_contracts'] },
        { title: t("contract_types"), url: "contract-types", category: "contract", category_name: t('contract'), module: modules.CONTRACTS, can_view: ['contracts.c_view_all_contracts', 'contracts.c_view_user_contracts'] },
        { title: t("record_categories"), url: "cost-centers-record-categories", category: "centers", category_name: t('cost_centers'), module: modules.COST_CENTERS, can_view: ['cost_centers.c_view_cost_centers'] },
        { title: t("course_categories"), url: "course-categories", category: "courses", category_name: t('courses'), module: modules.COURSES, can_view: ['courses.c_view_all_courses', 'courses.c_view_user_courses'] },
        { title: t("coursess"), url: "courses", category: "courses", category_name: t('courses'), module: modules.COURSES, can_view: ['courses.c_view_all_courses', 'courses.c_view_user_courses'] },
        { title: t("timesheets_activities"), url: "timesheets-activities", category: "timesheets", category_name: t('timesheets'), module: modules.TIMESHEETS, can_view: ['timesheets.c_manage_timesheet_categories'] },
        { title: t("benefits_categories"), url: "benefits-categories", category: "benefits", category_name: t('benefits'), module: modules.BENEFITS, can_view: ['benefits.c_view_all_benefits', 'benefits.c_view_user_benefits'] },
        { title: t("interruption_reasons"), url: "interruption-reasons", category: "attendance", category_name: t('attendance'), module: modules.ATTENDANCE, can_view: [] },
        { title: t("turnuses"), url: "turnuses", category: "attendance", category_name: t('attendance'), module: modules.ATTENDANCE, can_view: [] },
        { title: t("driver_licence"), url: "driver-licence", category: "employees", category_name: t('employees'), module: modules.EMPLOYEES, can_view: [] },
        { title: t("termination_reasons"), url: "termination-reasons", category: "contract", category_name: t('contract'), module: modules.CONTRACTS, can_view: ['contracts.c_view_all_contracts', 'contracts.c_view_user_contracts'] },
        { title: t("vehicle_types"), url: "vehicle-types", category: "vehicles", category_name: t('vehicles'), module: modules.VEHICLES, can_view: [] },
        { title: t("health_insurance_types"), url: "health-insurance-types", category: "employees", category_name: t('employees'), module: modules.EMPLOYEES, can_view: []},
        { title: t("position_groups"), url: "position-groups", category: "positions", category_name: t('positions'), module: modules.POSITIONS, can_view: ['jobs.c_view_job_positions']},
        { title: t("position_levels"), url: "position-levels", category: "positions", category_name: t('positions'), module: modules.POSITIONS, can_view: ['jobs.c_view_job_positions']},
        { title: t("job_families"), url: "job-families", category: "positions", category_name: t('positions'), module: modules.POSITIONS, can_view: ['jobs.c_view_job_positions']},
        { title: t("task_types"), url: "task-types", category: "tasks", category_name: t('tasks'), module: modules.TASKS, can_view: ['tasks.c_view_tasks']},
        { title: t("transport_types"), url: "transport-types", category: "logistics", category_name: t('logistics'), module: modules.LOGISTICS, can_view: ['logistics.c_view_transport_types']},
        { title: t("measure_units"), url: "measure-units", category: "common", category_name: t('common'), module: modules.COMMON, can_view: ['common.c_view_measure_units']},
        { title: t("logistic_labels"), url: "logistic-labels", category: "logistics", category_name: t('logistics'), module: modules.LOGISTICS, can_view: ['logistics.c_view_all_transports', 'logistics.c_view_user_transports']},
        { title: t("account_supplier_labels"), url: "account-labels", category: "accounts", category_name: t('accounts') + "/" + t("suppliers"), module: modules.ACCOUNTS, can_view: ['accounts.c_view_all_accounts']},
        { title: t("languages"), url: "languages", category: "common", category_name: t('common'), module: modules.COMMON, can_view: []},
        { title: t("certificate_types"), url: "certificate-types", category: "company", category_name: t('company'), module: modules.COMPANY, can_view: ['company.c_view_certificates']},
        { title: t("sale_sources"), url: "sale-sources", category: "orders", category_name: t('orders'), module: modules.ORDERS, can_view: []},
        { title: t("cost_center_labels"), url: "cost-center-labels", category: "centers", category_name: t('cost_centers'), module: modules.COST_CENTERS, can_view: ['cost_centers.c_view_cost_centers']},
        { title: t("catalogue_item_categories"), url: "catalogue-item-categories", category: "orders", category_name: t('orders'), module: modules.ORDERS, can_view: ['orders.c_view_catalogue'] },
        { title: t("accommodations_labels"), url: "accommodations-labels", category: "accommodations", category_name: t('accommodations'), module: modules.ACCOMMODATIONS, can_view: ['accommodations.c_view_accommodation'] },
    ]

    const [options, setOptions] = useState([])
    const [selectedCategory, setSelectedCategory] = useState("all")
    const [data, setData] = useState([])
    const [search, setSearch] = useState("")

    useEffect(() => {
        async function fetchOptions() {
            const listOfOptions = []
            const categs = []
            for (let i = 0; i < types.length; i++) {
                const canRender = types[i].module.every(module => allowedModules.indexOf(module) >= 0)
                if (!categs.includes(types[i].category) && canRender) {
                    listOfOptions.push({ key: i + 1, value: types[i].category, text: types[i].category_name });
                    categs.push(types[i].category)
                }
            }
            setOptions([{ key: 0, value: "all", text: t('all') }, ...listOfOptions])
        }

        fetchOptions()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        async function fetchData() {
            const newData = []
            for (let i = 0; i < types.length; i++) {
                if (selectedCategory === types[i].category) {
                    //newData.push(types[i]);
                    if (types[i].category_name.toLowerCase().includes(search.toLowerCase()) || types[i].title.toLowerCase().includes(search.toLowerCase())) {
                        newData.push(types[i]);
                    }
                }
                else if (selectedCategory === "all") {
                    if (types[i].category_name.toLowerCase().includes(search.toLowerCase()) || types[i].title.toLowerCase().includes(search.toLowerCase())) {
                        newData.push(types[i]);
                    }
                }
            }

            setData(newData)
        }

        fetchData()
        // eslint-disable-next-line
    }, [selectedCategory, search])

    return (
        <Container fluid style={{ marginTop: "1rem" }}>
            <Form>
                <Form.Group style={{ marginLeft: "0.5rem" }}>
                    <SuperField as="choice"
                        label={t('category')}
                        search
                        width={3}
                        value={selectedCategory}
                        onChange={(e, { value }) => {
                            if (value === "") value = "all"
                            setSelectedCategory(value)
                        }}
                        customOptions={options}
                    />
                    <SuperField as="input"
                        icon="search"
                        width={4}
                        label={t('search')}
                        placeholder={t('search')}
                        value={search}
                        onChange={(e, { value }) => setSearch(value)}
                    />
                </Form.Group>
            </Form>
            <Divider />
            <Grid stretched style={{ marginLeft: "0.2rem" }}>
                {data.map((item, index) =>
                    <>
                        <CanView permissions={item?.can_view}>
                            <Grid.Column mobile={16} tablet={8} computer={5} key={index}>
                                <Card
                                    fluid
                                    as={Link}
                                    to={routes.SETTINGS_TYPES + '/' + item.url}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        borderRadius: 0,
                                        height: "100%",
                                        cursor: "pointer"
                                    }}
                                >
                                    <Card.Content style={{ textAlign: "center", flexGrow: 0 }}>
                                        <Header as="h3" style={{ marginBottom: "0.2rem" }}>
                                            {item.title}
                                        </Header>
                                        <strong>{item.category_name}</strong><br /><br />
                                        <span style={{ color: "var(--variant2)" }}> {t('select_to_manage')} </span>
                                    </Card.Content>
                                </Card>
                            </Grid.Column>
                        </CanView>
                    </>
                )
                }
            </Grid>
        </Container>
    );
};

export default TypesList;