import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
// store
import { API } from '@store/config';
// components
import { Button, Form } from 'semantic-ui-react';
import Icon from '@components/Icon';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import UploadFile from '@components/UploadFile';
import RecordForm from '../../courses/components/RecordForm';
import SuperField from '@components/forms/SuperField';
import ListView from '@components/ListView';
import CanView from '@components/perms/CanView';

const CourseRecords = ({ employees }) => {
    const { t } = useTranslation();

    const resolveExpiration = (dateFrom, dateTo) => {
        if (dateTo === null || dateTo === undefined || dateTo === '') {
            return t('no_expiration');
        }

        if (moment().isAfter(dateTo)) {
            return <span style={{ color: 'var(--danger)' }}>{t('expired')}</span>;
        }

        return moment(dateTo).endOf('day').fromNow();
    };

    return (
        <>
            <ListView
                as="table"
                allowSearch
                isSoftDeleteAllowed
                endpoint={API.COURSES_RECORDS}
                initialFilters={{
                    is_mandatory: '',
                    person: '',
                    course: '',
                    expired: '',
                    failed: '',
                    category: '',
                }}
                renderFilterFields={(filters, setFilters) => (
                    <>
                        <SuperField
                            search
                            as="choice"
                            text="title"
                            label={t('category')}
                            value={filters.category}
                            endpoint={API.COURSES_CATEGORIES}
                            onChange={(e, { value }) => setFilters((prev) => ({ ...prev, category: value }))}
                        />
                        <SuperField
                            search
                            as="choice"
                            label={t('course')}
                            endpoint={API.COURSES + '?query={id, title}'}
                            value={filters.course}
                            text="title"
                            onChange={(e, { value }) => setFilters({ ...filters, course: value })}
                        />
                        <SuperField
                            as="choice"
                            search
                            label={t('employee')}
                            value={filters.person}
                            customOptions={employees}
                            onChange={(e, { value }) => setFilters({ ...filters, person: value })}
                        />
                        <Form.Group widths="equal">
                            <SuperField
                                as="checkbox"
                                label={t('mandatory')}
                                checked={filters.is_mandatory}
                                onChange={() =>
                                    setFilters((prev) => ({
                                        ...prev,
                                        is_mandatory: filters.is_mandatory === '' ? true : '',
                                    }))
                                }
                            />
                            <SuperField
                                as="checkbox"
                                label={t('expired')}
                                checked={filters.expired}
                                onChange={() =>
                                    setFilters((prev) => ({
                                        ...prev,
                                        expired: filters.expired === '' ? true : '',
                                    }))
                                }
                            />
                            <SuperField
                                as="checkbox"
                                label={t('failed')}
                                checked={filters.failed}
                                onChange={() =>
                                    setFilters((prev) => ({
                                        ...prev,
                                        failed: filters.failed === '' ? true : '',
                                    }))
                                }
                            />
                        </Form.Group>
                    </>
                )}
                actions={[
                    {
                        as: 'modal',
                        type: 'add',
                        name: t('create_record'),
                        modal: <RecordForm employees={employees} />,
                        permissions: ['courses.c_manage_all_courses'],
                    },
                    {
                        name: t('edit'),
                        type: 'edit',
                        as: 'modal',
                        icon: 'pencil-outline',
                        customIconColor: 'var(--dark)',
                        permissions: ['courses.c_manage_all_courses'],
                        modal: <RecordForm employees={employees} />,
                    },
                    {
                        name: t('delete'),
                        type: 'delete',
                        as: 'delete',
                        text: t('are_you_sure'),
                        permissions: ['courses.c_manage_all_courses'],
                    },
                ]}
                tableHeaders={[
                    { title: t('employee'), orderBy: 'fullname' },
                    { title: t('course'), orderBy: 'title' },
                    { title: t('made_on'), orderBy: 'made_on' },
                    { title: t('valid_from') },
                    { title: t('valid_until') },
                    { title: t('status') },
                    { title: t('document') },
                ]}
                renderCells={(record, setData) => [
                    { content: record?.person?.fullname },
                    {
                        content: (
                            <>
                                {record?.course?.title} <br />
                                <small>{resolveExpiration(record.valid_from, record.valid_until)}</small>
                            </>
                        ),
                    },
                    { content: record.made_on },
                    { content: record.valid_from },
                    { content: record.valid_until },
                    {
                        content: !record.failed ? (
                            <span style={{ color: 'var(--success)' }}>Completed</span>
                        ) : (
                            <span style={{ color: 'var(--danger)' }}>Failed</span>
                        ),
                    },
                    {
                        content: (
                            <>
                                {record?.attachment ? (
                                    // eslint-disable-next-line
                                    <a href={record?.attachment} target="_blank" rel="noopener noreferrer">
                                        <Icon
                                            style={{ cursor: 'pointer', color: 'var(--danger)', fontSize: '1.5rem' }}
                                            name="document-text-outline"
                                        />
                                    </a>
                                ) : (
                                    <CanView permissions={['courses.c_manage_all_courses']}>
                                        <SuperDuperModal
                                            trigger={<Button size="tiny" basic content={t('add_document')} />}
                                            content={
                                                <UploadFile
                                                    field="attachment"
                                                    endpoint={API.COURSES_RECORDS + record.id + "/attachment/"}
                                                    callback={(response) =>
                                                        setData((prev) =>
                                                            prev.filter((item) => {
                                                                if (item.id === record.id) {
                                                                    item.attachment = response.attachment;
                                                                }

                                                                return item;
                                                            })
                                                        )
                                                    }
                                                />
                                            }
                                        />
                                    </CanView>
                                )}
                            </>
                        ),
                    },
                ]}
            />
        </>
    );
};

export default CourseRecords;
