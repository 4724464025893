import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next"
// store
import { requests } from '@helpers/requests';
// components
import { Form, Divider } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';

const TypeForm = ({ endpoint, definitions, languages, action, onConfirm, onClose, result }) => {
    const [form, setForm] = useState({})
    const [isProcessing, setIsProcessing] = useState(false)
    const [errors, setErrors] = useState(null)
    const { t } = useTranslation()

    useEffect(() => {
        let arr = {}
        if (languages?.length > 0) {
            let translations = {}
            for (let i = 0; i < languages.length; i++) {
                translations[languages[i]] = {}

                const translatable_fields = definitions.filter(item => item.isTranslatable === true)
                for (let x = 0; x < translatable_fields.length; x++) {
                    translations[languages[i]][translatable_fields[x].key] = result?.translations?.[languages[i]]?.[definitions[x].key] || ""
                }
            }

            arr["translations"] = translations

            const non_translatable_fields = definitions.filter(item => item.isTranslatable !== true)
            for (let i = 0; i < non_translatable_fields.length; i++) {
                arr[non_translatable_fields[i].key] = result?.[non_translatable_fields[i].key] || ""
            }
        } else {
            for (let i = 0; i < definitions.length; i++) {
                if (definitions[i].isNested) {
                    arr[definitions[i].key] = result?.[definitions[i].key]?.id || definitions[i]?.defaultValue || ""
                } else {
                    arr[definitions[i].key] = result?.[definitions[i].key] || definitions[i]?.defaultValue || ""
                }
            }
        }

        setForm(arr)
        // eslint-disable-next-line
    }, [])

    const changeValue = (value, key, language) => {
        if (language === undefined) {
            setForm(prev => ({
                ...prev,
                [key]: value
            }))
        } else {
            setForm(prev => ({
                ...prev,
                translations: {
                    ...prev.translations,
                    [language]: {
                        ...prev.translations?.[language],
                        [key]: value
                    }
                }
            }))
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        setIsProcessing(true)
        setErrors(null)
        let request = null
        if (action === 'create') {
            request = await requests.post(endpoint + (languages?.length > 0 ? "?allow_translations=true" : ""), form)
        } else {
            let data = form
            
            // is multilanguge? adjust dataset
            // if (languages?.length > 0) {
            //     let translations = data.translations
            //     const translatable_fields = definitions.filter(item => item.isTranslatable === true)
            //     for (let i = 0; i < languages.length; i++) {
            //         for (let x = 0; x < translatable_fields.length; x++) {
            //             if (translations?.[languages[i]]?.[definitions[x].key] === result?.translations?.[languages[i]]?.[definitions[x].key]) {
            //                 delete translations?.[languages[i]]?.[definitions[x].key]
            //             }
            //         }
            //     }

            //     data['translations'] = translations
            // }


            request = await requests.patch(endpoint + result.id + "/" + (languages?.length > 0 ? "?allow_translations=true" : ""), data)
        }
        if (request !== null) {
            if (request.status === 201 || request.status === 200) {
                onConfirm(request.response)
                onClose()
            } else {
                setErrors(request.response)
            }
        }

        setIsProcessing(false)
    }

    return (
        <Form onSubmit={onSubmit}>
            { errors !== null && errors?.non_field_errors }
            { definitions.filter(item => item.isTranslatable === true).map((field, index) => (
                <span key={index} style={{ display: "block", marginTop: "1rem" }}>
                    { languages.map((lang, idx) => (
                        <div key={idx} style={{ marginBottom: "1rem" }}>
                            {field.as === "choice"
                                ? field.customOptions !== undefined
                                    ?
                                        <SuperField
                                            as={field.as}
                                            search={field.search}
                                            label={field.name + " - " + (lang === "en-us" ? "EN" : lang.toUpperCase())}
                                            value={form?.translations?.[lang]?.[field.key] || ""}
                                            required={field.isRequired}
                                            error={errors?.[field.key]?.[0] || false}
                                            customOptions={field.customOptions}
                                            onChange={(e, { value }) => changeValue(value, field.key, lang)}
                                        />
                                    :
                                        <SuperField
                                            as={field.as}
                                            search={field.search}
                                            type={field.type}
                                            required={field.isRequired}
                                            endpoint={field.endpoint}
                                            text={field.text}
                                            label={field.name + " - " + (lang === "en-us" ? "EN" : lang.toUpperCase())}
                                            value={form?.translations?.[lang]?.[field.key] || ""}
                                            error={errors?.[field.key]?.[0] || false}
                                            onChange={(e, { value }) => changeValue(value, field.key, lang)}
                                        />
                                :
                                    <SuperField
                                        as={field.as}
                                        search={field.search}
                                        required={field.isRequired}
                                        label={field.name + " - " + (lang === "en-us" ? "EN" : lang.toUpperCase())}
                                        value={form?.translations?.[lang]?.[field.key] || ""}
                                        onChange={(e, { value }) => changeValue(value, field.key, lang)}
                                        error={errors?.[field.key]?.[0] || false}
                                        color={form?.translations?.[lang]?.[field.key] || ""}
                                        setColor={(value) => changeValue(value, field.key, lang)}
                                    />
                            }
                        </div>
                    )) }
                </span>
            ))}

            { definitions.filter(item => item.isTranslatable !== true).map((field, index) => (
                <span key={index} style={{ display: "block", marginTop: "1rem" }}>
                    {field.as === "choice"
                        ? field.customOptions !== undefined
                            ?
                            <SuperField
                                as={field.as}
                                search={field.search}
                                label={field.name}
                                value={form?.[field.key]}
                                required={field.isRequired}
                                error={errors?.[field.key]?.[0] || false}
                                customOptions={field.customOptions}
                                onChange={(e, { value }) => changeValue(value, field.key)}
                            />
                            :
                            <SuperField
                                as={field.as}
                                search={field.search}
                                type={field.type}
                                required={field.isRequired}
                                label={field.name}
                                endpoint={field.endpoint}
                                text={field.text}
                                value={form?.[field.key]}
                                error={errors?.[field.key]?.[0] || false}
                                onChange={(e, { value }) => changeValue(value, field.key)}
                            />
                        :
                        <>
                            { field.as === "checkbox" && <div style={{ width: "100%", marginTop: "1rem" }}></div> }
                            <SuperField
                                as={field.as}
                                search={field.search}
                                required={field.isRequired}
                                label={field.name}
                                checked={field.as === "checkbox" ? form?.[field.key] : undefined }
                                onChange={field.as === "checkbox" ? () => changeValue(!form?.[field.key], field.key) : (e, { value }) => changeValue(value, field.key) }
                                value={form?.[field.key]}
                                error={errors?.[field.key]?.[0] || false}
                                color={form?.[field.key]}
                                setColor={(value) => changeValue(value, field.key)}
                            />
                        </>
                    }
                </span>
            ))}

            <Divider/>
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel
                    onClose={onClose}
                    disabled={isProcessing}
                />
                <ModalSubmit
                    loading={isProcessing}
                    disabled={isProcessing /*|| isEmpty(title) || isEmpty(content)*/}
                    text={t('save')}
                />
            </Form.Field>
        </Form>
    )
}

export default TypeForm;