import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { tzDateTime, getTimezoneOffset } from '@helpers/dates';
// components
import SuperField from '@components/forms/SuperField';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import { Divider, Form, Button, Icon, Grid, Message } from 'semantic-ui-react';
// module specific
import ImportInvalidReport from './ImportInvalidReport';

const ImportAttendance = ({ onClose }) => {
    const { t } = useTranslation()

    const currentDateTime = moment().utc().format("YYYY-MM-DD HH:mm:ss")
    const currentTime = tzDateTime(currentDateTime).format("HH:mm")

    const [response, setResponse] = useState(null)
    const [importing, setImporting] = useState(false)
    const [file, setFile] = useState(null)

    const handleSubmit = async () => {
        setResponse(null)
        setImporting(true)
        const formData = new FormData()
        formData.append("file", file)

        const request = await requests.post(API.API_URL + `/imports/attendance/?only_primary_sheet=true&timezone=${getTimezoneOffset()}`, formData)
        setResponse(request)
        setImporting(false)
    }

    

    const XLSXImportResult = ({ response }) => {

        const calculateInvoiceInvalid = () => {
            let total = 0
            total += response?.Attendance?.totals?.invalid || 0
            total += response?.Attendance?.totals?.error || 0

            return total
        }

        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column computer={16} tablet={16} mobile={16}>
                        <p style={{ fontWeight: "bold", fontSize: "1.3rem" }}> {t('attendance_import_result')}:</p>
                        <ol style={{ listStyleType: "none", paddingLeft: "0.5rem" }}>
                            <li>{t('new')}: { response?.Attendance?.totals?.new || 0 }</li>
                            <li>{t('updated')}: { response?.Attendance?.totals?.update || 0 }</li>
                            <li>{t('skipped')}: { response?.Attendance?.totals?.skip || 0 }</li>
                            <li style={{ fontWeight: "bold" }}>
                                {t('invalid')}: { calculateInvoiceInvalid() }
                                { calculateInvoiceInvalid() > 0 && 
                                    <SuperDuperModal
                                        header={t('invalid_records')}
                                        trigger={
                                            <span className="ref-link" style={{ color: "var(--primary)", marginLeft: "0.5rem", fontSize: "0.9rem" }}>
                                                {t('show_report')}
                                            </span>
                                        }
                                        content={<ImportInvalidReport errors={response?.Attendance?.invalid}/>}
                                    />
                                }
                            </li>
                        </ol>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }

    return (
        <>
            <strong>{t('download_import_template_xlsx')}: <a href="/imports/xlsx_template_attendance.xlsx" target="_blank" download>{t('example')} XLSX</a>  </strong>
            <div style={{ marginTop: "0.5rem", marginBottom: "0.5rem", fontSize: "1.1rem" }}>
                <p style={{ color: "var(--danger)" }}>
                    {t('attendance_import_records_hint')}
                </p>
            </div>
            <Divider/>

            <Form onSubmit={handleSubmit}>
                <Message visible info content={
                    <div>
                        {t('your_device_timezone_is_set_to')} UTC{getTimezoneOffset() > 0 ? `+${getTimezoneOffset()}` : getTimezoneOffset()} <br/>
                        <strong>{t('make_sure_that_date_and_time_format_match_your_timezone_hint')} {currentTime}</strong>
                    </div>
                }/>
                <Divider/>
                <Form.Group>
                    <SuperField 
                        id="source-file"
                        as="input"
                        type="file"
                        onChange={(event) => {
                            if (event.target?.files?.length > 0) {
                                setFile(event.target.files[0])
                            }
                        }}
                    />
                    <Form.Field style={{ marginTop: "0.7rem" }} disabled={importing}>
                        { file !== null && 
                            <span className="ref-link" style={{ color: "var(--danger)" }} onClick={() => {
                                document.getElementById('source-file').value = null
                                setFile(null)
                                setResponse(null)
                            }}>{t('remove_file')}</span>
                        }
                    </Form.Field>
                </Form.Group>
                { response !== null && 
                    <>
                        { response?.status >= 500 && <p style={{ fontWeight: "bold", fontSize: "1.3rem", color: "var(--danger)" }}> { t('system_failure_message') } </p> }
                        { response?.status === 400 && <p style={{ fontWeight: "bold", fontSize: "1.3rem", color: "var(--danger)" }}> { response?.response?.file?.[0] || response?.response?.sheet || t('file_or_structure_not_allowed') } </p> }
                        { response?.status === 200 && 
                            <XLSXImportResult response={response?.response}/>
                        }
                        <Divider/>
                    </>
                }
                <Divider/>
                <Form.Group>
                    <Form.Field>
                        <Button disabled={importing || file === null} loading={importing} primary content={t('confirm')}/>
                    </Form.Field>
                    { importing && 
                        <Form.Field style={{ fontWeight: "bold", marginTop: "0.6rem" }}>
                            {t('we_are_importing_your_data_hint')} ({ t('good_time_for_a_coffee') } <Icon name="coffee"/>)
                        </Form.Field>
                    }
                </Form.Group>
            </Form>
        </>
    );
};

export default ImportAttendance;