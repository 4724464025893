import React, {useState} from 'react';
import { useTranslation } from "react-i18next"
//components
import Icon from '@components/Icon';
import {FlexItem, FlexRow} from '@components/tables/FlexTable';
import { Label, Divider, Grid, Header } from 'semantic-ui-react';
// specific components
import OtherInfoForm from '../forms/OtherInfoForm';


const OtherInfoSection = ({ data, setData, patchEndpoint }) => {
    const { t } = useTranslation()
    const [view, setView] = useState(0);

    return (
        <Grid stackable>
            <Grid.Row>
                <Grid.Column only="computer" computer="3" stretched style={{ borderRadius: "4px", paddingTop: "1rem", backgroundColor: "var(--light)"}}>
                    <FlexRow background="transparent" padding="0">
                        <FlexItem textAlign="center">
                            <Header icon >
                                <Icon name='information-circle-outline' style={{ fontSize: "5rem" }}/>
                                <h3>{ t('other_info') }</h3>
                            </Header>
                        </FlexItem>
                    </FlexRow>

                </Grid.Column>
                <Grid.Column computer="12" tablet="16" mobile="16">
                    <Grid style={{ padding: 0 }} >
                        <Grid.Row style={{ padding: 0 }} >
                            <Grid.Column only="computer" computer="16">
                                <Header floated="left" as="h3" style={{ marginBottom: 0 }}> 
                                    <Icon name="information-circle-outline" style={{ marginRight: "0.5rem" }}/> 
                                    <span style={{ position: "relative", top: "-0.2rem" }}>{t('other_info')}</span>
                                </Header>
                                <Header floated="right" as="h4" style={{ marginBottom: 0 }}>
                                    { view === 0 ?
                                        <span style={{ cursor: "pointer" }} onClick={() => setView(1)}>
                                            <Icon name="pencil-outline" style={{ fontSize: "1rem", marginRight: "0.5rem" }} />
                                            <span style={{ position: "relative", top: "-0.2rem" }}>{t('edit')}</span>
                                        </span>
                                    :
                                        <span style={{ cursor: "pointer" }} onClick={() => setView(0)}>
                                            <Icon name="close-outline" style={{ fontSize: "1rem", color: "var(--danger)", marginRight: "0.5rem" }} />
                                            <span style={{ position: "relative", top: "-0.2rem" }}>{t('cancel')}</span>
                                        </span>
                                    }
                                </Header>
                            </Grid.Column>
                            <Grid.Column style={{ padding: 0 }} only="mobile tablet" tablet="16" mobile="16">
                            <Header style={{ padding: 0 }} floated="left" as="h4">
                                { view === 0 ?
                                    <span style={{ cursor: "pointer" }} onClick={() => setView(1)}>
                                        <span style={{ position: "relative", top: "-0.5rem" }}>{t('other_info')}</span>
                                        <Icon name="pencil-outline" style={{ fontSize: "1rem", marginLeft: "1rem" }} />
                                    </span>
                                :
                                    <span style={{ cursor: "pointer" }} onClick={() => setView(0)}>
                                        <span style={{ position: "relative", top: "-0.5rem" }}>{t('other_info')}</span>
                                        <Icon name="close-outline" style={{ fontSize: "1rem", color: "var(--danger)", marginLeft: "1rem"}} />
                                    </span>
                                }
                            </Header>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                    <Divider />
                    { view === 1 ?
                        <OtherInfoForm
                            data={data}
                            setView={setView}
                            patchEndpoint={patchEndpoint}
                            onConfirm={setData}
                        />
                    :
                    <Grid stackable>
                        <Grid.Row columns={5}>
                            <Grid.Column>
                                {t('citizenship')} <br />
                                <strong>{data?.citizenship_display || '~'}</strong>
                            </Grid.Column>
                            <Grid.Column>
                                {t('nationality')} <br />
                                <strong>{data?.nationality_display || '~'}</strong>
                            </Grid.Column>
                            <Grid.Column>
                                {t('last_country_of_residence')} <br />
                                <strong>{data?.last_country_residence_display || '~'}</strong>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{paddingTop: "1rem"}} columns={5}>
                            
                            <Grid.Column>
                                {t('acting_by')} <br />
                                <strong>{data?.acting_by || '~'}</strong>
                            </Grid.Column>
                            <Grid.Column>
                                {t('hired_based_on')} <br />
                                <strong>{data?.source?.name || '~'}</strong>
                            </Grid.Column>
                            <Grid.Column>
                                {t('handicap_level')} <br />
                                <strong>{data?.handicap_level_display || '~'}</strong>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{paddingTop: "1rem"}} columns={5}>
                            <Grid.Column>
                                {t('number_of_children')} <br />
                                <strong>{data?.number_of_children || 0}</strong>
                            </Grid.Column>
                            <Grid.Column>
                                {t('driver_licence')} <br />
                                {data?.has_driver_license ? <strong> { t('yes') } </strong> : <strong> { t('no') }</strong>}
                            </Grid.Column>
                            <Grid.Column>
                                {t('driver_licences')} <br />
                                {data?.driver_licences?.length === 0 && <strong>~</strong>}
                                <strong>{data?.driver_licences?.map(obj => (
                                    <Label style={{ marginTop: "0.2rem" }} key={obj.id} basic > {obj.driver_group} </Label>
                                ))}</strong>
                            </Grid.Column>
                            <Grid.Column>
                                {t('health_insurance')} <br />
                                <strong>{data?.health_insurance?.code || " "} {data?.health_insurance?.name || " "}</strong>
                            </Grid.Column>
                            <Grid.Column>
                                { t('bank_account') } <br />
                                <strong>{ data?.iban || '~'}</strong>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{paddingTop: "1rem"}} columns={5}>
                            <Grid.Column>
                                {t('shirt_size')} <br />
                                <strong>{data?.shirt_size || '~'}</strong>
                            </Grid.Column>
                            <Grid.Column>
                                {t('jacket_size')} <br />
                                <strong>{data?.jacket_size || '~'}</strong>
                            </Grid.Column>
                            <Grid.Column>
                                {t('trousers_size')} <br />
                                <strong>{data?.trousers_size || '~'}</strong>
                            </Grid.Column>
                            <Grid.Column>
                                {t('shoe_size')} <br />
                                <strong>{data?.shoe_size || '~'}</strong>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{paddingTop: "1rem"}} columns={5}>
                            <Grid.Column>
                                {t('external_position')} <br />
                                <strong>{data?.is_external ? t("yes") : t("no") }</strong>
                            </Grid.Column>
                            <Grid.Column>
                                {t('is_foreigner')} <br />
                                <strong>{data?.is_foreigner ? t("yes") : t("no") }</strong>
                            </Grid.Column>
                            <Grid.Column>
                                {t('agency_employee')} <br />
                                <strong>{data?.is_employed_by_agencies ? t("yes") : t("no") }</strong>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    }
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default OtherInfoSection;