import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from 'react-router-dom';
// store
import { API } from '@store/config';
import { getRequest } from '@store/services/ServiceCommon';
// components
import { Form, Grid, Button, Divider } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';

const Filters = ({ assetsHistory, onFilter, filterValues, setFilterValues}) => {
    const { t } = useTranslation()
    const history = useHistory()
    const location = useLocation()
    
    const [storedItemOptions, setStoredItemOptions] = useState([])

    useEffect(() => {
        async function fetchStoredItems(){
            let tmpArray = []
            const request = await getRequest(API.ASSET_STORED_ITEMS + "?query={id, code, sn, item{id, title}, warehouse{id, title}}")

            if(request.status === 200){
                for (let i = 0; i < request.response.length; i++) {
                    let textValue = "";
                    let code = ""

                    if (request.response[i]?.code){
                        code = request.response[i].code + " - "
                    }

                    if( request.response[i].sn !== "" && request.response[i].sn !== null ){
                        textValue = code + request.response[i].item.title + " (SN:" + request.response[i].sn + ", " + request.response[i].warehouse.title+ ")"
                    } else {
                        textValue = code + request.response[i].item.title + " (" + request.response[i].warehouse.title + ")"
                    }

                    tmpArray.push({
                        key: request.response[i].id, 
                        text: textValue,
                        value: request.response[i].id
                    });
                }

                setStoredItemOptions(tmpArray)
            }
        }

        fetchStoredItems()
    }, [])

    return (
        <Form padding="0.5rem">
            <Grid>
                <Grid.Row style={{ padding: 0 }}>
                    <Grid.Column computer={8} table={16} mobile={16}>
                        <Form.Group widths="equal">
                            <SuperField as="choice"
                                size="small"
                                search
                                label={t('operation')}
                                type="issued_item_operation"
                                value={filterValues.operation}
                                onChange={(e, { value }) => setFilterValues({...filterValues, operation: value})}
                            />
                            <SuperField as="choice"
                                size="small"
                                search
                                label={t('stored_item')}
                                customOptions={storedItemOptions}
                                value={filterValues.storedItem}
                                onChange={(e, { value }) => setFilterValues({...filterValues, storedItem: value})}
                            />
                            <SuperField as="choice"
                                search
                                size="small"
                                label={t('person')}
                                text="fullname_with_titles"
                                endpoint={API.EMPLOYEES + "?profiles_with_asset_history=true&only_basic_info=true"}
                                value={filterValues.employee}
                                onChange={(e, { value }) => setFilterValues({...filterValues, employee: value})}
                            />
                        </Form.Group>
                    </Grid.Column>
                    <Grid.Column computer={4} table={16} mobile={16}>
                        <Form.Field style={{ marginTop: "1.8rem" }}>
                            <Button loading={assetsHistory.isLoading} disabled={assetsHistory.isLoading} primary content={t('confirm')} onClick={async () => {
                                history.replace({ pathname: location.pathname, search: `?page=${1}` });
                                await onFilter("", true)
                            }}/>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider />
        </Form>
    );
};

export default Filters;