import React from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import moment from 'moment';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import { Icon } from 'semantic-ui-react';
import CanView from '@components/perms/CanView';
import ConfirmModal from '@components/modals/ConfrimModal';
import InvoiceReferenceIndicator from './InvoiceReferenceIndicator';

const OrderState = ({ order, setData }) => {
    const { t } = useTranslation()
    const dateFormat = useSelector(store => store.date_format)

    const onOrderConfirm = async (id, setData) => {
        const request = await requests.put(API.ORDERS + id + "/confirm/");
        if (request.status === 200) {
            setData(prev => prev.filter((item) => {
                if (item.id === id) {
                    item.confirmed_on = request.response.confirmed_on;
                    item.confirmed_by = request.response.confirmed_by;
                }

                return item;
            }))
        }
    }

    const displayOrderStatus = () => {
        let iconColor = "var(--dark)"

        if ( order?.order_status === 1 ) iconColor = "var(--primary)"
        if ( order?.order_status === 2 ) iconColor = "var(--info)"
        if ( order?.order_status === 3 ) iconColor = "var(--blue)"
        if ( order?.order_status === 4 ) iconColor = "var(--success)"
        if ( order?.order_status === 5 ) iconColor = "var(--warning)"
        if ( order?.order_status === 6 ) iconColor = "var(--danger)"

        return ( 
            <>
                <Icon size="small" name="circle" style={{ color: iconColor, position: "relative", top: "-0.1rem" }}/>
                { order?.order_status_display }
            </>
        )
    }

    return (
        <>
            { order.confirmed_on === null 
                ? 
                <>
                    <Icon size="small" name="circle" style={{ color: "var(--dark)", position: "relative", top: "-0.1rem" }}/> {t('not_confirmed')} <br/>
                    { order.resourcetype === "TransportOrder" && 
                        <InvoiceReferenceIndicator fulfillment={order.fulfillment}/>
                    }
                    <CanView permissions={['orders.c_confirm_order']}>
                        <ConfirmModal
                            description={t('confirm_message')}
                            onConfirm={() => onOrderConfirm(order.id, setData)}
                            button={
                                <small style={{ color: "var(--primary)" }} className="ref-link"> {t('add_confirmation')} </small>
                            }
                        />
                    </CanView>
                </>
                : 
                <>
                    <strong>
                        { displayOrderStatus() }
                    </strong> <br/>
                    { order.resourcetype === "TransportOrder" && 
                        <InvoiceReferenceIndicator fulfillment={order.fulfillment}/>
                    }
                    <small> { t('confirmed_on') } { moment(order.confirmed_on).format(dateFormat) } </small>
                </>
            }
        </>
    );
};

export default OrderState;