import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
// store
import { routes } from '@routes';
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { useHasPermission } from '@helpers/hooks';
// components
import { Form } from 'semantic-ui-react';
import ListView from '@components/ListView';
import AvatarList from '@components/AvatarList';
import SuperField from '@components/forms/SuperField';
import LabelsList from '@components/lists/LabelsList';
import GenerateDocuments from '@components/GenerateDocuments';
// shared components
import OrderState from '../shared/OrderState';
// shared components
import ServiceOrderForm from './components/ServiceOrderForm';
import ServiceOrderDetail from './components/ServiceOrderDetail';
import ServiceOrderFulfillment from './components/ServiceOrderFulfillment';

const ServiceOrder = ({ isPurchase }) => {
    const { t } = useTranslation()
    const [accounts, setAccounts] = useState([])
    const [projects, setProjects] = useState([])
    const [employees, setEmployees] = useState([])
    const [labels, setLabels] = useState([])
    const [units, setUnits] = useState([])
    // const [filters, setFilters] = useState({})

    const canManageFulfillments = useHasPermission('orders.c_manage_order_fulfillment')
    const canManageAllRecords = useHasPermission('timesheets.c_manage_all_records')
    const canManageTariffs = useHasPermission('orders.c_manage_order_tariffs')
    const canManageCostCenters = useHasPermission('cost_centers.c_manage_cost_centers')
    const canManageCostCenterRecords = useHasPermission('cost_centers.c_manage_cost_centers')

    const canCreateFulfillments = () => {
        let isValid = true

        if (!canManageFulfillments) isValid = false
        if (!canManageAllRecords) isValid = false
        if (!canManageTariffs) isValid = false
        if (!canManageCostCenters) isValid = false
        if (!canManageCostCenterRecords) isValid = false

        return isValid
    }

    useEffect(() => {
        async function fetchAccounts(){
            const request = await requests.get(API.ACCOUNTS + "business_details/?query={id, name}&is_active=true&is_account=true&exclude_unit=true")
            if (request.status === 200) {
                setAccounts(request.response.map(item => ({ key: item.id, value: item.id, text: item.name })))
            }
        }

        async function fetchEmployees(){
            const request = await requests.get(API.EMPLOYEES + "?only_basic_info=true&is_active=true")
            if (request.status === 200) {
                setEmployees(request.response.map(item => ({ key: item.id, value: item.id, text: item.fullname_with_titles })))
            }
        }

        async function fetchProjects(){
            const request = await requests.get(API.PROJECTS + "?query={id, name}")
            if (request.status === 200) {
                setProjects(request.response.map(item => ({ key: item.id, value: item.id, text: item.name })))
            }
        }

        async function fetchLabels(){
            const request = await requests.get(API.ORDERS + "labels/?query={id, name}")
            if (request.status === 200) {
                setLabels(request.response.map(item => ({ key: item.id, value: item.id, text: item.name })))
            }
        }

        async function fetchUnits(){
            const request = await requests.get(API.UNITS + "?only_from_active_company=true&is_business=true&query={id, name}")
            if (request.status === 200) {
                setUnits(request.response.map(item => ({ key: item.id, value: item.id, text: item.name })))
            }
        }

        fetchAccounts()
        fetchEmployees()
        fetchProjects()
        fetchLabels()
        fetchUnits()
    }, [])

    return (
        <ListView
            as="table"
            allowSearch
            allowSelection
            actionsCellWidth="2"
            isSoftDeleteAllowed
            endpoint={API.ORDERS}
            tableHeaders={[
                { title: t('order_num'), orderBy: "order_number" },
                { title: t('name'), orderBy: "name" },
                { title: t('workers')},
                { title: t('supplier'), orderBy: "owner__name" },
                { title: t('account'), orderBy: "business_detail__name" },
                { title: t('labels') },
                { title: t('order_manager'), orderBy: "order_manager__fullname" },
                { title: t('responsible_persons')},
                { title: t('state'), orderBy: "order_status" },
                // { title: t('status'), orderBy: "is_active" },
            ]}
            // onResponse={(response, filters) => {
            //     setFilters(filters)
            // }}
            exports={[
                {
                    endpoint: API.EXPORTS + "dynamic_service_order/",
                    permissions: ['orders.c_view_order'],
                    apply_filters: true,
                    type: "xlsx",
                    filename: "service_orders"
                },
            ]}
            listAdditionActions={() => [
                {
                    as: 'filter',
                    index: 0,
                    name: t('received_orders'),
                    filters: {
                        is_purchase_order: false,
                    },
                },
            ]}
            bulkActions={ (selected) => [
                { 
                    as: "modal",
                    name: t('generate_documents'),
                    icon: "document-text-outline",
                    modal: <GenerateDocuments source="orders.ServiceOrder" selected={selected} />
                }
            ]}
            initialFilters={{
                is_purchase_order: isPurchase,
                ordering: "-created_on",
                account: "",
                project: "",
                labels: [],
                assigned_to: [],
                service_workers: [],
                order_manager: "",
                owner: "",
                order_status: "",
                is_active: true,
                resource_type: "serviceorder"
            }}
            renderFilterFields={(filters, setFilters) => (
                <>
                    <SuperField as="choice"
                        search
                        label={t('account')}
                        value={filters.account}
                        customOptions={accounts}
                        onChange={(e, { value }) => setFilters({ ...filters, account: value })}
                    />
                    <Form.Group widths="equal">
                        <SuperField as="choice"
                            search
                            label={t('order_manager')}
                            value={filters.order_manager}
                            customOptions={employees}
                            onChange={(e, { value }) => setFilters({ ...filters, order_manager: value })}
                        />
                        <SuperField as="choice"
                            search
                            label={t('owner')}
                            value={filters.owner}
                            customOptions={units}
                            onChange={(e, { value }) => setFilters({ ...filters, owner: value })}
                        />
                    </Form.Group>
                    
                    <SuperField as="choice"
                        search
                        label={t('project')}
                        value={filters.project}
                        customOptions={projects}
                        onChange={(e, { value }) => setFilters({ ...filters, project: value })}
                    />
                    <SuperField as="choice"
                        search
                        multiple
                        label={t('responsible_persons')}
                        value={filters.assigned_to}
                        customOptions={employees}
                        onChange={(e, { value }) => setFilters({ ...filters, assigned_to: value })}
                    />

                    <SuperField as="choice"
                        search
                        multiple
                        label={t('workers')}
                        value={filters.service_workers}
                        customOptions={employees}
                        onChange={(e, { value }) => setFilters({ ...filters, service_workers: value })}
                    />

                    <SuperField as="choice"
                        search
                        multiple
                        label={t('labels')}
                        value={filters.labels}
                        customOptions={labels}
                        onChange={(e, { value }) => setFilters({ ...filters, labels: value })}
                    />

                    <Form.Group widths="equal">
                        <SuperField as="choice"
                            label={t('state')}
                            value={filters.order_status}
                            type="order_status_choices"
                            onChange={(e, { value }) => setFilters({ ...filters, order_status: value })}
                        />
                        <SuperField as="choice"
                            label={t('status')}
                            value={filters.is_active}
                            customOptions={[
                                { key: 1, value: true, text: t('active_order') },
                                { key: 2, value: false, text: t('closed_order') },
                            ]}
                            onChange={(e, { value }) => setFilters({ ...filters, is_active: value })}
                        />
                    </Form.Group>
                </>
            )}
            actions={[
                {
                    as: "modal",
                    type: "add",
                    name: t('create_order'),
                    modal: <ServiceOrderForm projects={projects} accounts={accounts} employees={employees} owners={units}/>,
                    permissions: ['orders.c_manage_order'],
                },
                {
                    as: "modal",
                    type: "custom",
                    icon: "add-circle-outline",
                    name: !canCreateFulfillments() ? t('fulfillment_limited_access_hint') : t('create_fulfillment'),
                    modalSize: "tiny",
                    disabled: !canCreateFulfillments(),
                    customIconColor: "var(--dark)",
                    modal: <ServiceOrderFulfillment/>,
                    permissions: ['orders.c_manage_order_fulfillment']
                },
                {
                    as: "modal",
                    type: "custom",
                    icon: "eye-outline",
                    name: t('fulfillment_overview'),
                    modalSize: "large",
                    customIconColor: "var(--primary)",
                    modal: <ServiceOrderDetail/>,
                    permissions: ['orders.c_view_order_fulfillment']
                },
                {
                    as: "modal",
                    type: "edit",
                    name: t('edit'),
                    permissions: ['orders.c_manage_order'],
                    modal: <ServiceOrderForm projects={projects} accounts={accounts} employees={employees} owners={units}/>
                },
                {
                    name: t('delete'),
                    type: "delete",
                    as: "delete",
                    text: t('delete_order'),
                    permissions: ['orders.c_delete_order'],
                },
            ]}
            renderCells={(order, setData) => ([
                { width: 1, content: order.order_number },
                { customWidth: "150px", content: 
                    <>
                        <strong> { order.name } </strong>
                        {order?.project?.id && (
                            <>
                                <br/>
                                <span> {t('project')}: <strong>{ order.project?.name }</strong></span>
                            
                            </>
                        )}
                    </>
                },
                { content: order?.workers?.length || 0 },
                { content: order.owner?.name || "" },
                { content: 
                    <>
                        { order?.business_detail?.id ?
                            <>
                                <Link target="_blank" to={routes.ACCOUNTS + order.business_detail.id}>
                                    { order.business_detail?.name }
                                </Link> <br/>
                            </>
                            : "--"
                        }
                    </>
                },
                { customWidth: "200px", content: 
                    <LabelsList
                        key={order.id}
                        rowID={order.id}
                        labels={order.labels}
                        endpoint={API.ORDERS}
                        additionalFilters="&lead_tag=false"
                        resourcetype={order.resourcetype}
                        endpointLabels={API.ORDERS + "labels/"}
                        label_key={"labels"}
                    />
                },
                {  width: 2, content: 
                    <>
                        { order?.order_manager?.id ?
                            <Link target="_blank" to={routes.EMPLYOEES_DETAIL + order?.order_manager?.id}>
                                <span>{ order?.order_manager?.fullname }</span>
                            </Link>
                            : "--"
                        }
                    </>
                },
                { width: 2, content: 
                    <AvatarList 
                        size="25"
                        alignDirection="start"
                        persons={
                            order.assigned_to.map(item => ({
                                id: item.id,
                                name: item.fullname,
                                avatar: item.profile_picture,
                                displayName: item?.fullname_with_titles || item.fullname,
                            }))
                        }
                    />
                },
                { width: 2, content: <OrderState order={order} setData={setData}/> },
                // { content: <OrderStatus order={order}/> }
            ])}
        />
    );
};

export default ServiceOrder;