import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { useIsMount } from '@helpers/hooks';
// components
import { Form } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';

const EmployeeFilters = ({ filters, setFilters, allowedEmploymentOrder }) => {
    const { t } = useTranslation()
    const isMount = useIsMount()

    const seniority = useSelector((state) => state.choices.seniority_level_types)
    const [loading, setLoading] = useState(false)
    const [options, setOptions] = useState([])
    const [isExact, setIsExact] = useState(false)

    useEffect(() => {
        async function fetchSkills(){
            setLoading(true)
            const request = await requests.get(API.EMPLOYEES + "filter_skills/")

            if( request.status === 200 ){
                const skills = request.response
                const skillSeniorityOptions = []
                for (let i = 0; i < skills.length; i++) {
                    skillSeniorityOptions.push({ key: skills[i].id, value: skills[i].name, text: skills[i].name });
                    for (const property in seniority) {
                        skillSeniorityOptions.push({ key: skills[i].id, value: skills[i].name + "__" + property, text: skills[i].name + " - " + seniority[property] });
                    }
                }

                setOptions(skillSeniorityOptions)
            }

            setLoading(false)
        }

        fetchSkills()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (!isMount) {
            if (isExact) {
                handleSkillAndSeniorityFilter(filters.skill_and_seniority, false, true)
            } else {
                handleSkillAndSeniorityFilter(filters.skill_and_seniority_conjoined, false, true)
            }
        }
        // eslint-disable-next-line
    }, [isExact])

    const handleSkillAndSeniorityFilter = (filterValue, isValue, isExactChanged) => {
        let value = []
        if (isValue) {
            const splitValue = filterValue.split('--')
            for (let i = 0; i < splitValue.length; i++) {
                if (splitValue[i] !== "") {
                    value.push(splitValue[i])
                }
            }
        } else {
            if (isExactChanged) {
                let values = []
                const splitValue = filterValue.split('--')
                for (let i = 0; i < splitValue.length; i++) {
                    if (splitValue[i] !== "") {
                        values.push(splitValue[i])
                    }
                }

                filterValue = values
            }

            let skillString = ""
            let skill_and_seniority = ""
            let skill_and_seniority_conjoined = ""
            for (let i = 0; i < filterValue.length; i++) {
                if (i + 1 === filterValue.length) {  // last item
                    skillString += filterValue[i];
                } else {
                    skillString += filterValue[i] + "--";
                }
            }
            if (isExact) {
                skill_and_seniority = ""
                skill_and_seniority_conjoined = skillString
            } else {
                skill_and_seniority_conjoined = ""
                skill_and_seniority = skillString
            }

            setFilters(prev => ({...prev, 
                skill_and_seniority: skill_and_seniority,
                skill_and_seniority_conjoined: skill_and_seniority_conjoined
            }))
        }
    
        return value
    }


    return (
        <>
            <Form.Group widths="equal">
                <SuperField as="choice"
                    search
                    value={filters.language}
                    text="title"
                    endpoint={API.COMMON + "language_names/"}
                    label={t('language')}
                    onChange={(e, { value }) => setFilters({
                        ...filters,
                        language: value
                    })}
                />

                <SuperField as="choice"
                    search
                    value={filters.languageLevel}
                    type="language_level_choices"
                    label={t('language_level')}
                    onChange={(e, { value }) => setFilters({
                        ...filters,
                        language_level: value
                    })}
                />
            </Form.Group>

            <SuperField as="choice"
                search
                multiple
                disabled={loading}
                loading={loading}
                customOptions={options}
                label={ t('skills') }
                value={handleSkillAndSeniorityFilter(isExact ? filters.skill_and_seniority_conjoined : filters.skill_and_seniority, true)}
                onChange={(e, { value }) => handleSkillAndSeniorityFilter(value, false)}
            />
            <Form.Field>
                <SuperField as="checkbox"
                    label={t('all_selected_skills_must_match')}
                    checked={isExact}
                    onChange={() => setIsExact(!isExact)}
                />
            </Form.Field>

            <SuperField as="choice"
                search
                multiple
                value={filters.contact_labels}
                label={t('labels')}
                endpoint={API.CONTACT_LABELS}
                text="name"
                onChange={(e, { value }) => setFilters({
                    ...filters,
                    contact_labels: value
                })}
            />
            
            <Form.Group widths="equal">
                <SuperField as="choice"
                    value={filters.gender}
                    type="genders"
                    label={t('gender')}
                    onChange={(e, { value }) => setFilters({
                        ...filters,
                        gender: value
                    })}
                />
                <SuperField as="choice"
                    label={t('show_duplicates')}
                    value={filters.show_duplicates}
                    customOptions={[
                        { key: 1, value: true, text: t('yes') },
                        { key: 2, value: false, text: t('no') },
                    ]}
                    onChange={(e, { value }) => setFilters(prev => ({
                        ...prev,
                        show_duplicates: value
                    }))}
                />
            </Form.Group>

            <Form.Group widths="equal">
                <SuperField as="choice"
                    search
                    value={filters.position}
                    endpoint={API.JOB_POSITIONS + "?query={id, title}"}
                    text="title"
                    label={t('position')}
                    onChange={(e, { value }) => setFilters({
                        ...filters,
                        position: value
                    })}
                />
                <SuperField as="choice"
                    search
                    value={filters.unit}
                    endpoint={API.UNITS + "?query={id, name}&only_from_active_company=true"}
                    text="name"
                    label={t('unit')}
                    onChange={(e, { value }) => setFilters({
                        ...filters,
                        unit: value
                    })}
                />
            </Form.Group>
            <Form.Group widths="equal">
                <SuperField as="choice"
                    label={t('active_contract')}
                    value={filters.have_active_contract}
                    customOptions={[
                        { key: 1, value: true, text: t('yes') },
                        { key: 2, value: false, text: t('no') },
                    ]}
                    onChange={(e, { value }) => setFilters(prev => ({
                        ...prev,
                        have_active_contract: value
                    }))}
                />
                {allowedEmploymentOrder && 
                    <SuperField as="choice"
                        search
                        text="name"
                        label={t('employment_order')}
                        value={filters.employment_order_active_only}
                        endpoint={API.ORDERS + "?only_basic_info=true&is_active=true&resource_type=employmentorder"}
                        onChange={(e, { value }) => setFilters({
                            ...filters,
                            employment_order_active_only: value
                        })}
                    />
                }
            </Form.Group>
            <SuperField as="choice"
                label={t('multiple_active_contract')}
                value={filters.has_multiple_active_contracts}
                customOptions={[
                    { key: 1, value: true, text: t('yes') },
                    { key: 2, value: false, text: t('no') },
                ]}
                onChange={(e, { value }) => setFilters(prev => ({
                    ...prev,
                    has_multiple_active_contracts: value
                }))}
            />

            <SuperField as="checkbox"
                label={t('not_assigned_unit')}
                checked={filters.not_assigned_unit === true}
                onChange={() => setFilters(prev => ({
                    ...prev,
                    not_assigned_unit: filters.not_assigned_unit === true ? "" : true
                }))}
            />
        </>
    );
};

export default EmployeeFilters;