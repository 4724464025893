import moment from 'moment';
import { store } from "../../../store/";

function getTimezoneFromRedux() {
    const state = store.getState();
    return state?.timezone || 0
}

function getLanguageFromRedux() {
    const state = store.getState();
    return state?.language || "en"
}

store.subscribe(getTimezoneFromRedux)
store.subscribe(getLanguageFromRedux)

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

export const dates = {
    dateToMY,
    dateToMY_to_String,
    diffDays,
    convertUTCDateToLocalDate,
};

function dateToMY(date) {
    //let d = date.getDate();
    let m = date.getMonth() + 1; //Month from 0 to 11
    let y = date.getFullYear();
    return (m <= 9 ? '0' + m : m) + '/' + y;
}

function dateToMY_to_String(date) {
    const splitted_date = date.split('/');
    let month = splitted_date[0];
    const year = splitted_date[1];

    if (month.startsWith('0')) {
        // remove 0 from string
        month = month.substring(1);
    }

    return months[parseInt(month - 1)] + ' ' + year;
}

function diffDays(date, otherDate) {
    date = new Date(date);
    otherDate = new Date(otherDate);
    return Math.ceil(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24));
}

function GetFormattedDate(date) {
    var month = ('0' + (date.getMonth() + 1)).slice(-2);
    var day = ('0' + date.getDate()).slice(-2);
    var year = date.getFullYear();
    var hour = ('0' + date.getHours()).slice(-2);
    var min = ('0' + date.getMinutes()).slice(-2);
    var seg = ('0' + date.getSeconds()).slice(-2);
    return year + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + seg;
}

function convertUTCDateToLocalDate(date) {
    date = new Date(date);
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return GetFormattedDate(newDate);
}

export function addMonths(date, months) {
    date.setMonth(date.getMonth() + months);
    return date;
}

export function getTimezoneOffset() {
    let offset = 0
    const currentOffsetInHours = new Date().getTimezoneOffset() / 60

    if (currentOffsetInHours > 0) offset = currentOffsetInHours * -1 
    if (currentOffsetInHours < 0) offset = currentOffsetInHours * -1 


    return offset
}

export const setLocaleLanguage = () => {
    let language = getLanguageFromRedux()
    if (language === "sk") return "sk"
    if (language === "ua") return "uk"
    if (language === "ru") return "ru"
    if (language === "cz") return "cs"

    return "en"
}

export function tzDateTime(datetime, backToUTC) {
    let offset = getTimezoneFromRedux()
    let defaultFormat = "YYYY-MM-DD HH:mm:ss"
    // eslint-disable-next-line
    let convertOffsetToFloat = parseFloat(offset) || 0
    let momentDatetime = moment(datetime, defaultFormat)

    // if (convertOffsetToFloat === 0) { // UTC+0 = default attempt to convert to browser timezone based on device location
    if (backToUTC) {
        momentDatetime = momentDatetime.add(new Date().getTimezoneOffset() / 60, "hours")
    } else {
        momentDatetime = moment(convertUTCDateToLocalDate(moment(momentDatetime).format(defaultFormat)))
    }


    // } else {
    //     if (backToUTC) {
    //         momentDatetime = momentDatetime.add(parseFloat(convertOffsetToFloat * - 1), 'hours')
    //     } else {
    //         momentDatetime = momentDatetime.add(convertOffsetToFloat, 'hours')
    //     }
    // }
    
    return momentDatetime
}