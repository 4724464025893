import React, { useState } from 'react';
import { useTranslation } from "react-i18next"
// store
import { API } from '@store/config';
import { useLocation, useHistory } from 'react-router-dom';
//components
import SuperField from '@components/forms/SuperField';
import CustomFormDivider from '@components/general/CustomFormDivider';
import { Divider, Button, Form, List, Header  } from 'semantic-ui-react';


const IssueFiltersForm = ({ fetchData, setVisible, initState, filters, setFilters, project_id }) => {

    const { t } = useTranslation();
    const location = useLocation()
    const history = useHistory()
    const [processing, setProcessing] = useState(false)

    const onFilter = async () => {
        setProcessing(true)
        history.replace({ pathname: location.pathname, search: `?page=${1}` });
        await fetchData('', true)
        setProcessing(false)
    }

    const resetFilters = () => {
        setFilters(initState)
    }

    return (
        <div style={{ marginBottom: "5rem"  }}>
            <List divided verticalAlign='middle'>
                <List.Item>
                    <List.Content>
                        <Header as="h2" content={t('advanced_filter')} style={{ marginTop: "1rem", paddingLeft: "1rem", textAlign: "left" }} />
                    </List.Content>
                </List.Item>
            </List>
            <Form style={{ paddingLeft: "1rem", paddingRight: "1rem", textAlign: "left" }}>
                <SuperField as="choice"
                    type="issue_state_choices"
                    label={t('state')}
                    value={filters.state}
                    onChange={ (e, {value}) => setFilters({ ...filters, state: value }) }
                />
                <SuperField as="choice"
                    search
                    text="name"
                    label={t('milestone')}
                    value={filters.milestone}
                    onChange={ (e, {value}) => setFilters({ ...filters, milestone: value }) }
                    endpoint={API.PROJECT_MILESTONES + `?project=${project_id}`}
                />
                <Divider/>

                <Form.Group widths="equal">
                    <SuperField as="choice"
                        search
                        text="title"
                        label={t('stage')}
                        value={filters.stage}
                        onChange={ (e, {value}) => setFilters({ ...filters, stage: value }) }
                        endpoint={API.PROJECT_STAGES + `?project=${project_id}`}
                    />
                    <SuperField as="choice"
                        search
                        text="title"
                        label={t('group')}
                        value={filters.group}
                        onChange={ (e, {value}) => setFilters({ ...filters, group: value }) }
                        endpoint={API.PROJECT_GROUPS + `?project=${project_id}`}
                    />
                </Form.Group>



                <SuperField as="choice"
                    search
                    multiple
                    value={filters.labels}
                    label={t('labels')}
                    endpoint={API.PROJECT_LABELS}
                    text="name"
                    onChange={(e, { value }) => setFilters({
                        ...filters,
                        labels: value
                    })}
                />
                <CustomFormDivider
                    header = {t('assigned_users')}
                    divider = {true}
                    description = {t('my_issues_message')}
                    content = {
                        <>
                        <SuperField as="checkbox"
                            label={t('my_issues')}
                            width={10}
                            checked={filters.onlyCurrentUser}
                            onChange={() => setFilters({...filters, onlyCurrentUser: !filters.onlyCurrentUser})}
                        />
                    </>
                    }
                />
                <SuperField as="choice"
                    search
                    multiple
                    value={filters.assigned}
                    label={t('assigned')}
                    endpoint={API.EMPLOYEES + "?only_basic_info=true"}
                    text="fullname"
                    onChange={(e, { value }) => setFilters({
                        ...filters,
                        assigned: value
                    })}
                />

                <CustomFormDivider
                    header = {t('date_filters')}
                    divider = {true}
                    content = {
                        <Form.Group widths="equal">
                            <SuperField as="datepicker"
                                label={ t('start_date') }
                                value={filters.startDate}
                                onChange={ (e, { value }) => setFilters({...filters, startDate: value})}
                                />
                            <SuperField as="datepicker"
                                label={ t('end_date') }
                                value={filters.endDate}
                                onChange={ (e, { value }) => setFilters({...filters, endDate: value})}
                            />
                        </Form.Group>
                    }
                />

                <Form.Field style={{ textAlign: "right"}}>
                    <Button size="medium" content={t('reset')} style={{}} onClick={() => resetFilters()}/>
                    <Button loading={processing} disabled={processing} size="medium" content={t('apply')}
                        style={{backgroundColor: "var(--primary)", color:"white"}}
                        onClick={() => {
                            onFilter()
                            setVisible(false)}
                    }/>
                </Form.Field>
            </Form>
        </div>
    );
};

export default IssueFiltersForm;