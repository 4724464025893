import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { useHasPermission } from '@helpers/hooks';
import { thousandsSeparators } from '@helpers/functions';
// components
import Icon from '@components/Icon';
import CanView from '@components/perms/CanView';
import SuperField from '@components/forms/SuperField';
import NonFieldErrors from '@components/NonFieldErrors';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import { Form, Divider, Header, Button, Popup } from 'semantic-ui-react';
import OrderItemForm from './OrderItemForm';

const SaleOrderForm = ({ customSubmitHandler, isPurchase, record, setData, setTotal, onClose, accounts, employees, owners }) => {
    const { t } = useTranslation()
    const user = useSelector(state => state.user)

    const canManageCC = useHasPermission('cost_centers.c_manage_cost_centers')

    const [processing, setProcessing] = useState(false)
    const [catalogue, setCatalogue] = useState([])
    const [loadingCatalogue, setLoadingCatalogue] = useState(false)
    const [loading, setLoading] = useState(false)
    const [createCCFromOrder, setCreateCCFromOrder] = useState(false)
    const [costCenters, setCostCenters] = useState([])
    const [errors, setErrors] = useState(null)
    const [form, setForm] = useState({
        name: record?.name || "",
        order_number: record?.order_number || "",
        business_detail: record?.business_detail?.id || "",
        // project: record?.project?.id || "",
        order_manager: record?.order_manager?.id || "",
        order_type: record?.order_type || 2,
        owner: record?.owner?.id || "",
        cost_center: record?.cost_center?.id || "",
        delivery_date: record?.delivery_date || "",
        business_contract_reference: record?.business_contract_reference || "",
        note: record?.note || "",

        // labels: record?.labels?.map(item => item.id) || [],
        assigned_to: record?.assigned_to?.map(item => item.id) || [],
        order_status: record?.order_status || 1,
        is_active: record?.is_active !== undefined ? record?.is_active : true,
    })

    const [items, setItems] = useState(record?.items?.map(recordItem => ({
        id: recordItem?.id || 0,
        item: recordItem?.item.id || "",
        item_title: recordItem?.item ? (recordItem?.item?.code ? recordItem?.item?.code  + " - " : "") + recordItem?.item?.title : "",
        weight_is_primary_measurement: recordItem?.item.weight_is_primary_measurement || false,
        abbreviation: recordItem?.item.measure_unit?.abbreviation || "",
        quantity: recordItem?.quantity || 1,
        currency: recordItem?.currency || "EUR",
        price_without_tax: recordItem?.price_without_tax || "",
        price_with_tax: recordItem?.price_with_tax || "",
        tax: recordItem?.tax || "",
        note: recordItem?.note || "",
    })) || [])

    useEffect(() => {
        async function fetchCostCenters() {
            setLoading(true)
            const request = await requests.get(API.COST_CENTERS + "?query={id, title}")
            if (request.status === 200) setCostCenters(request.response)
            setLoading(false)
        }

        fetchCostCenters()
    }, [])

    useEffect(() => {
        async function fetchCatalogue() {
            setLoadingCatalogue(true)
            const request = await requests.get(API.CATALOGUE_ITEMS + "?is_archived=false")
            if (request.status === 200) setCatalogue(request.response)
            setLoadingCatalogue(false)
        }

        fetchCatalogue()
    }, [])

    useEffect(() => {
        if (owners.length === 1) {
            setForm(prev => ({...prev, owner: owners?.[0]?.value || ""}))
        }
    }, [owners])

    const createCCAndUpdateOrder = async (response) => {
        const request = await requests.post(API.COST_CENTERS, {
            title: response.name,
            unit: response?.owner?.id || null
        })

        if (request.status === 201) {
            // update record
            const updateRequest = await requests.patch(API.ORDERS + response.id + "/", {
                cost_center: request.response?.id,
                resourcetype: "SalesOrder",
            }) 

            if (updateRequest.status === 200) {
                return updateRequest.response
            }
        }

        return response
    } 

    const calculateTotal = (unit_price, quantity) => {
        let total = 0

        if (unit_price > 0 && quantity > 0) {
            total = parseFloat(unit_price * quantity).toFixed(2)
        }

        return total
    }

    const handleSubmit = async () => {
        setProcessing(true)
        setErrors(null)

        let data = {
            is_purchase_order: isPurchase,
            name: form.name,
            order_number: form.order_number,
            order_type: form.order_type,
            resourcetype: "SaleOrder",
            order_manager: form.order_manager !== "" ? form.order_manager : null ,
            delivery_date: form.delivery_date !== "" ? form.delivery_date : null ,
            business_contract_reference: form.business_contract_reference,
            note: form.note,
            // project: form.project !== "" ? form.project : null,
            business_detail: form.business_detail !== "" ? form.business_detail : null,
            cost_center: form.cost_center !== "" ? form.cost_center : null,
            is_active: form.is_active,
            order_status: form.order_status,
            owner: form.owner !== "" ? form.owner : null,
        }

        if (items.length > 0) {
            let toCreate = items.filter(data => data.id === 0)
            let toUpdate = items.filter(data => data.id > 0)

            data["items"] = {}
            if(toCreate.length > 0) {
                data["items"]["create"] = toCreate.map(item => ({
                    item: item.item,
                    quantity: item.quantity,
                    currency: item.currency,
                    price_without_tax: item.price_without_tax || null,
                    price_with_tax: item.price_without_tax || null,
                    tax: item.tax || null,
                    total_price: calculateTotal(item.price_without_tax, item.quantity),
                    note: item.note
                }))
            }
            if(toUpdate.length > 0) {
                data.items['update'] = {}
                for (let i = 0; i < toUpdate.length; i++) {
                    data.items['update'][toUpdate[i].id] = {
                        item: toUpdate[i].item,
                        quantity: toUpdate[i].quantity,
                        currency: toUpdate[i].currency,
                        price_without_tax: toUpdate[i].price_without_tax || null,
                        price_with_tax: toUpdate[i].price_without_tax || null,
                        tax: toUpdate[i].tax || null,
                        total_price: calculateTotal(toUpdate[i].price_without_tax, toUpdate[i].quantity),
                        note: toUpdate[i].note
                    }
                }
            }
        }

        // handle data processing and api calls
        if (record === undefined) { // its create request
            if (form.assigned_to.length > 0) data['assigned_to'] = { add: form.assigned_to }

            const request = await requests.post(API.ORDERS, data)
            if (request.status === 400) setErrors(request.response)
            if (request.status === 201) {
                let response = null
                if (createCCFromOrder) {
                    response = await createCCAndUpdateOrder(request.response)
                } else {
                    response = request.response
                }

                if (customSubmitHandler) {
                    customSubmitHandler(response)
                } else {
                    setTotal(prev => prev + 1)
                    setData(prev => ([response, ...prev]))
                    onClose()
                }
            }

        } else { // its update request
            // data['labels'] = { remove: record.labels.map(item => item.id), add: form.labels }
            data['assigned_to'] = { remove: record.assigned_to.map(item => item.id), add: form.assigned_to }

            const request = await requests.patch(API.ORDERS + record.id + "/", data)
            if (request.status === 400) setErrors(request.response)
            if (request.status === 200) {
                if (customSubmitHandler) {
                    customSubmitHandler(request.response)
                } else {
                    setData(prev => prev.map(item => {
                        if (item.id === record.id) {
                            item = request.response
                        }
                        return item
                    }))
                    onClose()
                }
            }
        }

        setProcessing(false)
    }

    const handleAddCostCenter = async (value) => {
        setLoading(true)
        const request = await requests.post(API.COST_CENTERS, {
            title: value,
            unit: form.owner?.id || null
        })

        if (request.status === 201) {
            setForm(prev => ({...prev, cost_center: request.response.id}))
            setCostCenters(prev => [...prev, request.response])
        }
        setLoading(false)
    }

    const onDelete = async (item, idx) => {
        if (item.id === 0) {
            setItems(prev => prev.filter((item, index) => index !== idx))
        } else {
            const request = await requests.post(API.ORDERS + "order_items/" + item.id + "/delete/")
            if (request.status === 200) {
                setItems(prev => prev.filter((listItem, index) => listItem.id !== item.id))
            }
        }
    }

    return (
        <Form onSubmit={handleSubmit}>
            { record !== undefined && (
                <>
                    <Header as="h3" content={t('update_order')}/>
                    <Divider/>
                </>
            )}

            <NonFieldErrors errors={errors} />

            <Form.Group>
                <SuperField as="input"
                    required
                    autoFocus
                    width="12"
                    label={t('name')}
                    value={form.name}
                    error={errors?.name?.[0] || false}
                    onChange={(e, { value }) => setForm(prev => ({...prev, name: value}))}
                />
                <SuperField as="input"
                    width="4"
                    label={t('order_number')}
                    value={form.order_number}
                    error={errors?.order_number?.[0] || false}
                    onChange={(e, { value }) => setForm(prev => ({...prev, order_number: value}))}
                />
            </Form.Group>

            { (record === undefined && canManageCC) && // allow to create CC from order name when creating new service order
                <SuperField as="checkbox"
                    label={t('create_cost_center_from_order')}
                    checked={createCCFromOrder}
                    onChange={() => setCreateCCFromOrder(!createCCFromOrder)}
                />
            }

            { isPurchase 
                ? 
                    <Form.Group widths="equal">
                        <SuperField as="choice"
                            search
                            required
                            value={form.business_detail}
                            label={t('supplier')}
                            customOptions={accounts}
                            error={errors?.business_detail?.[0] || false}
                            onChange={(e, { value }) => setForm(prev => ({...prev, business_detail: value}))}
                        />
                        <SuperField as="choice"
                            search
                            required
                            value={form.owner}
                            label={t('account')}
                            customOptions={owners}
                            error={errors?.owner?.[0] || false}
                            onChange={(e, { value }) => setForm(prev => ({...prev, owner: value}))}
                        />
                    </Form.Group>
                : 
                    <Form.Group widths="equal">
                        <SuperField as="choice"
                            search
                            required
                            value={form.owner}
                            label={t('supplier')}
                            customOptions={owners}
                            error={errors?.owner?.[0] || false}
                            onChange={(e, { value }) => setForm(prev => ({...prev, owner: value}))}
                        />
                        <SuperField as="choice"
                            search
                            required
                            value={form.business_detail}
                            label={t('account')}
                            customOptions={accounts}
                            error={errors?.business_detail?.[0] || false}
                            onChange={(e, { value }) => setForm(prev => ({...prev, business_detail: value}))}
                        />
                    </Form.Group>
            }
            <Divider/>

            <Form.Group widths="equal">
                <CanView permissions={["orders.c_assign_order_manager"]}>
                    <Form.Field>
                        <SuperField as="choice"
                            search
                            label={t('order_manager')}
                            value={form.order_manager}
                            customOptions={employees}
                            error={errors?.order_manager?.[0] || false}
                            onChange={(e, { value }) => setForm(prev => ({...prev, order_manager: value}))}
                        />
                        { user.profile?.id && 
                            <SuperField as="checkbox"
                                style={{ marginTop: "1rem" }}
                                label={t('assign_me_as_order_manager')}
                                checked={form.order_manager === user.profile?.id}
                                onChange={() => {
                                    if (form.order_manager !== user.profile?.id) {
                                        setForm(prev => ({
                                            ...prev,
                                            order_manager: user.profile?.id || "",
                                        }))
                                    } else {
                                        setForm(prev => ({ ...prev, order_manager: ""}))
                                    }
                                }}
                            />
                        }
                    </Form.Field>
                </CanView>
                <SuperField as="choice"
                    search
                    loading={loading}
                    label={t('cost_center')}
                    value={form.cost_center}
                    disabled={createCCFromOrder || loading}
                    allowAdditions={canManageCC}
                    onAddItem={(e, { value }) => handleAddCostCenter(value)}
                    onChange={(e, { value }) => setForm(prev => ({...prev, cost_center: value}))}
                    customOptions={costCenters.map(item => ({ key: item.id, value: item.id, text: item.title }))}
                />
            </Form.Group>

            <Form.Group widths="equal">
                <SuperField as="choice"
                    search
                    multiple
                    label={t('responsible_persons')}
                    value={form.assigned_to}
                    customOptions={employees}
                    onChange={(e, { value }) => setForm({ ...form, assigned_to: value })}
                />
            </Form.Group>

            <Divider/>
            <SuperField as="textarea"
                label={t('note')}
                value={form.note}
                onChange={(e, { value }) => setForm({ ...form, note: value })}
            />
            <Form.Group widths="equal">
                <SuperField as="input"
                    label={t('business_contract_reference')}
                    value={form.business_contract_reference}
                    onChange={(e, { value }) => setForm({ ...form, business_contract_reference: value })}
                />
                {/* <SuperField as="datepicker"
                    label={t('delivery_date')}
                    value={form.delivery_date}
                    onChange={(e, { value }) => setForm({ ...form, delivery_date: value })}
                /> */}
            </Form.Group>
            { record?.id !== undefined &&
                <Form.Group widths="equal">
                    <SuperField as="choice"
                        label={t('state')}
                        disabled={record?.id === undefined || [undefined, null, ""].includes(record?.confirmed_on) }
                        type="order_status_choices"
                        value={form.order_status?.toString() || "1"}
                        onChange={(e, { value }) => setForm({ ...form, order_status: value })}
                    />
                    <SuperField as="choice"
                        label={t('status')}
                        value={form.is_active}
                        customOptions={[
                            { key: 0, value: true, text: t('active_order') },
                            { key: 1, value: false, text: t('closed_order') },
                        ]}
                        onChange={(e, { value }) => setForm({ ...form, is_active: value })}
                    />
                </Form.Group>
            }

            <Divider/>

            {/* <Header as="h3" content={t('ordered_items')} style={{ marginBottom: "0.5rem" }}/> */}
            <Form.Group style={{ fontWeight: "bold" }}>
                <Form.Field width="10">
                    { t('item') }
                </Form.Field>
                <Form.Field width="5">
                    { t('unit_price') }
                </Form.Field>
                <Form.Field width="5">
                    { t('quantity') }
                </Form.Field>
                <Form.Field width="5">
                    { t('price_without_tax') }
                </Form.Field>
                <Form.Field width="5">
                    { t('note') }
                </Form.Field>
                <Form.Field width="2" style={{ textAlign: "center" }}>
                    { t('actions') }
                </Form.Field>
            </Form.Group>
            <Divider/>

            { items.length === 0 && 
                <>
                    <p style={{ textAlign: "center" }}>
                        { t('no_item_has_been_added_yet') }
                    </p>
                    <Divider/>
                </>
            }
            { items.map((item, idx) => (
                <>
                    <Form.Group>
                    <Form.Field width="10">
                        { item?.item_title }
                    </Form.Field>
                    <Form.Field width="5">
                        { item.price_without_tax }  { item.currency } 
                    </Form.Field>
                    <Form.Field width="5">
                        { item.quantity }{ item.weight_is_primary_measurement ? ` ${item?.abbreviation || ""}` : "" }
                    </Form.Field>
                    <Form.Field width="4">
                        { thousandsSeparators((item.price_without_tax * item.quantity) || 0) } { item.currency || "EUR" }
                    </Form.Field>
                    <Form.Field width="5">
                        { item.note && 
                            <Popup
                                position="center top"
                                trigger={
                                    <Icon name="document-outline" style={{ marginLeft: "0.5rem" }}/>
                                }
                                content={
                                    <div>
                                        { item.note }
                                    </div>
                                }
                            />
                        }
                        </Form.Field>
                        <Form.Field width="2" style={{ textAlign: "center" }}>
                            <SuperDuperModal
                                trigger={
                                    <Icon name="pencil-outline" style={{ color: "var(--dark)", cursor: "pointer", marginRight: "0.5rem" }}/>
                                }
                                content={<OrderItemForm catalogue={catalogue} setRecords={setItems} record={item} idx={idx}/>}
                            />
                            <Icon
                                name="close-outline"
                                style={{ 
                                    color: "var(--danger)",
                                    cursor: "pointer"
                                }}
                                onClick={() => onDelete(item, idx)}
                            />
                        </Form.Field>
                    </Form.Group>
                    <Divider/>
                </>
            ))}
            <SuperDuperModal
                trigger={
                    <Button disabled={loadingCatalogue} primary type="button" size="tiny" content={t('add_item')}/>
                }
                content={<OrderItemForm setRecords={setItems} catalogue={catalogue}/>}
            />

            <Divider/>
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose}/>
                <ModalSubmit
                    loading={processing}
                    disabled={processing || form.name === "" || form.account === "" || form.owner === ""}
                    text={t('confirm')}
                />
            </Form.Field>
        </Form>
    );
};

export default SaleOrderForm;