import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { dates } from '@store/services/helpers/dates';
// store
import { routes } from '@routes';
import { API } from '@store/config';
// components
import { Icon } from 'semantic-ui-react';
import ListView from '@components/ListView';
import SuperField from '@components/forms/SuperField';
import { FlexItem } from '@components/tables/FlexTable';
import CanView from '@components/perms/CanView';
import {
    ProjectApproval,
    BudgetApproval,
    InvoiceApproval,
    InvoiceApprover,
    RecruitmentStageApproval,
    RecruitmentApproval,
    PlannedCourseApproval,
    TimesheetApproval
} from '../components/Approvals'

const ApprovedApprovals = () => {
    const { t } = useTranslation();
    const dateFormat = useSelector(state => state.date_format)
    const user = useSelector(state => state.user)

    const getResourceName = (name) => {
        if(name === "RecruitmentApproval") return t('recruitment')
        if(name === "PlannedCourseApproval") return t('planned_course')
        if(name === "InvoiceApproval") return t('invoice')
        if(name === "AttendanceRecordPlannerApproval") return t('attendance_record_planner')
        if(name === "BenefitApproval") return t('benefits')
        if(name === "CostCenterRecordApproval") return t('cost_center_record')
        if(name === "EventAbsenceApproval") return t('event_absence')
        if(name === "ProjectApproval") return t('projects')
        if(name === "RecruitmentStageApproval") return t('recruitment_stage')
        if(name === "TimesheetApproval") return t('timesheet')
    }

    let stateOptions = [
        { key: 1, text: t('invoices'), value: 'invoice' },
        { key: 2, text: t('budget'), value: 'budget' },
        { key: 3, text: t('planned_courses'), value: 'plannedcourse' },
        { key: 4, text: t('projects'), value: 'project' },
        { key: 5, text: t('recruitments'), value: 'recruitment' },
        { key: 6, text: t('recruitments_stage_move'), value: 'recruitmentstage' },
    ];

    return (
        <CanView permissions={['approvals.c_view_all_approvals', 'approvals.c_view_user_approvals']} route={routes.APPROVALS + "approved/"} redirect>
            <ListView
                as="table"
                endpoint={API.APPROVALS}
                query={`&awaiting_approval=false&is_approved=true`}
                initialFilters={{
                    resource_type: "",
                    responsible_person: "",
                    approved_by: ""
                }}
                listAdditionActions={() => (
                    [
                        {
                            as: "link",
                            name: t('pending'),
                            redirect: routes.APPROVALS
                        },
                        {
                            as: "link",
                            name: t('approved'),
                            redirect: routes.APPROVALS + "approved/",
                            isActive: true
                        },
                        {
                            as: "link",
                            name: t('denied'),
                            redirect: routes.APPROVALS + "denied/"
                        },
                    ])}
                renderFilterFields={(filters, setFilters) => (
                    <>
                        <CanView permissions={["approvals.c_view_all_approvals"]}>
                            <SuperField
                                as="choice"
                                search
                                label={t('responsible_person')}
                                endpoint={API.EMPLOYEES + "?only_basic_info=true"}
                                text="fullname"
                                value={filters.responsible_person}
                                onChange={(e, { value }) => setFilters({
                                    ...filters,
                                    responsible_person: value
                                })}
                            />
                            <SuperField
                                as="choice"
                                label={t('resource')}
                                search
                                customOptions={stateOptions}
                                value={filters.resource_type}
                                onChange={(e, { value }) => setFilters({
                                    ...filters,
                                    resource_type: value
                                })}
                            />
                            <SuperField
                                as="choice"
                                search
                                label={t('approved_by')}
                                endpoint={API.EMPLOYEES + "?only_basic_info=true&is_active=true"}
                                text="fullname"
                                value={filters.approved_by}
                                onChange={(e, { value }) => setFilters({
                                    ...filters,
                                    approved_by: value
                                })}
                            />
                        </CanView>
                    </>
                )}
                tableHeaders={
                    [
                        { title: t('requested_approval') },
                        { title: t('resource') },
                        { title: t('requested_when') },
                        { title: t('approval_status') },
                        { title: t('approved_when') },
                        { title: t('responsible_person') },
                    ]
                }
                renderCells={(approval) => (
                    [
                        {
                            content:
                                <>
                                    {approval.resourcetype === "RecruitmentApproval" && <RecruitmentApproval approval={approval} />}
                                    {approval.resourcetype === "PlannedCourseApproval" && <PlannedCourseApproval approval={approval} />}
                                    {approval.resourcetype === "ProjectApproval" && <ProjectApproval approval={approval} />}
                                    {approval.resourcetype === "BudgetApproval" && <BudgetApproval approval={approval} />}
                                    {approval.resourcetype === "InvoiceApproval" && <InvoiceApproval approval={approval} user={user} />}
                                    {approval.resourcetype === "RecruitmentStageApproval" && <RecruitmentStageApproval approval={approval} />}
                                    {approval.resourcetype === "TimesheetApproval" && <TimesheetApproval approval={approval} />}
                                </>
                        },
                        { content: getResourceName(approval.resourcetype) },
                        { content: moment(dates.convertUTCDateToLocalDate(approval.created_on)).format(`${dateFormat} HH:mm`) },
                        { content: t('approved') },
                        approval.status !== null && ({
                            content: <FlexItem bold content={
                                <>
                                    {approval?.approval_datetime &&
                                        <>{moment(dates.convertUTCDateToLocalDate(approval.approval_datetime)).format(`${dateFormat} HH:mm`)} <br /></>
                                    }
                                    <small>
                                        {approval.approved_by?.fullname
                                            ? <Link className="ref-link" to={routes.EMPLYOEES_DETAIL + approval?.approved_by?.id} target="_blank">{approval.approved_by?.fullname}</Link>
                                            : "--"
                                        }
                                    </small>
                                </>
                            } />
                        }),
                        ({
                            content:

                                (approval.resourcetype === "InvoiceApproval"
                                    ?
                                    <>
                                        <InvoiceApprover
                                            approver={approval.responsible_person}
                                            decision={approval.approved_by_responsible_person}
                                            note={approval.responsible_person_note}
                                            type={'responsible_person'}
                                        />
                                        <InvoiceApprover
                                            approver={approval.second_responsible_person}
                                            decision={approval.approved_by_second_responsible_person}
                                            note={approval.second_responsible_person_note}
                                            type={'second_responsible_person'}
                                        />
                                        <InvoiceApprover
                                            approver={approval.reviewer}
                                            decision={approval.approved_by_reviewer}
                                            note={approval.reviewer_note}
                                            type={'reviewer_person'}
                                        />
                                        <InvoiceApprover
                                            approver={approval.second_reviewer}
                                            decision={approval.approved_by_second_reviewer}
                                            note={approval.second_reviewer_note}
                                            type={'second_reviewer_person'}
                                        />
                                    </>
                                    :
                                    <>
                                        {approval.responsible_person?.fullname
                                            ?
                                            <>
                                                <Icon name="circle outline" style={{ color: "transparent" }} />
                                                <Link className="ref-link" to={routes.EMPLYOEES_DETAIL + approval?.responsible_person?.id} target="_blank">{approval.responsible_person?.fullname}</Link>
                                            </>
                                            : ""
                                        }
                                    </>)

                        }),
                    ])}
            />
        </CanView>
    );
};

export default ApprovedApprovals;
