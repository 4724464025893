import React, { useEffect, useState } from "react";
//store
import { API } from "@store/config";
import { requests } from '@helpers/requests';
//components
import Documents from "./Documents";

const FilesTab = ({ id, module, getEndpoint, updateEndpoint, hideCreate, group_params, attachment_params }) => {

    const [groups, setGroups] = useState([])
    const [attachments, setAttachments] = useState([])
    // eslint-disable-next-line
    const [data, setData] = useState([])

    const fetchDataAttachements = async (id) => {
        if (attachment_params !== undefined) {
            const request = await requests.get(API.ATTACHMENTS + `${attachment_params || ""}`)

            if (request.status === 200) {
            setAttachments(request.response)
        }
        } else {
            const request = await requests.get(getEndpoint + id + `/?query={id,attachments}`)

            if (request.status === 200) {
                setAttachments(request.response.attachments)
            }
        }
    }


    const fetchDataAttachementsGroups = async () => {
        const request = await requests.get(API.ATTACHMENT_GROUPS + `?module=${module}${group_params || ""}`)

        if (request.status === 200) {
            setGroups(request.response)
        }
    }

    const updateRequest = async (response) => {

        const request = await requests.patch(updateEndpoint + id + '/', {
            attachments: { add: response?.id !== undefined ? [response.id] : response.map(item => item.id) }
        })

        if (request.status === 200) {
            setData(request.response)
        }
    }

    useEffect(() => {
        fetchDataAttachements(id)
        fetchDataAttachementsGroups()
        // eslint-disable-next-line
    }, [id])

    return (
        <Documents
            groups={groups}
            docs={attachments}
            setAttachments={setAttachments}
            setGroups={setGroups}
            update={(response) => updateRequest(response)}
            module={module}
            group_params={group_params}
            hideCreate={hideCreate}
        />
    )
};

export default FilesTab;