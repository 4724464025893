import React, {useState} from 'react';
import { useTranslation } from "react-i18next"
//components
import Icon from '@components/Icon';
import Address from '@components/forms/Address';
import {FlexItem, FlexRow} from '@components/tables/FlexTable';
import { Divider, Grid, Header } from 'semantic-ui-react';
// specific components
import PersonalInfoForm from '../forms/PersonalInfoForm';


const PersonalInfoSection = ({ data, setData, patchEndpoint }) => {
    const { t } = useTranslation()
    const [view, setView] = useState(0);

    return (
        <>
        <Grid stackable>
            <Grid.Row>
                <Grid.Column only="computer" computer="3" stretched style={{ borderRadius: "4px", paddingTop: "1rem", backgroundColor: "var(--light)"}}>
                    <FlexRow background="transparent" padding="0">
                        <FlexItem textAlign="center">
                            <Header icon >
                                <Icon name='person-outline' style={{ fontSize: "5rem" }}/>
                                <h3>{t('personal_information')}</h3>
                            </Header>
                        </FlexItem>
                    </FlexRow>
                </Grid.Column>
                <Grid.Column computer="12" tablet="16" mobile="16">
                    <Grid style={{ padding: 0 }} >
                        <Grid.Row style={{ padding: 0 }} >
                            <Grid.Column only="computer" computer="16">
                                <Header floated="left" as="h3" style={{ marginBottom: 0 }}> 
                                    <Icon name="person-outline" style={{ marginRight: "0.5rem" }}/> 
                                        <span style={{ position: "relative", top: "-0.2rem" }}>{t('personal_information')}</span> 
                                    </Header>
                                <Header floated="right" as="h4" style={{ marginBottom: 0 }}>
                                    { view === 0 ?
                                        <span style={{ cursor: "pointer" }} onClick={() => setView(1)}>
                                            <Icon name="pencil-outline" style={{ fontSize: "1rem", marginRight: "0.5rem" }} />
                                            <span style={{ position: "relative", top: "-0.2rem" }}>{t('edit')}</span>
                                        </span>
                                    :
                                        <span style={{ cursor: "pointer" }} onClick={() => setView(0)}>
                                            <Icon
                                                name="close-outline"
                                                style={{ 
                                                    fontSize: "1rem",
                                                    color: "var(--danger)",
                                                    marginRight: "0.5rem"
                                                }} 
                                            />
                                            <span style={{ position: "relative", top: "-0.2rem" }}>{t('cancel')}</span>
                                        </span>
                                    }
                                </Header>
                            </Grid.Column>
                            <Grid.Column style={{ padding: 0 }} only="mobile tablet" tablet="16" mobile="16">
                                <Header style={{ padding: 0 }} floated="left" as="h4">
                                    { view === 0 ?
                                        <span style={{ cursor: "pointer" }} onClick={() => setView(1)}>
                                            <span style={{ position: "relative", top: "-0.5rem" }}> { t('personal_information') } </span> 
                                            <Icon name="pencil-outline" style={{ marginLeft: "1rem", fontSize: "1rem"}}/>
                                        </span>
                                    :
                                        <span style={{ cursor: "pointer" }} onClick={() => setView(0)}>
                                            <span style={{ position: "relative", top: "-0.5rem" }}> { t('personal_information') } </span>
                                            <Icon name="close-outline" style={{ marginLeft: "1rem", fontSize: "1rem"}}/>
                                        </span>
                                    }
                                </Header>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                    <Divider />
                    { view === 1 ?
                        <PersonalInfoForm
                            data={data}
                            setView={setView}
                            patchEndpoint={patchEndpoint}
                            onConfirm={setData}
                        /> :
                    <Grid stackable>
                        <Grid.Row columns={5}>
                            <Grid.Column>
                                {t('firstname')} <br />
                                <strong>{data?.firstname || '~'}</strong>
                            </Grid.Column>
                            <Grid.Column>
                                {t('lastname')} <br />
                                <strong>{data?.lastname || '~'}</strong>
                            </Grid.Column>
                            <Grid.Column>
                                {t('middlename')} <br />
                                <strong>{data?.middlename || '~'}</strong>
                            </Grid.Column>
                            <Grid.Column>
                                {t('titles_before')} <br />
                                <strong>{data?.titles_before || '~'}</strong>
                            </Grid.Column>
                            <Grid.Column>
                                {t('titles_after')} <br />
                                <strong>{data?.titles_after || '~'}</strong>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{paddingTop: "1rem"}} columns={5}>
                            <Grid.Column>
                                {t('date_of_birth')} <br />
                                <strong>{data?.date_of_birth || '~'}</strong>
                            </Grid.Column>
                            <Grid.Column>
                                {t('place_of_birth')} <br />
                                <strong>{data?.place_of_birth || '~'}</strong>
                            </Grid.Column>
                            <Grid.Column>
                                {t('name_at_birth')} <br />
                                <strong>{data?.name_at_birth || '~'}</strong>
                            </Grid.Column>
                            <Grid.Column>
                                {t('gender')} <br />
                                <strong>{data?.gender_display || '~'}</strong>
                            </Grid.Column>
                            <Grid.Column>
                                {t('marital_status')} <br />
                                <strong>{data?.marital_status_display || '~'}</strong>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{paddingTop: "1rem"}} columns={5}>
                            <Grid.Column>
                                {t('personal_number')} <br />
                                <strong>{data?.personal_number || '~'}</strong>
                            </Grid.Column>
                            <Grid.Column>
                                {t('emergency_contact_name')} <br />
                                <strong>{data?.emergency_name || '~'}</strong>
                            </Grid.Column>
                            <Grid.Column>
                                {t('emergency_contact_phone')} <br />
                                <strong>{data?.emergency_phone || '~'}</strong>
                            </Grid.Column>
                            <Grid.Column>
                                {t('emergency_contact_email')} <br />
                                <strong>{data?.emergency_email || '~'}</strong>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{paddingTop: "1rem"}} columns={2}>
                            <Grid.Column>
                                {t('permanent_address')} <br />
                                <strong>
                                    <Address address={data?.permanent_address}/>
                                </strong>
                            </Grid.Column>
                            <Grid.Column>
                                {t('temporary_address')} <br />
                                <strong>
                                    <Address address={data?.temporary_address}/>
                                </strong>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
            }
                </Grid.Column>
            </Grid.Row>
        </Grid>
        </>

    );
};

export default PersonalInfoSection;