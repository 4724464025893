import React, { useState, useEffect } from 'react';
import moment from 'moment';
// store 
import { API } from '@store/config';
import { createRequest } from '@services/ServiceCommon';
// components
import Invoice from '../documents/Invoice';
import { Segment } from 'semantic-ui-react';

const CreateDocument = ({ connection, journal, setData, type, onClose, setSize }) => {
    const [isProcessing, setIsProcessing] = useState(true)
    const [document, setDocument] = useState(null)
    const [invoice, setInvoice] = useState(null)

    const handleDocumentCreate = async (isIssued) => {
        setIsProcessing(true)
        const request = await createRequest(API.INVOICES + "?connection=" + connection.connection_id + "&account_ids=" + journal.id, {
            invoice_type: 1, // invoice
            status: 4,
            is_issued: isIssued,
            payment_method: 1,
            tax_mode: 1,
            currency: "EUR",
            account: journal.account_id,
            supplier_registration_id: journal.id_number,
            date_issue: moment().format("YYYY-MM-DD"),
            date_supply: moment().format("YYYY-MM-DD"),
            date_due: moment().add(14, 'days').format('YYYY-MM-DD')
        })
        
        if( request.status === 201 ){
            setInvoice(request.response)
            setDocument("sent_invoice")
        }
        setIsProcessing(false)
    }

    useEffect(() => {
        if (type === "issued") {
            handleDocumentCreate(true)
        } else {
            setIsProcessing(true)
            setDocument("recieved_invoice") 
            setIsProcessing(false)
        }
        // eslint-disable-next-line
    }, [])

    useEffect( () => {
        if( document !== null ){
            setSize("large")
        } else {
            setSize("tiny")
        }
        // eslint-disable-next-line
    }, [document])

    return (
        <>
        { document === null
            ? 
            <Segment loading={isProcessing} style={{ 
                padding: 0, 
                margin: 0, 
                background: "transparent", 
                boxShadow: "none", 
                border: "none", 
                paddingTop: isProcessing ? "2rem" : 0,
                paddingBottom: isProcessing ? "2rem" : 0,
            }}/>
            : 
            <>
                { document === "recieved_invoice" && <Invoice document={document} connection={connection} journal={journal} setData={setData} onClose={onClose}/> }
                { document === "sent_invoice" && <Invoice invoice={invoice} document={document} connection={connection} journal={journal} setData={setData} onClose={onClose}/> }
            </>
        }
        </>
    );
};

export default CreateDocument;