import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
// store
import { API } from '@store/config';
import { routes } from '@routes';
import { requests } from '@helpers/requests';
// components
import Icon from '@components/Icon';
import ListView from '@components/ListView';
import AvatarIcon from '@components/AvatarIcon';
import CanView from '@components/perms/CanView';
import SuperField from '@components/forms/SuperField';
import PasswordForm from '../components/PasswordForm';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import { FlexRow, FlexItem } from '@components/tables/FlexTable';
import { Icon as SemanticIcon, Button, Form } from 'semantic-ui-react';
// module specific Components
import UserForm from '../components/UserForm';
import UserDemoForm from '../components/UserDemoForm';

const UsersList = () => {
    const { t } = useTranslation();
    const user = useSelector(state => state.user)
    const dateFormat = useSelector(state => state?.date_format || "YYYY-MM-DD")

    function getColor(type) {
        switch (type) {
            case 1:
                return 'var(--black)';
            case 2:
                return 'var(--primary)';
            case 3:
                return 'var(--blue)';
            case 4:
                return 'var(--info)';
            default:
                return 'var(--black)';
        }
    }

    const onCreate = (response, setData, setTotal) => {
        setData((prev) => [response, ...prev]);
        setTotal((prev) => prev + 1);
    };

    const onUpdate = (response, setData) => {
        setData((prev) =>
            prev.map((item) => {
                if (item.id === response.id) {
                    item = response;
                }
                return item;
            })
        );
    };

    return (
        <CanView permissions={['users.c_view_users']} redirect>
            <div style={{ marginTop: "0.5rem" }}>
                    <CanView permissions={['users.c_create_users']}>
                        { (window.location.hostname === "demo.hriis.io" || window.location.hostname === "demo.pulsawork.com") &&
                            <Form.Field style={{ textAlign: "right", marginBottom: "1rem", marginTop: "1rem" }}>
                                <SuperDuperModal
                                    size='small'
                                    header={t('create_user')}
                                    trigger={
                                        <Button size="small" style={{ background: "var(--dark)", color: "var(--white)" }} content={t('create_demo_user')} />
                                    }
                                    content={
                                        <UserDemoForm />
                                    }
                                />
                            </Form.Field>
                        }
                    </CanView>

                <ListView
                    as="table"
                    allowSearch
                    endpoint={API.USERS}
                    initialFilters={{
                        role: '',
                    }}
                    renderFilterFields={(filters, setFilters) => (
                        <>
                            <SuperField
                                search
                                as="choice"
                                type="roles"
                                label={t('role')}
                                value={filters.role}
                                onChange={(e, { value }) => setFilters((prev) => ({ ...prev, role: value }))}
                            />
                        </>
                    )}
                    listAdditionActions={() => [
                        {
                            as: 'filter',
                            index: 0,
                            name: t('active'),
                            filters: {
                                is_active: true,
                            },
                        },
                        {
                            as: 'filter',
                            index: 1,
                            name: t('inactive'),
                            filters: {
                                is_active: false,
                            },
                        },
                        {
                            as: 'filter',
                            index: 2,
                            name: t('all'),
                            filters: {
                                is_active: null,
                            },
                        },
                    ]}
                    actions={[
                        {
                            as: 'modal',
                            type: 'add',
                            name: t('create_user'),
                            modal: (
                                <UserForm
                                    onConfirm={(response, setData, setTotal) => onCreate(response, setData, setTotal)}
                                />
                            ),
                            permissions: ['users.c_create_users'],
                        },
                        {
                            as: 'confirm',
                            type: 'custom',
                            icon: 'checkmark-circle-outline',
                            text: t('activate'),
                            customIconColor: 'var(--success)',
                            isHidden: (item) => user.id === item.id ? true : item.is_active,
                            onClick: async (user, setData) => {
                                const request = await requests.patch(API.USERS + user.id + '/', {
                                    is_active: true,
                                });

                                if (request.status === 200) {
                                    setData((prev) =>
                                        prev.filter((updatedUser) => {
                                            if (user.id === updatedUser.id) {
                                                updatedUser.is_active = true;
                                            }

                                            return updatedUser;
                                        })
                                    );
                                }
                            },
                        },
                        {
                            as: 'confirm',
                            type: 'custom',
                            icon: 'ban-outline',
                            text: t('deactive'),
                            customIconColor: 'var(--danger)',
                            isHidden: (item) => user.id === item.id ? true : !item.is_active,
                            onClick: async (user, setData) => {
                                const request = await requests.patch(API.USERS + user.id + '/', {
                                    is_active: false,
                                });

                                if (request.status === 200) {
                                    setData((prev) =>
                                        prev.filter((updatedUser) => {
                                            if (user.id === updatedUser.id) {
                                                updatedUser.is_active = false;
                                            }

                                            return updatedUser;
                                        })
                                    );
                                }
                            },
                        },
                        {
                            as: 'modal',
                            type: 'edit',
                            name: t('edit'),
                            modalSize: 'small',
                            modal: (item, setData) => (
                                <UserForm
                                    user={item}
                                    setData={setData}
                                    onConfirm={(response, setData) => onUpdate(response, setData)}
                                />
                            ),
                        },
                        {
                            name: t('password_change'),
                            type: 'custom',
                            as: 'modal',
                            modalSize: 'tiny',
                            icon: 'lock-closed-outline',
                            text: t('password_change'),
                            permissions: ['users.c_manage_user_passwords'],
                            modal: (item) => <PasswordForm user={item} />,
                        },
                    ]}
                    tableHeaders={[
                        { title: t('user') },
                        { title: t('email') },
                        { title: t('profile') },
                        { title: t('last_login') },
                        { title: t('date_joined') },
                        { title: t('is_active') },
                    ]}
                    renderCells={(user, setData) => [
                        {
                            content: (
                                <FlexRow background="transparent" padding="0" fontSize="0.9rem">
                                    <FlexItem basis="10%">
                                        <SemanticIcon.Group style={{ marginRight: '0.5rem', padding: '0.5rem' }}>
                                            <AvatarIcon size={30} name={user.username} src={user.profile_picture} />
                                        </SemanticIcon.Group>
                                    </FlexItem>
                                    <FlexItem>
                                        <strong style={{ fontSize: '1.2rem' }}>{user.username}</strong>
                                        <br />
                                        <strong style={{ color: getColor(user.role) }}>{user.role_name}</strong>
                                    </FlexItem>
                                </FlexRow>
                            ),
                        },
                        { content: user.email },
                        {
                            content: (
                                <>
                                    {/* Employee */}
                                    {user.role === 3 && user?.profile !== null && user?.profile !== undefined && (
                                        <Link to={routes.EMPLYOEES_DETAIL + user.profile.id} target="_blank">
                                            {user.profile.name}
                                        </Link>
                                    )}

                                    {/* Candidate */}
                                    {user.role === 2 && user?.profile !== null && user?.profile !== undefined && (
                                        // <Link  to={routes.EMPLYOEES_DETAIL + user.profile.id} target="_blank">
                                        <span> {user.profile.name} </span>
                                        // </Link>
                                    )}

                                    {/* Account */}
                                    {user.role === 4 && user?.account !== null && user?.account !== undefined && (
                                        <Link to={routes.ACCOUNTS_DETAIL + user.account.id} target="_blank">
                                            {user.account.name}
                                        </Link>
                                    )}
                                </>
                            ),
                        },
                        { content: user?.last_login ? moment(user?.last_login).format(dateFormat + " HH:mm") : "--"},
                        { content: user?.date_joined ? moment(user?.date_joined).format(dateFormat + " HH:mm") : "--" },
                        {
                            content: (
                                <FlexRow background="transparent" padding="0" fontSize="0.9rem">
                                    <FlexItem basis="10%">
                                        <Icon
                                            name="ellipse"
                                            style={{
                                                color: user.is_active ? 'green' : 'red',
                                                fontSize: '1rem',
                                                marginRight: '0.5rem',
                                            }}
                                        />
                                    </FlexItem>
                                    <FlexItem>
                                        <span style={{ position: 'relative', top: '-0.2rem' }}>
                                            {user.is_active ? t('active_him') : t('inactive_him')}
                                        </span>
                                    </FlexItem>
                                </FlexRow>
                            ),
                        },
                    ]}
                />
            </div>
        </CanView>
    );
};

export default UsersList;
