import React, { useEffect, useState, } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
// store
import { API } from '../../../store/config';
import { addChoices } from '../../../store/actions';
import { APIRequest } from '../../../store/services/helpers/requests';
// components
import { Form } from 'semantic-ui-react';

// Function will handle API connection
async function fetchData(endpoint) {
    const url = endpoint || API.CHOICES; // choices will be used by default if "endpoint" is undefined
    return await APIRequest({
        url: url,
        method: "GET",
        data: {},
        private: true
    })
}


const ChoiceField = ({ label, value, onChange, placeholder, customOptions, endpoint, text, type, exclude, addOption, disabled, clearable, ...rest }) => {
    /*
        ** value is current selected value,
        ** onChange is function that will handle state management
        ** endpoint is custom endpoint for API connection
        ** key is json key for getting values
        ** type is used mainly for choices only option and represent current key value in json to get values from

    */
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false)
    const [options, setOptions] = useState(customOptions || [])
    const [excludeValues] = useState(exclude || [])
    const choices = useSelector(state => state.choices)
    const dispatch = useDispatch()

    useEffect(() => {
        async function getChoices() {
            setLoading(true);

            let result = null
            if (type) {
                if (choices[type]) {
                    result = {
                        status: 200,
                        response: choices
                    }
                } else {
                    result = await fetchData();
                    if (result.status === 200) {
                        dispatch(addChoices(result.response))
                    }
                }
            } else if (endpoint && text) {
                result = await fetchData(endpoint)
            }

            if (result && result.status === 200) {
                let optionsArray = [];
                if (type) {
                    optionsArray = Object.entries(result.response[type]).map(
                        (item, index) => (
                            { key: index + 1, text: item[1], value: item[0] }
                        )
                    );
                } else if (endpoint && text) {
                    let splitText = text.split(".");
                    for (let i = 0; i < result.response.length; i++) {

                        let textValue = "";
                        if (splitText.length === 1) { // nested lvl 1: name: "test
                            textValue = result?.response[i][splitText[0]]
                        } else if (splitText.length === 2) { // nested lvl 2: job_position: { name: "test" }
                            textValue = result?.response[i][splitText[0]][splitText[1]]
                        } else if (splitText.length === 3) { // nested lvl 3: job_position: { name: "test", test: { name: "something" } }
                            textValue = result?.response[i][splitText[0]][splitText[1]][splitText[2]]
                        }

                        if (!excludeValues.includes(result?.response[i].id)) {
                            optionsArray.push(
                                { key: i + 1, text: textValue, value: result?.response[i].id }
                            );
                        }

                    }
                }

                setOptions(optionsArray);
            }
            setLoading(false);
        }


        getChoices();
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (addOption && addOption.id) {
            setOptions([...options, addOption]);
        }
        // eslint-disable-next-line
    }, [addOption])


    return (
        <Form.Select
            {...rest}
            clearable={clearable === undefined ? true : clearable}
            // forceSelection={false}
            selectOnBlur={false}
            lazyLoad
            label={label}
            loading={loading}
            disabled={loading || disabled}
            options={customOptions || options}
            value={value}
            onChange={onChange}
            placeholder={placeholder ? placeholder : t('select_option')}
        />
    );
};

export default ChoiceField;