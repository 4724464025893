import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { requests } from '@helpers/requests';
import { API } from '@store/config';
// components
import { Label, Form, Popup } from 'semantic-ui-react';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import Icon from '@components/Icon';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import SuperLabelField from '@components/forms/SuperLabelField';

const LabelsList = ({ labels, mark, rowID, endpoint, endpointLabels, label_key, additionalFilters, resourcetype }) => {
    const { t } = useTranslation();

    const [data, setData] = useState(labels);
    const maxVisible = 3

    const onLabelRemove = async (id, labelID) => {
        const request = await requests.patch(endpoint + id + `/?query={id, ${label_key}}`,
            resourcetype ?
                {
                    [label_key]: {
                        remove: [labelID]
                    },
                    resourcetype: resourcetype,
                }
                :
                {
                    [label_key]: {
                        remove: [labelID]
                    }
                }
        )

        if (request.status === 200) {
            setData(request.response[label_key])
        }
    }

    const LabelsForm = ({ data, onClose }) => {

        const [labels, setLabels] = useState(data.map(item => item.id))
        const [isProcessing, setIsProcessing] = useState(false)

        const onSubmit = async () => {
            setIsProcessing(true)

            const request = await requests.patch(endpoint + rowID + `/?query={id, ${label_key}}` + (additionalFilters || ""),
                resourcetype 
                    ?
                        {
                            [label_key]: {
                                remove: data.map(item => item.id),
                                add: labels
                            },
                            resourcetype: resourcetype,
                            [mark]: true
                        }
                    :
                        {
                            [label_key]: {
                                remove: data.map(item => item.id),
                                add: labels
                            },
                            resourcetype: resourcetype,
                        }
            )

            if (request.status === 200) {
                setData(request.response[label_key])
                onClose()
            }

            setIsProcessing(false)
        }

        return (
            <Form onSubmit={onSubmit}>
                <SuperLabelField
                    value={labels}
                    mark={mark}
                    onChange={(labels) => setLabels(labels)}
                    endpoint={endpointLabels ? endpointLabels : label_key === "tags" ? API.ACCOUNTS_LABELS : label_key === "labels" ? API.PROJECT_LABELS : API.CONTACT_LABELS}
                />
                <Form.Field style={{ textAlign: "right" }}>
                    <ModalCancel onClose={onClose} />
                    <ModalSubmit
                        disabled={isProcessing}
                        loading={isProcessing}
                        text={t('confirm')}
                    />
                </Form.Field>
            </Form>
        );
    }


    return (
        <Fragment>
            <>
                {data?.map((label, idx) => (
                    <>
                        { idx + 1 <= maxVisible  && 
                            <Label basic key={label.id} style={{background: label.color === "" ? "var(--dark)" : label.color + "B3", padding: "0.2rem" }}>
                                <span style={{ position: "relative", top: "-0.05rem", fontSize: "0.8rem", color: "var(--dark)" }}>{label.name}</span>
                                <Icon name="close-outline" style={{ marginLeft: "0.3rem", color: "var(--danger)", cursor: "pointer", position: "relative", top: "0.1rem" }} title={t('remove_label')} onClick={() => onLabelRemove(rowID, label.id)} />
                            </Label>
                        }
                    </>
                ))}

                { data.length > maxVisible && 
                    <Popup 
                        hoverable
                        position="left center"
                        trigger={
                            <Label basic style={{ padding: "0.3rem" }}>
                                <span style={{ fontSize: "0.8rem", color: "var(--dark)" }}>
                                    { (data.length - maxVisible) } {" "}
                                    <span style={{ textTransform: "lowercase" }}>{(data.length - maxVisible) === 1 ? t('label') : t('labels')}</span>...
                                </span>
                            </Label>
                        }
                        content={
                            <div style={{ display: "flex", flexDirection: "column" }}>
                               { data?.map((label, idx) => (
                                    <>
                                        { idx + 1 > maxVisible  && 
                                            <Label 
                                                basic 
                                                key={label.id} 
                                                style={{ 
                                                    display: "flex",
                                                    padding: "0.2rem",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    justifyContent: "space-between",
                                                    background: label.color === "" ? "var(--dark)" : label.color + "B3",
                                                    marginBottom: (idx + 1 === data.length) ? "0" : "0.5rem"
                                                }}
                                            >
                                                <span style={{ fontSize: "0.8rem", color: "var(--dark)" }}>{label.name}</span>
                                                <Icon name="close-outline" style={{ marginLeft: "0.5rem", color: "var(--danger)", cursor: "pointer" }} title={t('remove_label')} onClick={() => onLabelRemove(rowID, label.id)} />
                                            </Label>
                                        }
                                    </>
                                ))}
                            </div>
                        }
                    />

                }

                <SuperDuperModal
                    trigger={
                        <Label basic style={{ cursor: "pointer", padding: "0.2rem" }} title={t('add_label')}>
                            <Icon name="add-outline"/>
                        </Label>
                    }
                    content={
                        <LabelsForm data={data} />
                    }
                />
            </>
        </Fragment>
    );
};

export default LabelsList;
