import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
// store
import { routes } from '@routes';
import { API } from '@store/config';
import { requests } from "@helpers/requests";
// components
import { Label, Form } from 'semantic-ui-react';
import ListView from '@components/ListView';
import CanView from '@components/perms/CanView';
import SuperField from '@components/forms/SuperField';
import BreadcrumbNav from '@components/BreadcrumbNav';
import SuperDuperModal from '@components/modals/SuperDuperModal';
// module specific Components
import PositionPreview from '../components/PositionPreview';
import PositionItemsConfig from '../components/PositionItemsConfig';
import LaunchHiringRoom from '../components/forms/LaunchHiringRoom';


const PositionList = () => {
    const { t } = useTranslation();
    const history = useHistory();
    
    const [forceModalOpen, setForceModalOpen] = useState(0)

    return (
        <CanView permissions={['jobs.c_view_job_positions']} redirect>
            <BreadcrumbNav items={[{ 'name': t('jobs_positions'), 'icon': '', 'href': '' }]} />
            <ListView
                as="table"
                allowSearch
                isSoftDeleteAllowed
                endpoint={API.JOB_POSITIONS}
                initialFilters={{
                    title: "",
                    is_external: "",
                    position_number: "",
                    level: "",
                    job_family: "",
                    group: ""
                }}
                renderFilterFields={(filters, setFilters) => (
                    <>
                        <SuperField
                            as="input"
                            label={t('position_number')}
                            value={filters.position_number}
                            onChange={(e, { value }) => setFilters(prev => ({...prev, position_number: value}))}
                        />
                        <SuperField
                            search
                            as="choice"
                            text="title"
                            label={t('job_family')}
                            value={filters.job_family}
                            endpoint={API.JOBS + "job_families/"}
                            onChange={(e, { value }) => setFilters(prev => ({...prev, job_family: value}))}
                        />
                        <Form.Group widths="equal">
                            <SuperField
                                search
                                as="choice"
                                text="title"
                                label={t('position_group')}
                                value={filters.group}
                                endpoint={API.JOBS + "position_groups/"}
                                onChange={(e, { value }) => setFilters(prev => ({...prev, group: value}))}
                            />
                            <SuperField
                                search
                                as="choice"
                                text="title"
                                label={t('position_level')}
                                value={filters.level}
                                endpoint={API.JOBS + "position_levels/"}
                                onChange={(e, { value }) => setFilters(prev => ({...prev, level: value}))}
                            />
                        </Form.Group>
                        <SuperField as="choice"
                            label={t('type')}
                            customOptions={[
                                { key: 1, value: true, text: t('external_position') },
                                { key: 2, value: false, text: t('internal_position') }
                            ]}
                            value={filters.is_external}
                            onChange={(e, { value }) => setFilters({
                                ...filters,
                                is_external: value
                            })}
                        />
                    </>
                )}
                actions={[
                    {
                        as: "link",
                        type: "add",
                        name: t("add_position"),
                        permissions: ['jobs.c_manage_job_positions'],
                        redirect: () => routes.CREATE_JOB_POSITION
                    },
                    {
                        as: "modal",
                        type: "custom",
                        name: t('show_detail'),
                        icon: "eye-outline",
                        customIconColor: "var(--primary)",
                        permissions: ['jobs.c_view_job_positions'],
                        modalSize: "large",
                        modal: <PositionPreview/>
                    },
                    {
                        as: "modal",
                        type: "custom",
                        name: t('manage_position_linked_items'),
                        icon: "settings-outline",
                        permissions: ['assets.c_view_items'],
                        modal: <PositionItemsConfig/>,
                        forceModalOpen: forceModalOpen,
                        setForceModalOpen: setForceModalOpen
                    },
                    {
                        as: "modal",
                        type: "custom",
                        name: t('launch_hiring'),
                        icon: "log-in-outline",
                        customIconColor: "var(--primary)",
                        permissions: ['candidates.c_manage_recruitment'],
                        modal: <LaunchHiringRoom/>
                    },
                    {
                        as: "link",
                        type: "edit",
                        name: t('edit'),
                        redirect: (item) => routes.UPDATE_JOB_POSITION + item.id,
                        permissions: ['jobs.c_manage_job_positions']
                    },
                    {
                        name: t('duplicate'),
                        type: "custom",
                        as: "confirm",
                        icon: "copy-outline",
                        text: t('duplicate_position_confirm'),
                        permissions: ['jobs.c_manage_job_positions'],
                        onClick: async (item) => {
                            const request = await requests.post(API.JOB_POSITIONS + item.id + "/duplicate/")

                            if (request.status === 201) {
                                history.push(routes.UPDATE_JOB_POSITION + request.response.id + "/")
                            }
                        }
                    },
                    {
                        name: t('delete'),
                        type: "delete",
                        as: "delete",
                        text: t('delete_desc'),
                        permissions: ['jobs.c_delete_job_positions']
                    },
                ]}
                tableHeaders={[
                    { title: t('code'), orderBy: "position_number" },
                    { title: t('title'), orderBy: "title" },
                    { title: t('group'), orderBy: "group__title" },
                    { title: t('level'), orderBy: "level__title" },
                    { title: t('isco'), orderBy: "isco__fullname" },
                    { title: t('contracts') },
                    { title: t('type'), orderBy: "type" },
                ]}
                renderCells={(position, setData) => ([
                    { width: 1, content: position.position_number },
                    { width: 2, content: 
                        <>
                            <SuperDuperModal
                                size="large"
                                trigger={<span className="ref-link">{position.title}</span>}
                                content={<PositionPreview record={position} />}
                            /> <br/>
                            <small>{ position.job_family?.title || "" }</small>
                        </>
                    },
                    { content: position.group?.title || "" },
                    { content: position.level?.title || "" },
                    { width: 2, content: position.isco?.fullname },
                    { content: position.contract_count },
                    { width: 1, content: 
                        <>
                            { position.is_external === false
                                ? <Label content={t('internal_position')} style={{ backgroundColor: 'var(--primary)', color: 'var(--white)' }} />
                                : <Label content={t('external_position')} style={{ backgroundColor: 'var(--info)', color: 'var(--white)' }} />
                            }
                        </>
                    }
                ])}
            />
        </CanView>
    )
}

export default PositionList;