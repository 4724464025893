import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Cookies from "js-cookie";
// store
import { routes } from '@routes';
import { authService } from '@services/ServiceAuth';
// custom components
import AttendanceVerification from './components/AttendanceVerification';
import AttendanceSelfManagement from './components/AttendanceSelfManagement';

const AttendanceTracker = () => {
    const history = useHistory()
    const [mode, setMode] = useState(1)
    const [employee, setEmployee] = useState(null)
    const [interruptions, setInterruptions] = useState([])

    useEffect(() => {
        const interval = setInterval(() => {
            async function checkUser() {
                const result = await authService.validateToken();
                if( result === false ){
                    const refresh = Cookies.get("refreshToken");
                    // attempt to refreshLogin with refresh token, if true then return new access token
                    if (refresh) {
                        const refreshAttempt = await authService.refreshLogin(refresh);
                        if (!refreshAttempt) {
                            history.push(routes.LOGIN);
                        }
                    } else {
                        history.push(routes.LOGIN);
                    }
                }
            }
    
            checkUser();
        }, 60000) // verify access token each minute (60 seconds)

        return () => clearInterval(interval)
    })

    return (
        <>
            { mode === 1 && 
                <AttendanceVerification
                    setMode={setMode}
                    setEmployee={setEmployee}
                    setInterruptions={setInterruptions}
                />
            }
            { mode === 2 && 
                <AttendanceSelfManagement
                    setMode={setMode}
                    employee={employee}
                    setEmployee={setEmployee}
                    interruptions={interruptions}
                />
            }
        </>
    )
}

export default AttendanceTracker