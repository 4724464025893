import React, { useState, Fragment } from 'react';
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom';
//store
import { routes } from '@routes';
import { useFetchData } from '@helpers/hooks';
import { requests } from '@helpers/requests';
// components
import Icon from '@components/Icon';
import { Grid, Header, Divider, Button } from 'semantic-ui-react';
import { FlexTable, FlexRow, FlexHeader, FlexItem } from '@components/tables/FlexTable'
import EmptyRow from '@components/tables/EmptyRow';
import Loading from "@components/general/Loading";
import CanView from '@components/perms/CanView';
import SuperDuperModal from '@components/modals/SuperDuperModal'
import OutlineAddButton from '@components/buttons/OutlineAddButton';
import ConfirmModal from '@components/modals/ConfrimModal';
import TypeForm from './TypeForm';

const TypesManagement = ({ allowSoftDelete, title, endpoint, languages, permissions, definitions }) => {
    const { t } = useTranslation();
    const [result, setResult] = useFetchData(endpoint + (languages?.length > 0 ? "?allow_translations=true" : ""))
    const [language, setLanguage] = useState("sk")

    const onDelete = async (id) => {
        let request = null
        if (allowSoftDelete) {
            request = await requests.post(endpoint + id + "/delete/",);
        } else {
            request = await requests.del(endpoint + id + "/",);
        }

        if (request?.status === 204 || request?.status === 200) {
            const newData = result.data.filter(item => item.id !== id)

            setResult({
                ...result,
                data: newData
            })
        }
    }

    const onUpdate = (response) => {
        setResult(prevState => ({
            ...prevState,
            data: prevState.data.map((item) => {
                if (item.id === response.id) return response

                return item;
            })
        }))
    };

    const onCreate = async (item) => {
        setResult(prevState => ({
            ...prevState,
            data: [item, ...prevState.data]
        }))
    }

    return (
        <>
            <CanView permissions={permissions.view}>
                {result.isLoading ? <Loading /> :
                    <>
                        <Grid>
                            <Grid.Row verticalAlign='middle'>
                                <Grid.Column computer={5}>
                                    <Link to={routes.SETTINGS_TYPES}>
                                        <Icon name='arrow-back-outline' style={{ maxHeight: "43px", height: "100%" }} /> {t('back_to_the_list')}
                                    </Link>
                                </Grid.Column>
                                <Grid.Column computer={6} textAlign='center'>
                                    <Header>{title || "undefined"}</Header>
                                </Grid.Column>
                                <Grid.Column computer={5} textAlign="right">
                                    <CanView permissions={permissions.manage}>
                                        <SuperDuperModal
                                            header={t('add')}
                                            trigger={
                                                <OutlineAddButton
                                                    size="small"
                                                    color="violet"
                                                    text={t('add')}
                                                />
                                            }
                                            content={
                                                <TypeForm
                                                    endpoint={endpoint}
                                                    definitions={definitions}
                                                    languages={languages}
                                                    action='create'
                                                    onConfirm={(item) => onCreate(item)}
                                                />
                                            }
                                        />
                                    </CanView>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>

                        <Divider />

                        { languages?.length > 0 && 
                            <Button.Group size="tiny" basic style={{ marginBottom: "0.5rem", borderRadius: 0 }}>
                                { languages.map((lang, index) => (
                                    <Button
                                        key={index}
                                        style={{ textTransform: "uppercase" }}
                                        active={lang === language}
                                        onClick={() => setLanguage(lang)}
                                    >
                                        { lang === "en-us" ? "en" : lang } 
                                    </Button>
                                )) }
                            </Button.Group>
                        }
                        
                        <FlexTable padding='1rem'>
                            <FlexRow>
                                {definitions.map((header, index) => (
                                    <FlexHeader key={index} content={header.name} />
                                ))}
                                <CanView permissions={permissions.manage}>
                                    <FlexHeader basis="10%" content={t('actions')} />
                                </CanView>
                            </FlexRow>
                            <EmptyRow length={result?.data?.length} />
                            {result?.data?.map((item, index) => (
                                <FlexRow key={index}>
                                    { languages?.length > 0 
                                        ? 
                                            <>
                                            { languages.map((lang, index) => (
                                                <Fragment key={index}>
                                                    { lang === language && 
                                                        <>
                                                            { definitions.filter(item => item.isTranslatable === true).map((cell, index) => (
                                                                <FlexItem key={index}>
                                                                    {cell.as === "choice" 
                                                                        ? cell.icon 
                                                                            ? cell.isTranslatable ? <Icon name={item?.translations?.[lang]?.[cell.key]}/>  : <Icon name={item?.[cell.key]}/> 
                                                                            : cell.isTranslatable 
                                                                                ? item?.translations?.[lang]?.[cell.key + "_display"] 
                                                                                : item?.[cell.key + "_display"] 
                                                                        : cell.isTranslatable ? item?.translations?.[lang]?.[cell.key] : item?.[cell.key]
                                                                    }
                                                                </FlexItem>
                                                            ))}
                                                        </>
                                                    }
                                                </Fragment>
                                            )) }
                                            { definitions.filter(item => item.isTranslatable !== true).map((cell, index) => (
                                                <FlexItem key={index}>
                                                    {cell.as === "choice" 
                                                        ? cell.icon 
                                                            ? <Icon name={item?.[cell.key]}/> 
                                                            : cell.isNested 
                                                                ? item?.[cell.key]?.title || item?.[cell.key]?.name || ""
                                                                : item?.[cell.key + "_display"] 
                                                        : cell.as === "checkbox" 
                                                            ? item?.[cell.key] === true ? t('yes') : t('no') 
                                                            : item?.[cell.key]
                                                    }
                                                </FlexItem>
                                            ))}
                                            </>
                                        : 
                                            <>
                                                {definitions.map((cell, index) => (
                                                    <FlexItem key={index}>
                                                        {
                                                        cell.as === "choice" 
                                                            ? cell.icon 
                                                                ? <Icon name={item?.[cell.key]} /> 
                                                                : cell.isNested 
                                                                    ? item?.[cell.key]?.title || item?.[cell.key]?.name || ""
                                                                    : item?.[cell.key + "_display"] 
                                                            : cell.as === "checkbox" 
                                                                ? item?.[cell.key] === true ? t('yes') : t('no') 
                                                                : item?.[cell.key]
                                                        }
                                                    </FlexItem>
                                                ))}
                                            </>
                                    }
                                    <CanView permissions={permissions.manage}>
                                        <FlexItem basis="10%">
                                            <FlexRow background="transparent" padding="0">
                                                <FlexItem>
                                                    <SuperDuperModal
                                                        size="small"
                                                        trigger={
                                                            <Icon name="pencil-outline" style={{ color: "var(--dark)", cursor: "pointer" }} />
                                                        }
                                                        content={
                                                            <TypeForm
                                                                endpoint={endpoint}
                                                                definitions={definitions}
                                                                languages={languages}
                                                                action='update'
                                                                result={item}
                                                                onConfirm={(updated_item) => onUpdate(updated_item)}
                                                            />
                                                        }
                                                    />
                                                </FlexItem>
                                                <CanView permissions={permissions.delete}>
                                                    <FlexItem>
                                                        <ConfirmModal
                                                            description={t('delete_this')}
                                                            onConfirm={() => onDelete(item.id)}
                                                            button={
                                                                <Icon name="close-outline" style={{ color: "var(--danger)", cursor: "pointer" }} />
                                                            }
                                                        />
                                                    </FlexItem>
                                                </CanView>
                                            </FlexRow>
                                        </FlexItem>
                                    </CanView>
                                </FlexRow>
                            ))}
                        </FlexTable>
                    </>
                }
            </CanView>
        </>
    );
};

export default TypesManagement;